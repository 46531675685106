/*-------------- filial --------------*/
.filial-hero {
  margin-bottom: rem(60);

  @media (max-width: 1500px) {
    margin-bottom: rem(40);
  }

  @media (max-width: 991.98px) {
    padding-top: rem(55);
  }

  @media (max-width: 767.98px) {
    padding-top: rem(20);
  }

  .section-title__back {
    bottom: 1%;

    @media (max-width: 767.98px) {
      line-height: lh(80, 138);
    }
  }

  &__cover {
    position: relative;

    .map-inside {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @media (max-width: 767.98px) {
        position: static;
        width: auto;
        max-width: none;
        transform: none;
        margin: 0 rem(-20);
      }

      h4 {
        &::after {
          content: none;
        }
      }
    }
  }

  &__image {
    width: calc(100% - #{rem(195)});
    height: rem(450);
    margin-left: auto;
    position: relative;
    overflow: hidden;

    @media (max-width: 1500px) {
      width: calc(100% - #{rem(100)});
    }

    @media (max-width: 991.98px) {
      width: auto;
      margin: 0 rem(-25);
    }

    @media (max-width: 767.98px) {
      height: rem(190);
      margin: 0 rem(-20);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: "object-fit: cover;", serif;
    }
  }
}

.filial-stat {
  margin-bottom: rem(60);

  @media (max-width: 1500px) {
    margin-bottom: rem(50);
  }

  @media (max-width: 991.98px) {
    margin-bottom: rem(20);
  }

  @media (max-width: 767.98px) {
    margin-bottom: 0;
  }

  &__list {
    margin-left: rem(-30);
    display: flex;
    align-items: stretch;

    @media (max-width: 991.98px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__item {
    width: calc(100%/7);
    padding-left: rem(30);

    @media (max-width: 1500px) {
      display: none;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      @media (max-width: 1500px) {
        width: calc(100%/5);
        display: block;
      }

      @media (max-width: 991.98px) {
        width: 50%;
        margin-bottom: rem(30);
      }

      @media (max-width: 767.98px) {
        width: 100%;
        max-width: rem(480);
      }
    }

    &-inner {
      height: rem(145);
      background: #FFFFFF;
      box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
      text-align: center;
      padding: rem(35) rem(20) 0;
      transition: background 0.3s;

      &:hover {
        background: #2D303E;

        .filial-stat__item-title {
          color: #FFFFFF;

          & > div {
            color: #FFFFFF;
          }
        }
      }
    }

    &-title {
      font-weight: bold;
      font-size: rem(40);
      line-height: lh(35, 40);
      color: #D43A42;
      transition: color 0.3s;

      @media (max-width: 991.98px) {
        font-size: rem(35);
      }

      & > div {
        font-weight: normal;
        font-size: rem(16);
        line-height: rem(20, 16);
        color: #000000;
        transition: color 0.3s;
        margin-top: rem(25);

        @media (max-width: 991.98px) {
          margin-top: rem(15);
        }
      }
    }
  }
}

.filial-galery {
  margin-bottom: rem(10);
  $p: 30;

  @media (max-width: 1500px) {
    margin-bottom: rem(20);
  }

  @media (max-width: 767.98px) {
    display: none;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-left: rem(-$p);
  }

  &__item {
    width: 50%;
    padding-left: rem($p);
    margin-bottom: rem($p);

    &-inner {
      box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.1);
      height: rem(380);
      display: flex;
      align-items: center;
      justify-content: center;
      background: #2D303E;
      position: relative;

      @media (max-width: 1500px) {
        height: rem(355);
      }

      @media (max-width: 991.98px) {
        height: rem(300);
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: rem(40);
        height: rem(40);
        background: #505466;
      }
    }

    &-image {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: "object-fit: cover;", serif;
      }
    }

    &-text {
      font-weight: 300;
      font-size: rem(16);
      line-height: lh(32, 16);
      color: #FFFFFF;
      width: 100%;
      max-width: rem(620);
      padding: 0 rem(20);
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }
  }
}

.filial-slider {
  padding: rem(60) 0;
  background: #2D303E;
  box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.1);
  margin-bottom: rem(40);

  @media (max-width: 991.98px) {
    padding: 0;
    background: none;
    box-shadow: none;
    margin-bottom: rem(30);
  }

  @media (max-width: 767.98px) {
    margin-bottom: 0;
  }

  &__inner {
    width: 100%;
    position: relative;

    .benefit-card {
      width: 100%;
      height: auto;
      max-width: rem(500);
      padding: rem(50) rem(95) rem(50) rem(60);
      position: absolute;
      bottom: rem(50);
      left: rem(50);
      z-index: 1;

      @media (max-width: 991.98px) {
        padding: rem(30) 0 0 0;
        position: static;
        max-width: rem(600);
        box-shadow: none;
      }

      &::before {
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;
        background: #EEEEEE;

        @media (max-width: 991.98px) {
          content: none;
        }
      }

      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: rem(34);
        line-height: lh(54, 34);
        margin-bottom: rem(40);

        @media (max-width: 991.98px) {
          margin-bottom: rem(20);
        }

        @media (max-width: 767.98px) {
          font-size: rem(24);
        }
      }

      p {
        font-weight: normal;
        font-size: rem(16);
        line-height: lh(32, 16);

        @media (max-width: 767.98px) {
          font-size: rem(15);
        }
      }
    }
  }
}

.photo-slider {
  width: 100%;

  &__outer {
    width: 100%;
    margin: 0;

    @media (max-width: 991.98px) {
      background: #2D303E;
      padding: rem(20) rem(25);
      margin: 0 rem(-25);
      width: auto;
      box-shadow: none;
    }

    @media (max-width: 767.98px) {
      padding: rem(20);
      margin: 0 rem(-20);
    }

    .swiper-button-wrap {
      top: rem(30);
      right: 0;
      padding: 0 rem(30);

      @media (max-width: 767.98px) {
        right: auto;
        left: 0;
      }
    }
  }

  .swiper-slide {
    margin-right: 0;
  }

  &__item {
    &-inner {
      width: 100%;
      height: rem(590);
      position: relative;

      @media (max-width: 991.98px) {
        height: rem(390);
      }
  
      @media (max-width: 767.98px) {
        height: rem(245);
      }

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: "object-fit: cover;", serif;
        display: block;
      }
    }
  }
}

.employees {
  padding-bottom: rem(30);
  overflow: hidden;

  @media (max-width: 991.98px) {
    padding-bottom: rem(40);
  }

  .section-title-outside__cover {
    left: rem(-80);
  }
}

.employees-list {
  $p: 30;
  overflow: visible;

  @media (max-width: 991.98px) {
    overflow: visible;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-right: rem(-$p);
    width: auto;

    @media (max-width: 991.98px) {
      width: 100%;
      flex-wrap: nowrap;
      margin-right: 0;
    }

    li {
      width: 25%;
      width: calc(25% - #{rem(30)});
      margin-bottom: rem($p);
 
      box-shadow: none;


      @media (max-width: 991.98px) {
        padding-left: 0;
        margin-bottom: 0;
        width: rem(390);
   
      }

      @media (max-width: 575.98px) {
        width: 100%;
      }
    }
  }
}

.employees-card {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__image {
    position: relative;
    width: 100%;
    // height: rem(290);
    padding-top: 71%;

    @media (max-width: 767.98px) {
      padding-top: 0;
      height: rem(200);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      font-family: "object-fit: cover;", serif;
    }
  }

  &__cont {
    flex-grow: 1;
    padding: rem(35) rem(45) rem(50) rem(40);
    box-shadow: 0 0 rem(24) rgba(0, 0, 0, 0.1);

    @media (max-width: 1500px) {
      padding: rem(24) rem(30) rem(34);
    }

    h5 {
      font-weight: bold;
      font-size: rem(20);
      line-height: lh(32, 20);
      color: #000000;
      margin-bottom: rem(15);
    }

    p {
      font-weight: normal;
      font-size: rem(16);
      line-height: lh(26, 16);
      color: #9E9E9E;

      @media (max-width: 767.98px) {
        font-size: rem(15);
      }
    }
  }
}



body {
	font-family: 'Gilroy', sans-serif;
	background-color: #fffffd;
	color: #000;
}

p {
	font-weight: 300;
	font-size: rem(16);
	line-height: rem(32);
	color: #616161;

	@media (max-width: 767.98px) {
		font-size: rem(15);
		line-height: rem(30);
	}
}

h1 {
	font-weight: 800;
	font-size: rem(56);
	line-height: rem(75);
	text-transform: uppercase;

	@media (max-width: 767.98px) {
		font-size: rem(34);
		line-height: rem(50);
	}
}

h2 {
	font-weight: bold;
	font-size: rem(41);
	line-height: rem(50);

	@media (max-width: 767.98px) {
		font-size: rem(30);
		line-height: rem(45);
	}
}

h3 {
	font-weight: bold;
	font-size: rem(38);
	line-height: rem(75);

	@media (max-width: 767.98px) {
		font-size: rem(30);
		line-height: rem(45);
	}
}

h4 {
	font-weight: bold;
	font-size: rem(34);
	line-height: rem(63);

	@media (max-width: 767.98px) {
		font-size: rem(26);
		line-height: rem(42);
	}
}

h5 {
	font-weight: bold;
	font-size: rem(20);
	line-height: rem(34);
}


time {
	font-size: 16px;
	line-height: 32px;
	color: #9E9E9E;
}

.wrapper	{
	width: 100%;
	overflow: hidden;
}

.container {
	width: 100%;
	max-width: rem(1680);
	padding: 0 rem(15);
	position: relative;

	@media (max-width: 1500px) {
    padding: 0 rem(50);
  }

  @media (max-width: 991.98px) {
    padding: 0 rem(25);
	}

	@media (max-width: 767.98px) {
		padding: 0 rem(20);
	}
}

.mob-visible {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.desk-visible {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: #D43A42;
	box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.1);
	padding: 0 rem(45);
	height: rem(64);
	line-height: rem(64);
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	font-size: rem(15);
	cursor: pointer;
	outline: none;
	text-decoration: none;
	transition: all .3s;

	&:hover {
		background-color: #171921;
	}
}

.btn-secondary {
	background: #2D303E;

	&:hover {
		background: #D1D1D1;
		box-shadow: none;
	}
}

.btn-light {
	background: #F9F9F9;
	color: #000000;

	&:hover {
		color: #fff;
		background-color: #171921;

	}
}

// -----------swiper-----------

.swiper-button-next, .swiper-button-prev {
	width: rem(64);
	height: rem(64);
	background: #E5E6EA;
	margin: 0;
	top: auto;
	right: auto;
	left: auto;
	position: relative;
	margin: 0 rem(5);
	outline: none;
	transition: all .3s;

	@media (max-width: 991.98px) {
		width: rem(46);
		height: rem(46);
	}

	&:after {
		content: "";
		display: block;
		width: rem(9);
		height: rem(17);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 50%;

	}

	&:hover {
		background-color: #171921;
	}
}

.swiper-button-next {
	&:after {
		background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-next-dark.svg");
	}

	&:hover {
		&:after {
			background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-next-white.svg");
		}
	}
} 

.swiper-button-prev {
	&:after {
		background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-prev-dark.svg");
	}

	&:hover {
		&:after {
			background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-prev-white.svg");
		}
	}
}

.swiper-wrap {
	width: calc(100vw - (100vw - #{rem(1650)}) / 2);
	box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);

	@media (max-width: 1500px) {
		width: auto;
		margin-right: rem(-50);
	}

	@media (max-width: 991.98px) {
		margin: 0 rem(-25);
		box-shadow: none;
		
	}

	@media (max-width: 767.98px) {
		margin: 0 rem(-20);
	}
}

.swiper-button-wrap {
	display: flex;
	position: absolute;
	top: rem(-95);
	right: 0;
	padding: 0 rem(25);

	@media (max-width: 991.98px) {
    top: rem(15);
	}

	@media (max-width: 767.98px) {
    padding: 0 rem(10);
	}
}

.swiper-slide {
	height: auto;
	width: auto;
	margin-right: rem(30);
	box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
	position: relative;
	z-index: 2;

	@media (max-width: 991.98px) {
    margin-right: rem(15);
    margin-left: rem(15);
	}

	@media (max-width: 575.98px) {
		width: 100%;	
	}

}

.swiper-container-fade {
	.swiper-slide {
		opacity: 1 !important;

		&:not(.swiper-slide-active){ 
			opacity: 0 !important; 
		}
	}
}


.swiper-container {
	@media (max-width: 991.98px) {
		overflow: visible;
	}
}

.mob-swiper-button-center {

	@media (max-width: 767.98px) {
		margin: 0;

		.swiper-button-wrap {
			position: static;
		}

		.swiper-button-prev,
		.swiper-button-next {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
		}

		.swiper-button-prev {
			left: rem(-23);
		}

		.swiper-button-next {
			right: rem(-23);

		}

	}
}


.swiper-pagination-bullet {
	background-color: #fff;
	opacity: 0.5;
	width: 12px;
	height: 12px;
}

.swiper-pagination-bullet-active {
	opacity: 1;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 8px;
}

.swiper-pagination-bullet-active {

}

.issue-leasing {
	padding-top: rem(25);
}

// -----------select2-----------

.select2-container {
	outline: none;

	&:focus {
		outline: none;
	}
}

.select-wrap {
	.select2-container {
		width: 100%;
	}
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: rem(50);
	padding-right: rem(50);
	font-weight: 300;
	font-size: rem(17);
	padding-left: rem(22);
}

.select2-container .select2-selection--single {
	height: rem(50);
	border-radius: 0;
	outline: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: rem(50);
	width: rem(50);
	display: flex;
	align-items: center;
	justify-content: center;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border: none;
	margin: 0;
	width: rem(17);
	height: rem(9);
	background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-dark.svg") no-repeat 50% / contain;
	transform: translateY(-50%);
	position: relative;
	top: auto;
	left: auto;
	transform: translate(0);
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border: none;
	transform: rotate(180deg);
}



// ----------range-slider--------
.irs--flat {
	height: 45px;
	font-family: 'Gilroy', sans-serif;
}

.irs > .irs {
	height: 9px;
	cursor: pointer;
}



.irs--flat .irs-line {
	height: 13px;
	background: none;
	top: -2px;

	
	&:before {
		content: "";
		width: 100%;
		height: 1px;
		background-color: #DADADA;
		position: absolute;
		top: 6px;
		left: 0px;
	}
}

.irs--flat .irs-bar {
	height: 9px;
	background-color: #878FB3;
	border-radius: rem(3);
	top: 0px;
	cursor: pointer;
}

.irs:hover {
	.irs-bar {
		background-color: #D43A42;
	}
}

.irs--flat .irs-handle {
	top: -5px;
	cursor: pointer;
}

.irs--flat .irs-handle>i:first-child {
	background: none;
}
.irs--flat .irs-handle.state_hover>i:first-child, .irs--flat .irs-handle:hover>i:first-child {
	background: none;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
	font-family: 'Gilroy', sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: #000000;
	background: none;
	cursor: pointer;
}

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
	display: none;
}

.irs-from, .irs-to, .irs-single {
	top: auto;
	bottom: -38px;
}


// -------checkbox------

.checkbox-input {
	position: relative;

	label {
		display: inline-block;
		cursor: pointer;
		position: relative;
		padding-left: rem(45);
		margin-right: rem(30);
		margin-bottom: rem(25);
		font-weight: 500;
		font-size: rem(16);
		line-height: rem(32);
		color: #000000;

	}

	label:before {
		content: "";
		display: inline-block;
		width: rem(24);
		height: rem(24);
		position: absolute;
		left: rem(0);
		top: 50%;
    transform: translate(0, -50%);
		background: #F3F3F3;
	}

	label:after {
		content: "";
		display: none;
		width: rem(8);
		height: rem(8);
		position: absolute;
		left: rem(8);
		top: rem(12);
		background-color: #878FB2;
		
	}

	input[type=radio],
	input[type=checkbox] {
		display: none;
	}

	button {
		width: rem(110);
		margin: rem(20) auto;
		margin-top: rem(10);
		height: rem(30);
		cursor: pointer;
		outline: none;
		background-color: #c92f35;
		text-transform: uppercase;
		color: #fff;
		font-size: rem(14);
		border: none;
		display: block;

	}

	input[type=radio]:checked + label:after {
		display: block;
	}

	input[type=checkbox]:checked + label:after {
		border-radius: 0;
		display: block;
	}

}


.radio-input {
	label:before {
		border-radius: 50%;
	}
	label:after {
		border-radius: 50%;
	}
}

.first-block-offset {
	@media (max-width: 991.98px) {
		padding-top: rem(60);
	}

	@media (max-width: 767.98px) {
		padding-top: rem(20);
	}
}
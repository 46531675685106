/*-------------- about --------------*/
.about-hero {
  margin-bottom: rem(80);

  @media (max-width: 991.98px) {
    padding-top: rem(55);
    margin-bottom: 0;
  }

  .section-title-outside {
    top: rem(200);
  }

  &__image {
    position: relative;
    width: 100%;
    height: rem(635);

    @media (max-width: 1500px) {
      height: rem(470);
    }

    @media (max-width: 991.98px) {
      height: rem(370);
    }

    @media (max-width: 767.98px) {
      padding-top: 59.4%;
      height: auto;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;', serif;
    }
  }

  &__cover {
    @media (max-width: 991.98px) {
      margin: 0 rem(-25);
    }

    @media (max-width: 767.98px) {
      margin: 0 rem(-20);
    }
  }

  &__info {
    width: calc(100% - #{rem(155)});
    background: #2d303e;
    box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.1);
    padding: rem(48) 0 rem(40) rem(64);
    margin-top: rem(-150);
    position: relative;
    overflow: hidden;

    @media (max-width: 1500px) {
      width: 100%;
      margin-top: rem(-50);
    }

    @media (max-width: 991.98px) {
      margin-top: rem(-10);
      padding: rem(30) rem(25) rem(40);
    }

    @media (max-width: 767.98px) {
      padding: rem(30) rem(20) rem(40);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: rem(40);
      height: rem(40);
      background: #505466;

      @media (max-width: 991.98px) {
        content: none;
      }
    }

    &::before {
      content: '';
      width: rem(1200);
      height: rem(1200);
      background: url('/design/themes/komtrans_it/media/assets/icons/circle-bg.svg') no-repeat 50% / contain;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-60%, -13%);

      @media (max-width: 767.98px) {
        width: rem(1000);
        height: rem(1000);
        left: auto;
        right: 0;
        // transform: translate(-60%, -5%);
        transform: translate(-5%, -10%);
      }
    }

    &-inner {
      width: 100%;
      display: flex;
      align-items: stretch;
      position: relative;

      @media (max-width: 991.98px) {
        flex-direction: column;
      }
    }

    &-left {
      width: calc(50% + #{rem(155)});
      padding-right: 5%;

      @media (max-width: 991.98px) {
        width: 100%;
        padding-right: 0;
        margin-bottom: rem(30);
      }

      h3 {
        font-weight: bold;
        font-size: rem(34);
        line-height: lh(42, 34);
        margin-bottom: rem(40);
        color: #ffffff;

        @media (max-width: 767.98px) {
          font-size: rem(24);
        }
      }

      p {
        color: #ffffff;
        font-weight: normal;
        font-size: rem(16);
        line-height: lh(28, 16);

        @media (max-width: 767.98px) {
          font-size: rem(15);
        }
      }
    }
    &-right {
      width: calc(50% - #{rem(155)});
      display: flex;
      align-items: center;

      @media (max-width: 991.98px) {
        width: 100%;
      }
    }
  }
}

.check-list {
  width: 100%;

  &-icon {
    width: rem(45);
    height: rem(45);
    border-radius: 50%;
    background: #d43a42;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: rem(-22.5);

    @media (max-width: 767.98px) {
      width: rem(40);
      height: rem(40);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(16);
      height: rem(11);
      margin-top: rem(-5.5);
      margin-left: rem(-8);
      background: url('/design/themes/komtrans_it/media/assets/icons/check-white.svg') no-repeat 50% / contain;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    li {
      width: 50%;
      position: relative;
      display: flex;
      align-items: center;
      font-weight: normal;
      font-size: rem(16);
      line-height: lh(27, 16);
      text-transform: uppercase;
      color: #ffffff;
      padding-left: rem(70);
      margin-bottom: rem(20);
      min-height: rem(45);

      @media (max-width: 767.98px) {
        padding-left: rem(55);
        font-size: rem(14);
      }
    }
  }
}

.advantages-about {
  &-block {
    margin-bottom: rem(40);

    @media (max-width: 991.98px) {
      margin: 0 rem(-25);
    }

    @media (max-width: 767.98px) {
      margin: 0 rem(-20);
    }

    &:before {
      height: 55%;
    }

    .advantages-block__text-content {
      @media (max-width: 991.98px) {
        padding: 0 rem(25);
      }

      @media (max-width: 767.98px) {
        padding: 0 rem(20);
      }

      .section-title {
        @media (max-width: 991.98px) {
          padding: rem(25) 0 rem(30);
          margin: 0;
        }
      }

      .section-title__back {
        @media (max-width: 991.98px) {
          display: none;
        }
      }
    }

    h3 {
      @media (max-width: 991.98px) {
        margin-bottom: 0;
      }
    }

    .swiper-button-wrap {
      // padding: 0;
      display: none;

      @media (max-width: 1500px) {
        display: flex;
      }
    }
    .swiper-wrap {
      margin: 0;
      margin-right: rem(-30);
      width: auto;

      @media (max-width: 1500px) {
        margin-right: 0;
      }
    }

    .swiper-container {
      @media (max-width: 1500px) and (min-width: 992px) {
        margin-right: -10%;
      }

      @media (max-width: 767.98px) {
        margin-right: 0;
      }
    }

    .swiper-slide {
      width: calc(33.333% - #{rem(30)});

      @media (max-width: 1500px) {
        width: auto;
      }

      @media (max-width: 575.98px) {
        width: 100%;
      }
    }

    .advantage-card {
      // width: 100%;

      @media (max-width: 1500px) {
        padding: rem(50) rem(40);
        
      }

      @media (min-width: 1500px) {
        width: 100%;
      }

      @media (max-width: 991.98px) {
        padding: rem(40) rem(20);
      }

      &::after {
        @media (max-width: 991.98px) {
          content: none;
        }
      }

      h4 {
        @media (max-width: 1500px) {
          font-size: rem(27);
          line-height: lh(37, 27);
        }

        @media (max-width: 991.98px) {
          font-size: rem(24);
          line-height: lh(34, 24);
          margin-bottom: rem(20);
        }
      }

      p {
        @media (max-width: 991.98px) {
          font-size: rem(16);
        }
      }

    }
  }

  &__actions {
    margin-top: rem(40);
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991.98px) {
      margin-top: rem(30);
      padding: 0 rem(25);
    }

    @media (max-width: 767.98px) {
      padding: 0 rem(20);

      .btn {
        width: 100%;
        max-width: rem(280);
        padding: 0;
      }
    }
  }
}

.filials-list {
  margin-bottom: rem(30);

  &__cover {
    box-shadow: none;
    margin: 0;
    width: 100%;

    @media (max-width: 991.98px) {
      width: auto;
      margin: 0 rem(-25);
    }

    @media (max-width: 767.98px) {
      margin: 0 rem(-20);
    }

    .swiper-button-wrap {
      display: none;

      @media (max-width: 991.98px) {
        display: flex;
      }
    }
  }

  .section-title__back {
    @media (max-width: 991.98px) {
      display: none;
    }
  }
}

.filials-slider {
  @media (max-width: 991.98px) {
    overflow: visible;
  }

  .swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: rem(-30);
    width: auto;

    @media (max-width: 991.98px) {
      width: 100%;
      flex-wrap: nowrap;
      margin-right: 0;
    }
  }

  .swiper-slide {
    width: calc(25% - #{rem(30)});
    margin-bottom: rem(30);
    box-shadow: none;

    @media (max-width: 1500px) {
      width: calc(33.333% - #{rem(30)});
    }

    @media (max-width: 991.98px) {
      width: rem(390);
      padding-left: 0;
      margin-bottom: 0;
    }

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }
}

.filial-card {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);

  &__top {
    width: 100%;
  }

  &__image {
    width: 100%;
    position: relative;
    padding-top: 62.8%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: "object-fit: cover;", serif;
    }
  }

  &__bottom {
    padding: rem(20) rem(30) rem(40);

    @media (max-width: 991.98px) {
      padding: rem(20) rem(25) rem(35);
    }

    @media (max-width: 767.98px) {
      padding: rem(20) rem(20) rem(35);
    }

    h5 {
      font-weight: bold;
      font-size: rem(20);
      line-height: lh(28, 20);
      color: #000000;
      margin-bottom: rem(20);
    }

    .btn {
      padding: 0;
      width: rem(230);

      @media (max-width: 991.98px) {
        width: 100%;
        max-width: rem(230);
        font-size: rem(15);
      }
    }
  }
}

.about-services {
  margin-bottom: rem(30);

  &__cover {

    @media (max-width: 991.98px) {
      margin: 0 rem(-25);
    }

    @media (max-width: 767.98px) {
      margin: 0 rem(-20);
    }

    .swiper-wrap {
      width: 100%;
      margin: 0;
      box-shadow: none;

      @media (max-width: 991.98px) {
        padding-top: rem(40);
      }

      .swiper-button-wrap {
        display: none;

        @media (max-width: 991.98px) {
          display: flex;
        }
      }
    }
  }

  &__items {
    display: flex;
    align-items: stretch;
    margin-left: rem(-30);
    margin-bottom: rem(55);

    @media (max-width: 991.98px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__item {
    width: 50%;
    padding-left: rem(30);

    @media (max-width: 991.98px) {
      width: 100%;
      margin-bottom: rem(20);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.about-services-card {
  width: 100%;
  height: rem(355);
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15%;

  @media (max-width: 991.98px) {
    padding: 0 15% 0 rem(25);
    height: rem(320);
  }

  @media (max-width: 767.98px) {
    padding: 0 15% 0 rem(20);
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(45, 48, 62, 0.8), rgba(45, 48, 62, 0.8));
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;", serif;
  }

  &__text {
    position: relative;
    font-weight: normal;
    font-size: rem(16);
    line-height: lh(29, 16);
    color: #FFFFFF;
    z-index: 1;

    @media (max-width: 767.98px) {
      font-size: rem(15);
    }

    p {
      font-weight: normal;
      font-size: rem(16);
      line-height: lh(29, 16);
      color: #FFFFFF;

      @media (max-width: 767.98px) {
        font-size: rem(15);
      }
    }
  }
}

.about-advantages {
  &__container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: auto;
    margin-left: rem(-40);

    @media (max-width: 991.98px) {
      flex-wrap: nowrap;
      margin-left: 0;
      width: 100%;
    }
  }

  &__item {
    width: 33.333333%;
    padding-left: rem(40);
    margin-bottom: rem(30);
   
    box-shadow: none;
    position: relative;

    @media (min-width: 992px) {
      margin-right: 0;
    }

    @media (max-width: 991.98px) {
      width: auto;
      padding-left: 0;
      margin-bottom: 0;
    }

    &::before {
      content: "";
      width: 1px;
      height: 100%;
      background: #E2E2E2;
      position: absolute;
      top: 0;
      right: rem(-20);

      @media (max-width: 575.98px) {
        display: none;
      }
    }

    @media (max-width: 1500px) {
      width: 50%;
    }

    @media (max-width: 991.98px) {
      width: rem(390);
    }

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(30);

    @media (max-width: 991.98px) {
      display: none;
    }
  }
}

.about-advantages-card {
  position: relative;
  padding-left: rem(100);

  @media (max-width: 991.98px) {
    padding: rem(90) rem(25) 0;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: rem(65);
    height: rem(65);
    border-radius: 50%;
    background: #D43A42;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991.98px) {
      left: rem(20);
    }
  }

  &__cont {
    padding-top: rem(15);

    @media (max-width: 991.98px) {
      padding-top: 0;
    }

    h5 {
      font-weight: bold;
      font-size: rem(22);
      line-height: lh(35, 22);
      color: #000000;
      margin-bottom: rem(10);

      @media (max-width: 991.98px) {
        margin-bottom: rem(15);
      }
    }

    p {
      font-weight: normal;
      font-size: rem(16);
      line-height: lh(32, 16);
      color: #000000;
    }
  }
}

.about-clients {
  margin-bottom: rem(40);

  @media (max-width: 991.98px) {
    margin-bottom: 0;
  }

  &__cover {
    box-shadow: none;
    margin: 0;
    width: 100%;

    @media (max-width: 991.98px) {
      width: auto;
      margin: 0 rem(-25);
    }

    @media (max-width: 767.98px) {
      margin: 0 rem(-20);
    }

    .swiper-button-wrap {
      display: none;

      @media (max-width: 991.98px) {
        display: flex;
      }
    }
  }

  .section-title__back {
    @media (max-width: 991.98px) {
      display: none;
    }
  }

  .swiper-wrapper {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: auto;
    margin-right: rem(-30);

    @media (max-width: 991.98px) {
      flex-wrap: nowrap;
      margin-right: 0;
      width: 100%;
    }
  }

  .swiper-slide {
    width: calc(25% - #{rem(30)});
    margin-bottom: rem(30);
    box-shadow: none;
    position: relative;

    @media (max-width: 1500px) {
      width: calc(33.333% - #{rem(30)});
    }

    @media (max-width: 991.98px) {
      margin-bottom: 0;
      padding-left: 0;
      width: rem(390);
    }
     @media (max-width: 575.98px) {
      width: 100%;
    }
  }
}

.clients-card {
  &__image {
    position: relative;
    width: 100%;
    padding-top: 62.8%;

    @media (max-width: 767.98px) {
      padding-top: 76.56%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: "object-fit: cover;", serif;
    }
  }
}

.our-branches {
  &--about {
    margin-bottom: rem(55);

    @media (max-width: 991.98px) {
      display: none;
    }

    .map-inside {
      h4 {
        &::after {
          content: none;
        }
      }
    }
  }
}

.footer-nav {
	background: #2D303E;
	box-shadow: rem(0) rem(4) rem(20) rgba(0, 0, 0, 0.1);
	
	@media (max-width: 991.98px) {
   	padding: rem(15) 0;

	}

	@media (max-width: 767.98px) {
		padding-bottom: rem(25);
	}

	&__cover {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 991.98px) {
			flex-wrap: wrap;
		}

		.break {
		  order: 3;	
		  @media (min-width: 768px) {
				display: none
			}
		}
	}

	nav {
		width: 60.4%;
		flex-grow: 0;

		@media (max-width: 1500px) {
			width: 76%;
		}

		@media (max-width: 991.98px) {
    	display: none;
    	width: 100%;
    	order: 5;
    	padding-top: rem(15);
		}

		&.show {
			display: block;
		}

		ul {

			@media (max-width: 991.98px) {
				display: flex;
				flex-wrap: wrap;
			}

			li {
				height: rem(100);
				padding-top: rem(31);
				padding-bottom: rem(26);

				@media (max-width: 991.98px) {
					height: rem(50);
					width: 33.333%;
				}

				@media (max-width: 767.98px) {
					width: 50%;
				}	

				@media (max-width: 575.98px) {
					width: 100%;
				}


				a {
			
				}
			}
		}
	}

	.logo {
		max-width: rem(227);
		width: 100%;
		display: block;

		@media (max-width: 767.98px) {
			order: 4;

		}

		img {
			position: relative;
			left: rem(-5);
		}
	}

	.socials {
		@media (max-width: 1500px) {
			display: none;
		}

		@media (max-width: 991.98px) {
    	display: flex;
    	margin: 0;
		}

		@media (max-width: 767.98px) {
			order: 1;
			margin-bottom: rem(15);
		}

		li {
			@media (max-width: 767.98px) {
				margin: 0;
				margin-right: rem(20);

			}
		}
	}

	&__tel {
		display: block;
		flex-shrink: 0;

		@media (min-width: 992px) {
			display: none;
    }

    @media (max-width: 767.98px) {
			order: 2;
			margin-bottom: rem(15);
		}


    img {
    	width: rem(154);
    }
	}

	&__btn {
		display: block;
		order: 5;
		margin-top: rem(10);

		@media (min-width: 992px) {
	    display: none;
		}
	}
}

.socials {
	display: flex;
	margin-right: auto;
	margin-left: rem(50);
	padding-top: rem(10);

	@media (max-width: 991.98px) {
  	padding-top: 0;  
	}

	li {
		margin: rem(15);

		a {
			display: block;

			&:hover {
				opacity: 0.85;
			}
		}
	}
}

.footer-main {
	background: #1C1F2D;
	box-shadow: rem(0) rem(4) rem(20) rgba(0, 0, 0, 0.1);

	&__cover {
		display: flex;
		justify-content: space-between;
		padding-top: rem(42);
		padding-bottom: rem(40);

		@media (max-width: 767.98px) {
			padding-top: rem(30);
			padding-bottom: rem(10);
			flex-wrap: wrap;
			flex-flow: column;

		}
	}

	&__nav-col {
		position: relative;
		flex-grow: 1;
		display: flex;
		justify-content: center;

		@media (max-width: 1500px) {
			width: 37%;
		}

		@media (max-width: 991.98px) {
    	width: 33.333%;
    	padding-left: rem(20);
    	padding-right: rem(20);
    	justify-content: flex-start;
		}

		@media (max-width: 767.98px) {
    	display: none;
    	margin-bottom: 0;
    	
    	width: 100%;
    	padding-left: 0;
    	padding-right: 0;
		}

		&:first-child {
			justify-content: flex-start;

			@media (max-width: 1500px) {
				width: 26%;
			}

			@media (max-width: 991.98px) {
	    	width: 33.333%;
	    	padding-left: 0;
			}

			@media (max-width: 767.98px) {
    		display: block;
    		width: 100%;
			}

			ul {
				li {
					@media (max-width: 767.98px) {
		    		display: none;
					}

					&:first-child { 
						position: relative;

						@media (max-width: 767.98px) {
			    		display: block;
						}

						a {
							&:after {
								content: "";
								display: none;
								width: rem(17);
								height: rem(9);
								position: absolute;
								top: rem(5);
								right: 0;
								background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-white.svg") no-repeat 50% / contain;
								
								@media (max-width: 767.98px) {
									display: block;
								}
							}
						}
					}

					a {
						@media (max-width: 767.98px) {
			    		width: 100%;
			    		display: block;
						}
					}
				}
			}
		}

		&:nth-child(3) {

			@media (max-width: 991.98px) {
				padding-right: 0;
			}


			&:after {
				
				@media (max-width: 1500px) {
					display: none;
				}
			}
		}


		&:last-child {

			@media (max-width: 1500px) {
				display: none;
			}
		}

		&:after {
			content: "";
			display: block;
			width: rem(1);
			height: rem(222);
			background-color: #2D303E;
			position: absolute;
			right: 0;
			top: 0;

			@media (max-width: 767.98px) {
    		display: none;
			}
		}

		ul {
			li {
				margin-bottom: rem(22);

				&:last-child {
					margin-bottom: 0;
				}

				a {
					font-size: rem(17);
					line-height: rem(20);
					text-decoration-line: underline;
					text-transform: capitalize;
					color: #FFFFFF;
				}
			}
		}

		&.show-mob-nav {
			display: block;
			margin-bottom: rem(40);

			li {
				display: block !important;
				
				&:first-child { 
					a {
						&:after {
							transform: rotate(-180deg);
						}
					}
				}
					
			}
		}
	}

	&__map-wrap {
		position: relative;

		@media (max-width: 767.98px) {
			margin: 0 rem(-20);
		}

		.map-inside {
			position: absolute;
			left: rem(40);
			bottom: rem(40);
			z-index: 5;

			@media (max-width: 767.98px) {
				position: relative;
				left: auto;
				bottom: auto;
				max-width: 100%;
			}
		}

		.socials {
			display: none;
			background: #2D303E;
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: 2;

			@media (max-width: 1500px) {
				display: flex;
				margin-left: 0;
				padding: rem(8) rem(15);

			}

			@media (max-width: 991.98px) {
	    	display: none;
			}
		}


	}

	&__bottom {
		display: flex;
		justify-content: space-between;
		margin-top: rem(10);
		padding: rem(35) 0;

		@media (max-width: 767.98px) {
			flex-flow: column;
			padding: rem(20) 0;
		}

		p {
			color: #9D9FA5;

			@media (max-width: 1500px) {
				font-size: rem(15);
				line-height: rem(23);
			}

			@media (max-width: 767.98px) {
				margin-bottom: rem(18);
			}

			a {
				color: #9D9FA5;
				text-decoration: underline;
			}
		}
	}
}

.map {
	height: rem(395);
	width: 100%;
	position: relative;
	background: url("/design/themes/komtrans_it/media/assets/images/map.jpg") no-repeat 50% / cover;
}

.map-inside {
	max-width: rem(375);
	width: 100%;
	background-color: #fff;
	padding: rem(30) rem(40);
	box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.1);

	@media (max-width: 767.98px) {
		padding: rem(30) rem(20);
	}

	h4 {
		font-weight: bold;
		font-size: rem(24);
		line-height: rem(32);
		color: #000000;
		display: inline-block;
		position: relative;
		padding-right: rem(36);
		margin-bottom: rem(10);

		&:after {
			content: "";
			display: block;
			width: rem(17);
			height: rem(9);
			position: absolute;
			top: rem(13);
			right: 0;
			background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-dark.svg") no-repeat 50% / contain;
		}
	}

	ul {
		li {
			font-weight: 500;
			font-size: rem(16);
			line-height: rem(32);
			display: flex;
			align-items: center;
			padding-left: rem(36);
			position: relative;

			i {
				position: absolute;
				top: rem(8);
				left: 0;
			}

			a {
				color: #000;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	hr {
		margin: rem(18) 0;
		border: none;
		border-top: rem(1) solid #E8E8E8;
	}

	&__drop-down {
		position: relative;

		h4 {
			cursor: pointer;
		}

		ul {
			display: none;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			min-width: rem(200);
			max-height: rem(185);
			overflow: auto;
			background-color: #fff;
			z-index: 2;

			li {
				padding-left: 0;
			}
		}
	}

	&.open {
		.map-inside__drop-down {
			ul {
				display: block;
			}
			h4 {
				&:after {
					transform: rotate(-180deg);
				}
			}
		}
	}

}

.tel-link {
	font-weight: bold;
	font-size: rem(20);
	line-height: rem(32);
	color: #000000;
	padding-left: rem(38);
	position: relative;
	text-decoration: none;

	&:before {
		content: "";
		display: block;
		width: rem(24);
		height: rem(24);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: rem(0);
		background: url("/design/themes/komtrans_it/media/assets/icons/tel-red.svg") no-repeat 50% / contain;
	}

	&:hover {
		text-decoration: underline;
	}
}
.header-wrap {
	position: relative;
  z-index: 50;

	.container {
		@media (max-width: 1500px) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.header-wrap-absolute {
	position: absolute;
	top: 0;
	width: 100%;
	left: 0;
	z-index: 10;

	@media (max-width: 1500px) {
		position: relative;
		top: auto;
	}
}

.header-main {
	background: #2D303E;
	padding: rem(15) rem(40) rem(21);

	@media (max-width: 1500px) {
		padding-left: rem(25);
		padding-right: rem(25);
	}

	@media (max-width: 991.98px) {
		padding-bottom: rem(40);
		padding-top: rem(20);
	}

	&__cover {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 767.98px) {
			flex-flow: column;
			align-items: flex-start;
		}
	}

	&__desc {
		font-weight: 300;
		font-size: rem(16);
		line-height: rem(23);
		color: #B8BBC4;

		@media (max-width: 991.98px) {
	  	display: none;  
		}	
	}

	.logo {

		@media (max-width: 767.98px) {
			order:3;
		}
	}

}

.logo {
	max-width: rem(236);
	width: 100%;
	display: block;
	min-height: 1px;
	flex-shrink: 0;

	@media (max-width: 991.98px) {
  	max-width: rem(226); 
	}	

	img {
		width: 100%;
	}
}


.search {
	width: 41.3%;
	position: relative;

	@media (max-width: 1500px) {
		width: 29%;
	}

	@media (max-width: 991.98px) {
  	position: absolute;
  	bottom: rem(-25);
  	left: rem(20);
		z-index: 3;
  	width: calc(100% - #{rem(40)})
	}	

	&:before {
		content: "";
		display: block;
		width: rem(24);
		height: rem(24);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: rem(18);
		background: url("/design/themes/komtrans_it/media/assets/icons/serach-white.svg") no-repeat 50% / contain;
	}

	input {
		font-family: 'Gilroy', sans-serif;
		background: #2D303E;
		border: 1px solid #515668;
		box-sizing: border-box;
		box-shadow: 0 rem(5) rem(20) rgba(255, 255, 255, 0.05);
		padding-left: rem(60);
		padding-right: rem(30);
		height: rem(50);
		width: 100%;
		font-family: 'Gilroy', sans-serif;
		font-weight: 300;
		font-size: rem(17);
		line-height: rem(23);
		color: #B8BBC4;

		&::placeholder { 

			color: #B8BBC4;
			opacity: 1;
		}
	}
}

.tel-city {
	margin-right: rem(25);
	position: relative;
	min-height: 1px;

	@media (max-width: 1500px) {
		margin-right: rem(5);
	}

	@media (max-width: 767.98px) {
		order: 1;
		margin-bottom: rem(15);
		padding-right: rem(30);
		margin-right: 0;
	}

	> a {
		display: inline-block;
		position: relative;
		text-decoration: none;
		padding-left: rem(40);
		margin-bottom: rem(10);
		
		@media (max-width: 1500px) {
			padding-left: 0;
		}
		@media (max-width: 767.98px) {
			padding-left: rem(30);
			margin-bottom: 0;
		}

		&:before {
			content: "";
			display: block;
			width: rem(24);
			height: rem(24);
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			background: url("/design/themes/komtrans_it/media/assets/icons/tel-white.svg") no-repeat 50% / contain;
			
			@media (max-width: 1500px) {
				display: none;
			}

			@media (max-width: 767.98px) {
				display: block;
				top: 50%;
			}
		}
		img {
			max-width: rem(171);
			width: 100%;

			@media (max-width: 767.98px) {
				max-width: rem(140);
			}
		}
	}

	&__dropdown {
		padding-left: rem(40);
		position: relative;

		@media (max-width: 1500px) {
			padding-left: 0;
		}

		@media (max-width: 767.98px) {
			position: absolute;
			right: 0;
			top: 0;
			width: rem(17);
			height: rem(15);
		}

		a {
			font-weight: 300;
			font-size: rem(17);
			line-height: 1;
			color: #FFFFFF;
			position: relative;
			padding-right: rem(25);
			text-decoration: none;
			display: inline-block;

			@media (max-width: 767.98px) {
				width: 100%;
				height: 100%;
				padding-right: 0;
			}


			span {
				@media (max-width: 767.98px) {
					display: none;
				}
			}
		}

		> a {
			&:after {
				content: "";
				display: block;
				width: rem(17);
				height: rem(9);
				position: absolute;
				top: rem(5);
				right: 0;
				
				background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-white.svg") no-repeat 50% / contain;
				
				@media (max-width: 767.98px) {
					top: 50%;
					right: auto;
					left: 50%;
					transform: translate(-50%, -50%);

				}
			}
		}

		ul {
			display: none;
			position: absolute;
			bottom: 0;
			transform: translateY(100%);
			right: 0;
			padding: rem(15) 0;
			background: #2D303E;
			padding-left: rem(40);
			width: 100%;
			min-width: rem(200);
			z-index: 10;
			max-height: rem(220);
			overflow: auto;

			@media (max-width: 767.98px) {
				bottom: -10px;
			}


			li {

				a {
					display: block;
					padding: rem(10) 0;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

	}

	&.open {
		.tel-city__dropdown {
			ul {
				display: block;
			}
			> a {
				&:after {
					transform: rotate(-180deg);

					@media (max-width: 767.98px) {
						transform: rotate(-180deg) translate(50%, 50%);
					}
				}
			}
		}
	}

}

.header-nav {
	background: #171921;
	box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.1);
	height: rem(63);

	@media (max-width: 991.98px) {
  	display: none;  
	}	


	&__cover {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.catalog-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	height: rem(63);
	padding: 0 rem(60);
	font-size: rem(19);
	line-height: rem(28);
	color: #FFFFFF;
	text-decoration: none;
	background: #1B1D29;
	transition: all .2s;
	white-space: nowrap;
	flex-shrink: 0;

	@media (max-width: 1500px) {
		padding-left: rem(30);
		padding-right: rem(40);
	}

	& > span {
		@media (max-width: 1500px) {
			display: none;
		}
	}

	.hamburger {
		margin-right: rem(25);
		
	}

	&:hover {
		background: #D43A42;

		& > div {
			& > div {
				background-color: #fff;
			}
		}
	}
}

.hamburger {
	width: rem(25);
	height: rem(16);
	display: flex;
	flex-flow: column;
	justify-content: space-between;

	& > div {
		display: block;
		width: 100%;
		height: 2px;
		background: #000;
		transition: all .2s;
		background: #D43A42;
		box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.1);
	}
}

.close-menu {
	.hamburger div {
		position: relative;

		&:nth-child(2) {
			opacity: 0;
		}

		&:nth-child(1) {
			transform: rotate(45deg);
			top: calc(50% - 1px);
		}

		&:nth-child(3) {
			transform: rotate(-45deg);
			bottom: calc(50% - 1px);
		}
	}
}

nav {
	flex-grow: 1;

	ul {
		display: flex;
		align-items: center;
		width: 100%;

		li {
			padding-top: rem(12);
			padding-bottom: rem(9);
			height: rem(63);
			flex-grow: 1;
			transition: all .2s;

			&:hover {
				background: #D43A42;

				a {
					border-right-color: #D43A42;
				}
			}

			&:last-child {
				a {
					border-right: none;
				}
			}

			a {
				display: inline-block;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #3E4151;
				font-size: rem(19);
				line-height: rem(28);
				color: #FFFFFF;
				text-decoration: none;
				transition: all .2s;

				&:hover {
					// background: #D43A42;
					border-right-color: #D43A42;
				}
			}
		}
	}
}

.mob-menu-btn {
	display: block;
	width: rem(25);
	height: rem(16);

	@media (min-width: 992px) {
		display: none;
	}

	@media (max-width: 767.98px) {
		position: absolute;
		right: rem(20);
		top: rem(20);
	}

	& > div {
		margin-right: rem(25);
		width: rem(25);
		height: rem(16);
		display: flex;
		flex-flow: column;
		justify-content: space-between;

		& > div {
			display: block;
			width: 100%;
			height: 2px;
			background: #000;
			transition: all .2s;
			background: #D43A42;
			box-shadow: 0 rem(4) rem(20) rgba(0, 0, 0, 0.1);
		}
	}
}

.mob-menu {
	position: absolute;
	top: 0;
	left: 0;
	padding: rem(25);
	padding-top: rem(60);
	background: #FFFFFF;
	box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
	z-index: 5;
	width: 100%;
	display: none;

	&.show {
		display: block;
	}

	@media (max-width: 767.98px) {
		padding: rem(20);
		padding-top: rem(50);
	}

	&__close {
		display: block;
		width: rem(19);
		height: rem(19);
		position: absolute;
		right: rem(25);
		top: rem(25);
		background: url("/design/themes/komtrans_it/media/assets/icons/cross.svg") no-repeat 50% / contain;
		
		@media (max-width: 767.98px) {
			right: rem(20);
			top: rem(20);
		}
	}

	&__dropdown {
		position: relative;

		&:after {
			content: "";
			display: block;
			width: rem(17);
			height: rem(9);
			background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-dark.svg") no-repeat 50%;
			position: absolute;
			right: 0;
			top: rem(21);
		}

		> ul {
			display: none;
			padding-left: rem(25);

			@media (max-width: 767.98px) {
				padding-left: rem(20);
			}
		}

		&.open {

			&:after {
				transform: rotate(180deg);
			}

			> ul {
				display: block;
			}
		}

	}

	ul {
		li {
			a {
				font-size: rem(19);
				line-height: rem(28);
				text-decoration-line: underline;
				color: #000000;
				padding: rem(11) 0;
				display: block;
			}
		}
	}

	> ul {
		> li {
			border-bottom: 1px solid #E3E3E3;
			box-shadow: 0 rem(5) rem(20) rgba(255, 255, 255, 0.05);
			
			&:last-child {
				border-bottom: none;
			}

			> ul {
				> li {
					> a {
						color: #D43A42;
						text-decoration: none;
					}
				}
			}
		}
	}
}
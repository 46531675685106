.article {
  @media (max-width: 991.98px) {
    padding-top: rem(50);
  }

  * {
    box-sizing: border-box;
  }
  h1 {
    font-weight: bold;
    font-size: rem(41);
    line-height: lh(50, 41);
    color: #000000;
    margin-bottom: rem(20);
    text-transform: initial;

    @media (max-width: 767.98px) {
      font-size: rem(30);
      margin-bottom: 0;
    }
  }

  h2, h3, h4, h5, h6 {
    line-height: normal;
  }

  .section-title-outside h2 {
    font-weight: bold;
    font-size: rem(56);
    line-height: rem(75);
    color: #ECECEC;
    white-space: nowrap;
    margin-bottom: 0;
  }

  .section-title__back {
    line-height: rem(60);
  }

  ul {
    margin-bottom: rem(35);

    @media (max-width: 767.98px) {
      margin-bottom: rem(30);
    }

    li {
      font-weight: 300;
      font-size: rem(16);
      line-height: lh(32, 16);
      color: #000000;
      margin-bottom: rem(20);
      position: relative;
      padding-left: rem(38);
      width: 100%;
      max-width: rem(680);

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: rem(12);
        left: 0;
        width: rem(6);
        height: rem(6);
        border-radius: 50%;
        background: #000;
      }

      @media (max-width: 767.98px) {
        max-width: none;
        font-size: rem(15);
      }
    }

    &.check-ul {
      li {
        padding-left: rem(50);

        &::before {
          width: rem(24);
          height: rem(24);
          top: rem(5);
          background: #D43A42;
        }

        &::after {
          content: "";
          position: absolute;
          top: rem(12);
          left: rem(6);
          width: rem(12);
          height: rem(9);
          background: url("/design/themes/komtrans_it/media/assets/icons/check-white.svg") no-repeat 50%/contain;
        }
      }
    }
  }

  h2 {
    font-weight: bold;
    font-size: rem(38);
    line-height: lh(53, 38);
    color: #000000;
    margin-bottom: rem(20);

    @media (max-width: 767.98px) {
      font-size: rem(26);
      margin-bottom: rem(10);
    }
  }

  h3 {
    font-weight: bold;
    font-size: rem(34);
    line-height: lh(43, 34);
    color: #000000;
    margin-bottom: rem(20);

    @media (max-width: 767.98px) {
      font-size: rem(24);
      margin-bottom: rem(10);
    }
  }

  h4 {
    font-weight: bold;
    font-size: rem(25);
    line-height: lh(32, 25);
    color: #000000;
    margin-bottom: rem(20);

    @media (max-width: 767.98px) {
      font-size: rem(21);
      margin-bottom: rem(10);
    }
  }

  hr {
    width: 100%;
    height: 1px;
    background: #B8B8B8;
    margin: rem(45) 0;

    @media (max-width: 767.98px) {
      margin: rem(40) 0;
    }
  }

  blockquote {
    padding: rem(50) rem(35) rem(35) rem(55);
    background: #F5F7FF;
    font-weight: 300;
    font-size: rem(16);
    line-height: lh(32, 16);
    color: #000000;
    margin-bottom: rem(60);

    @media (max-width: 767.98px) {
      margin: 0 rem(-20);
      padding: rem(20) rem(30) rem(40);
      font-size: rem(15);
    }

    strong {
      font-weight: bold;
    }
  }

  p {
    font-weight: 300;
    font-size: rem(16);
    line-height: lh(32, 16);
    color: #000000;
    margin: rem(15) 0;

    &:first-child {
      margin-top: 0;
    }
  }

  &__row {
    display: flex;
    align-items: stretch;
    margin: 0 rem(-25) rem(60);

    &--reverse {
      flex-direction: row-reverse;
    }

    @media (max-width: 991.98px) {
      flex-direction: column;
    }

    @media (max-width: 767.98px) {
      margin: 0 rem(-20) rem(40);
    }
  }

  &__col {
    padding: 0 rem(25);

    @media (max-width: 767.98px) {
      padding: 0 rem(20);
    }

    &:first-child {
      width: 40%;

      @media (max-width: 991.98px) {
        width: 100%;
        margin-bottom: rem(30);
      }
    }

    &:last-child {
      width: 60%;

      @media (max-width: 991.98px) {
        width: 100%;
      }
    }
  }

  &__ul {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin-left: rem(-30);
    margin-top: rem(45);

    @media (max-width: 767.98px) {
      margin-top: rem(30);
    }

    ul {
      width: 50%;
      padding-left: rem(30);

      @media (max-width: 767.98px) {
        width: auto;
      }
    }
  }

  &__image {
    width: 100%;
    position: relative;
    height: rem(330);

    @media (max-width: 767.98px) {
      // height: rem(200);
      margin: 0 rem(-20);
      width: auto;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: "object-fit: cover;", serif;
    }
  }

  img {
    width: 100%;
    display: block;
  }
}

.our-branches--article {
  margin-top: rem(35);

  @media (max-width: 767.98px) {
    display: none;
  }
  .map-inside h4:after {
    content: none;
  }
}



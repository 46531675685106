@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align: middle;
  outline: none;
}

a {
  color: inherit;
}

img {
  display: block;
  max-width: 100%;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

@media (max-width: 1934.99px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1720px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 1500px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1199px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1100px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 991.98px) {
  html {
    font-size: 16px;
  }
}

body {
  font-family: 'Gilroy', sans-serif;
  background-color: #fffffd;
  color: #000;
}

p {
  font-weight: 300;
  font-size: 1rem;
  line-height: 2rem;
  color: #616161;
}

@media (max-width: 767.98px) {
  p {
    font-size: 0.9375rem;
    line-height: 1.875rem;
  }
}

h1 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 4.6875rem;
  text-transform: uppercase;
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 2.125rem;
    line-height: 3.125rem;
  }
}

h2 {
  font-weight: bold;
  font-size: 2.5625rem;
  line-height: 3.125rem;
}

@media (max-width: 767.98px) {
  h2 {
    font-size: 1.875rem;
    line-height: 2.8125rem;
  }
}

h3 {
  font-weight: bold;
  font-size: 2.375rem;
  line-height: 4.6875rem;
}

@media (max-width: 767.98px) {
  h3 {
    font-size: 1.875rem;
    line-height: 2.8125rem;
  }
}

h4 {
  font-weight: bold;
  font-size: 2.125rem;
  line-height: 3.9375rem;
}

@media (max-width: 767.98px) {
  h4 {
    font-size: 1.625rem;
    line-height: 2.625rem;
  }
}

h5 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2.125rem;
}

time {
  font-size: 16px;
  line-height: 32px;
  color: #9E9E9E;
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 105rem;
  padding: 0 0.9375rem;
  position: relative;
}

@media (max-width: 1500px) {
  .container {
    padding: 0 3.125rem;
  }
}

@media (max-width: 991.98px) {
  .container {
    padding: 0 1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .container {
    padding: 0 1.25rem;
  }
}

@media (min-width: 768px) {
  .mob-visible {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .desk-visible {
    display: none !important;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #D43A42;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
  padding: 0 2.8125rem;
  height: 4rem;
  line-height: 4rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.9375rem;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: all .3s;
}

.btn:hover {
  background-color: #171921;
}

.btn-secondary {
  background: #2D303E;
}

.btn-secondary:hover {
  background: #D1D1D1;
  box-shadow: none;
}

.btn-light {
  background: #F9F9F9;
  color: #000000;
}

.btn-light:hover {
  color: #fff;
  background-color: #171921;
}

.swiper-button-next, .swiper-button-prev {
  width: 4rem;
  height: 4rem;
  background: #E5E6EA;
  margin: 0;
  top: auto;
  right: auto;
  left: auto;
  position: relative;
  margin: 0 0.3125rem;
  outline: none;
  transition: all .3s;
}

@media (max-width: 991.98px) {
  .swiper-button-next, .swiper-button-prev {
    width: 2.875rem;
    height: 2.875rem;
  }
}

.swiper-button-next:after, .swiper-button-prev:after {
  content: "";
  display: block;
  width: 0.5625rem;
  height: 1.0625rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  background-color: #171921;
}

.swiper-button-next:after {
  background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-next-dark.svg");
}

.swiper-button-next:hover:after {
  background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-next-white.svg");
}

.swiper-button-prev:after {
  background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-prev-dark.svg");
}

.swiper-button-prev:hover:after {
  background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-prev-white.svg");
}

.swiper-wrap {
  width: calc(100vw - (100vw - 103.125rem) / 2);
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
}

@media (max-width: 1500px) {
  .swiper-wrap {
    width: auto;
    margin-right: -3.125rem;
  }
}

@media (max-width: 991.98px) {
  .swiper-wrap {
    margin: 0 -1.5625rem;
    box-shadow: none;
  }
}

@media (max-width: 767.98px) {
  .swiper-wrap {
    margin: 0 -1.25rem;
  }
}

.swiper-button-wrap {
  display: flex;
  position: absolute;
  top: -5.9375rem;
  right: 0;
  padding: 0 1.5625rem;
}

@media (max-width: 991.98px) {
  .swiper-button-wrap {
    top: 0.9375rem;
  }
}

@media (max-width: 767.98px) {
  .swiper-button-wrap {
    padding: 0 0.625rem;
  }
}

.swiper-slide {
  height: auto;
  width: auto;
  margin-right: 1.875rem;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
}

@media (max-width: 991.98px) {
  .swiper-slide {
    margin-right: 0.9375rem;
    margin-left: 0.9375rem;
  }
}

@media (max-width: 575.98px) {
  .swiper-slide {
    width: 100%;
  }
}

.swiper-container-fade .swiper-slide {
  opacity: 1 !important;
}

.swiper-container-fade .swiper-slide:not(.swiper-slide-active) {
  opacity: 0 !important;
}

@media (max-width: 991.98px) {
  .swiper-container {
    overflow: visible;
  }
}

@media (max-width: 767.98px) {
  .mob-swiper-button-center {
    margin: 0;
  }
  .mob-swiper-button-center .swiper-button-wrap {
    position: static;
  }
  .mob-swiper-button-center .swiper-button-prev,
  .mob-swiper-button-center .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
  }
  .mob-swiper-button-center .swiper-button-prev {
    left: -1.4375rem;
  }
  .mob-swiper-button-center .swiper-button-next {
    right: -1.4375rem;
  }
}

.swiper-pagination-bullet {
  background-color: #fff;
  opacity: 0.5;
  width: 12px;
  height: 12px;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
}

.issue-leasing {
  padding-top: 1.5625rem;
}

.select2-container {
  outline: none;
}

.select2-container:focus {
  outline: none;
}

.select-wrap .select2-container {
  width: 100%;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 3.125rem;
  padding-right: 3.125rem;
  font-weight: 300;
  font-size: 1.0625rem;
  padding-left: 1.375rem;
}

.select2-container .select2-selection--single {
  height: 3.125rem;
  border-radius: 0;
  outline: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 3.125rem;
  width: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: none;
  margin: 0;
  width: 1.0625rem;
  height: 0.5625rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-dark.svg") no-repeat 50%/contain;
  transform: translateY(-50%);
  position: relative;
  top: auto;
  left: auto;
  transform: translate(0);
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border: none;
  transform: rotate(180deg);
}

.irs--flat {
  height: 45px;
  font-family: 'Gilroy', sans-serif;
}

.irs > .irs {
  height: 9px;
  cursor: pointer;
}

.irs--flat .irs-line {
  height: 13px;
  background: none;
  top: -2px;
}

.irs--flat .irs-line:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #DADADA;
  position: absolute;
  top: 6px;
  left: 0px;
}

.irs--flat .irs-bar {
  height: 9px;
  background-color: #878FB3;
  border-radius: 0.1875rem;
  top: 0px;
  cursor: pointer;
}

.irs:hover .irs-bar {
  background-color: #D43A42;
}

.irs--flat .irs-handle {
  top: -5px;
  cursor: pointer;
}

.irs--flat .irs-handle > i:first-child {
  background: none;
}

.irs--flat .irs-handle.state_hover > i:first-child, .irs--flat .irs-handle:hover > i:first-child {
  background: none;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  background: none;
  cursor: pointer;
}

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
  display: none;
}

.irs-from, .irs-to, .irs-single {
  top: auto;
  bottom: -38px;
}

.checkbox-input {
  position: relative;
}

.checkbox-input label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 2.8125rem;
  margin-right: 1.875rem;
  margin-bottom: 1.5625rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 2rem;
  color: #000000;
}

.checkbox-input label:before {
  content: "";
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  left: 0rem;
  top: 50%;
  transform: translate(0, -50%);
  background: #F3F3F3;
}

.checkbox-input label:after {
  content: "";
  display: none;
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  left: 0.5rem;
  top: 0.75rem;
  background-color: #878FB2;
}

.checkbox-input input[type=radio],
.checkbox-input input[type=checkbox] {
  display: none;
}

.checkbox-input button {
  width: 6.875rem;
  margin: 1.25rem auto;
  margin-top: 0.625rem;
  height: 1.875rem;
  cursor: pointer;
  outline: none;
  background-color: #c92f35;
  text-transform: uppercase;
  color: #fff;
  font-size: 0.875rem;
  border: none;
  display: block;
}

.checkbox-input input[type=radio]:checked + label:after {
  display: block;
}

.checkbox-input input[type=checkbox]:checked + label:after {
  border-radius: 0;
  display: block;
}

.radio-input label:before {
  border-radius: 50%;
}

.radio-input label:after {
  border-radius: 50%;
}

@media (max-width: 991.98px) {
  .first-block-offset {
    padding-top: 3.75rem;
  }
}

@media (max-width: 767.98px) {
  .first-block-offset {
    padding-top: 1.25rem;
  }
}

.icon {
  display: block;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  flex-shrink: 0;
}

.ig-icon {
  width: 1.3125rem;
  height: 1.3125rem;
  background-image: url("/design/themes/komtrans_it/media/assets/icons/ig.svg");
}

.vk-icon {
  width: 1.3125rem;
  height: 1.3125rem;
  background-image: url("/design/themes/komtrans_it/media/assets/icons/vk.svg");
}

.fb-icon {
  width: 1.3125rem;
  height: 1.3125rem;
  background-image: url("/design/themes/komtrans_it/media/assets/icons/fb.svg");
}

.time-light-icon {
  width: 1.1875rem;
  height: 1.1875rem;
  background-image: url("/design/themes/komtrans_it/media/assets/icons/time-light.svg");
}

.mail-light-icon {
  width: 1.1875rem;
  height: 1.1875rem;
  background-image: url("/design/themes/komtrans_it/media/assets/icons/mail-light.svg");
}

.location-light-icon {
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("/design/themes/komtrans_it/media/assets/icons/location-light.svg");
}

.equipment-icon {
  width: 2.5rem;
  height: 2.4375rem;
  background-image: url("/design/themes/komtrans_it/media/assets/icons/equipment-white.svg");
}

.locations-icon {
  width: 2.375rem;
  height: 2.375rem;
  background-image: url("/design/themes/komtrans_it/media/assets/icons/locations-white.svg");
}

.header-wrap {
  position: relative;
  z-index: 50;
}

@media (max-width: 1500px) {
  .header-wrap .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.header-wrap-absolute {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
}

@media (max-width: 1500px) {
  .header-wrap-absolute {
    position: relative;
    top: auto;
  }
}

.header-main {
  background: #2D303E;
  padding: 0.9375rem 2.5rem 1.3125rem;
}

@media (max-width: 1500px) {
  .header-main {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}

@media (max-width: 991.98px) {
  .header-main {
    padding-bottom: 2.5rem;
    padding-top: 1.25rem;
  }
}

.header-main__cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767.98px) {
  .header-main__cover {
    flex-flow: column;
    align-items: flex-start;
  }
}

.header-main__desc {
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: #B8BBC4;
}

@media (max-width: 991.98px) {
  .header-main__desc {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .header-main .logo {
    order: 3;
  }
}

.logo {
  max-width: 14.75rem;
  width: 100%;
  display: block;
  min-height: 1px;
  flex-shrink: 0;
}

@media (max-width: 991.98px) {
  .logo {
    max-width: 14.125rem;
  }
}

.logo img {
  width: 100%;
}

.search {
  width: 41.3%;
  position: relative;
}

@media (max-width: 1500px) {
  .search {
    width: 29%;
  }
}

@media (max-width: 991.98px) {
  .search {
    position: absolute;
    bottom: -1.5625rem;
    left: 1.25rem;
    z-index: 3;
    width: calc(100% - 2.5rem);
  }
}

.search:before {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.125rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/serach-white.svg") no-repeat 50%/contain;
}

.search input {
  font-family: 'Gilroy', sans-serif;
  background: #2D303E;
  border: 1px solid #515668;
  box-sizing: border-box;
  box-shadow: 0 0.3125rem 1.25rem rgba(255, 255, 255, 0.05);
  padding-left: 3.75rem;
  padding-right: 1.875rem;
  height: 3.125rem;
  width: 100%;
  font-family: 'Gilroy', sans-serif;
  font-weight: 300;
  font-size: 1.0625rem;
  line-height: 1.4375rem;
  color: #B8BBC4;
}

.search input::placeholder {
  color: #B8BBC4;
  opacity: 1;
}

.tel-city {
  margin-right: 1.5625rem;
  position: relative;
  min-height: 1px;
}

@media (max-width: 1500px) {
  .tel-city {
    margin-right: 0.3125rem;
  }
}

@media (max-width: 767.98px) {
  .tel-city {
    order: 1;
    margin-bottom: 0.9375rem;
    padding-right: 1.875rem;
    margin-right: 0;
  }
}

.tel-city > a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  padding-left: 2.5rem;
  margin-bottom: 0.625rem;
}

@media (max-width: 1500px) {
  .tel-city > a {
    padding-left: 0;
  }
}

@media (max-width: 767.98px) {
  .tel-city > a {
    padding-left: 1.875rem;
    margin-bottom: 0;
  }
}

.tel-city > a:before {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: url("/design/themes/komtrans_it/media/assets/icons/tel-white.svg") no-repeat 50%/contain;
}

@media (max-width: 1500px) {
  .tel-city > a:before {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .tel-city > a:before {
    display: block;
    top: 50%;
  }
}

.tel-city > a img {
  max-width: 10.6875rem;
  width: 100%;
}

@media (max-width: 767.98px) {
  .tel-city > a img {
    max-width: 8.75rem;
  }
}

.tel-city__dropdown {
  padding-left: 2.5rem;
  position: relative;
}

@media (max-width: 1500px) {
  .tel-city__dropdown {
    padding-left: 0;
  }
}

@media (max-width: 767.98px) {
  .tel-city__dropdown {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.0625rem;
    height: 0.9375rem;
  }
}

.tel-city__dropdown a {
  font-weight: 300;
  font-size: 1.0625rem;
  line-height: 1;
  color: #FFFFFF;
  position: relative;
  padding-right: 1.5625rem;
  text-decoration: none;
  display: inline-block;
}

@media (max-width: 767.98px) {
  .tel-city__dropdown a {
    width: 100%;
    height: 100%;
    padding-right: 0;
  }
}

@media (max-width: 767.98px) {
  .tel-city__dropdown a span {
    display: none;
  }
}

.tel-city__dropdown > a:after {
  content: "";
  display: block;
  width: 1.0625rem;
  height: 0.5625rem;
  position: absolute;
  top: 0.3125rem;
  right: 0;
  background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-white.svg") no-repeat 50%/contain;
}

@media (max-width: 767.98px) {
  .tel-city__dropdown > a:after {
    top: 50%;
    right: auto;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.tel-city__dropdown ul {
  display: none;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  right: 0;
  padding: 0.9375rem 0;
  background: #2D303E;
  padding-left: 2.5rem;
  width: 100%;
  min-width: 12.5rem;
  z-index: 10;
  max-height: 13.75rem;
  overflow: auto;
}

@media (max-width: 767.98px) {
  .tel-city__dropdown ul {
    bottom: -10px;
  }
}

.tel-city__dropdown ul li a {
  display: block;
  padding: 0.625rem 0;
}

.tel-city__dropdown ul li a:hover {
  text-decoration: underline;
}

.tel-city.open .tel-city__dropdown ul {
  display: block;
}

.tel-city.open .tel-city__dropdown > a:after {
  transform: rotate(-180deg);
}

@media (max-width: 767.98px) {
  .tel-city.open .tel-city__dropdown > a:after {
    transform: rotate(-180deg) translate(50%, 50%);
  }
}

.header-nav {
  background: #171921;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
  height: 3.9375rem;
}

@media (max-width: 991.98px) {
  .header-nav {
    display: none;
  }
}

.header-nav__cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.catalog-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.9375rem;
  padding: 0 3.75rem;
  font-size: 1.1875rem;
  line-height: 1.75rem;
  color: #FFFFFF;
  text-decoration: none;
  background: #1B1D29;
  transition: all .2s;
  white-space: nowrap;
  flex-shrink: 0;
}

@media (max-width: 1500px) {
  .catalog-btn {
    padding-left: 1.875rem;
    padding-right: 2.5rem;
  }
}

@media (max-width: 1500px) {
  .catalog-btn > span {
    display: none;
  }
}

.catalog-btn .hamburger {
  margin-right: 1.5625rem;
}

.catalog-btn:hover {
  background: #D43A42;
}

.catalog-btn:hover > div > div {
  background-color: #fff;
}

.hamburger {
  width: 1.5625rem;
  height: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.hamburger > div {
  display: block;
  width: 100%;
  height: 2px;
  background: #000;
  transition: all .2s;
  background: #D43A42;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
}

.close-menu .hamburger div {
  position: relative;
}

.close-menu .hamburger div:nth-child(2) {
  opacity: 0;
}

.close-menu .hamburger div:nth-child(1) {
  transform: rotate(45deg);
  top: calc(50% - 1px);
}

.close-menu .hamburger div:nth-child(3) {
  transform: rotate(-45deg);
  bottom: calc(50% - 1px);
}

nav {
  flex-grow: 1;
}

nav ul {
  display: flex;
  align-items: center;
  width: 100%;
}

nav ul li {
  padding-top: 0.75rem;
  padding-bottom: 0.5625rem;
  height: 3.9375rem;
  flex-grow: 1;
  transition: all .2s;
}

nav ul li:hover {
  background: #D43A42;
}

nav ul li:hover a {
  border-right-color: #D43A42;
}

nav ul li:last-child a {
  border-right: none;
}

nav ul li a {
  display: inline-block;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #3E4151;
  font-size: 1.1875rem;
  line-height: 1.75rem;
  color: #FFFFFF;
  text-decoration: none;
  transition: all .2s;
}

nav ul li a:hover {
  border-right-color: #D43A42;
}

.mob-menu-btn {
  display: block;
  width: 1.5625rem;
  height: 1rem;
}

@media (min-width: 992px) {
  .mob-menu-btn {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .mob-menu-btn {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
  }
}

.mob-menu-btn > div {
  margin-right: 1.5625rem;
  width: 1.5625rem;
  height: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.mob-menu-btn > div > div {
  display: block;
  width: 100%;
  height: 2px;
  background: #000;
  transition: all .2s;
  background: #D43A42;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
}

.mob-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.5625rem;
  padding-top: 3.75rem;
  background: #FFFFFF;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
  z-index: 5;
  width: 100%;
  display: none;
}

.mob-menu.show {
  display: block;
}

@media (max-width: 767.98px) {
  .mob-menu {
    padding: 1.25rem;
    padding-top: 3.125rem;
  }
}

.mob-menu__close {
  display: block;
  width: 1.1875rem;
  height: 1.1875rem;
  position: absolute;
  right: 1.5625rem;
  top: 1.5625rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/cross.svg") no-repeat 50%/contain;
}

@media (max-width: 767.98px) {
  .mob-menu__close {
    right: 1.25rem;
    top: 1.25rem;
  }
}

.mob-menu__dropdown {
  position: relative;
}

.mob-menu__dropdown:after {
  content: "";
  display: block;
  width: 1.0625rem;
  height: 0.5625rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-dark.svg") no-repeat 50%;
  position: absolute;
  right: 0;
  top: 1.3125rem;
}

.mob-menu__dropdown > ul {
  display: none;
  padding-left: 1.5625rem;
}

@media (max-width: 767.98px) {
  .mob-menu__dropdown > ul {
    padding-left: 1.25rem;
  }
}

.mob-menu__dropdown.open:after {
  transform: rotate(180deg);
}

.mob-menu__dropdown.open > ul {
  display: block;
}

.mob-menu ul li a {
  font-size: 1.1875rem;
  line-height: 1.75rem;
  text-decoration-line: underline;
  color: #000000;
  padding: 0.6875rem 0;
  display: block;
}

.mob-menu > ul > li {
  border-bottom: 1px solid #E3E3E3;
  box-shadow: 0 0.3125rem 1.25rem rgba(255, 255, 255, 0.05);
}

.mob-menu > ul > li:last-child {
  border-bottom: none;
}

.mob-menu > ul > li > ul > li > a {
  color: #D43A42;
  text-decoration: none;
}

.main {
  display: block;
}

.breadcrumb {
  margin: 2.1875rem 0;
}

@media (max-width: 1500px) {
  .breadcrumb {
    margin-bottom: 1.5625rem;
  }
}

@media (max-width: 991.98px) {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb li {
  display: inline;
}

.breadcrumb li a {
  font-size: 0.9375rem;
  line-height: 1.8125rem;
  text-decoration-line: underline;
  color: #A4A4A4;
}

.breadcrumb li:after {
  content: "-";
  padding: 0 0.3125rem;
  font-size: 0.9375rem;
  line-height: 1.8125rem;
  text-decoration-line: underline;
  color: #A4A4A4;
  text-decoration: none;
  padding-right: 0.125rem;
}

.breadcrumb li:last-child a {
  color: #000000;
}

.breadcrumb li:last-child:after {
  content: "";
}

.section-title {
  padding-bottom: 1.875rem;
  position: relative;
}

@media (max-width: 1500px) {
  .section-title {
    padding-bottom: 1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .section-title {
    margin-top: 1.5625rem;
    padding-bottom: 1.25rem;
  }
}

.section-title h2 {
  position: relative;
  z-index: 2;
}

.section-title__back {
  font-weight: 800;
  font-size: 10.125rem;
  line-height: 4.8125rem;
  color: #000000;
  opacity: 0.05;
  position: absolute;
  bottom: 18%;
  left: 0;
  white-space: nowrap;
  pointer-events: none;
}

@media (max-width: 1500px) {
  .section-title__back {
    font-size: 8.625rem;
    bottom: 13%;
  }
}

@media (max-width: 991.98px) {
  .section-title__back {
    font-size: 6.25rem;
  }
}

@media (max-width: 767.98px) {
  .section-title__back {
    font-size: 3.9375rem;
    bottom: -12%;
  }
}

@media (max-width: 1500px) {
  .section-title__back .hide-1 {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .section-title__back .hide-2 {
    display: none;
  }
}

.section-title-outside {
  position: absolute;
  z-index: 10;
  transform: rotate(-90deg);
  left: -21.875rem;
  top: 22.5rem;
}

.section-title-outside__cover {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 37.5rem;
}

.section-title-outside h2 {
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 4.6875rem;
  color: #ECECEC;
  white-space: nowrap;
}

.section-title-outside .title-num {
  margin-right: 2rem;
}

.title-num {
  display: flex;
  align-items: center;
}

.title-num__number {
  font-size: 3.125rem;
}

.title-num__number-small {
  font-size: 1rem;
  padding-left: 0.5rem;
  padding-bottom: 1.5625rem;
  opacity: 0.4;
}

.title-num__lines {
  display: flex;
  align-items: flex-end;
  position: relative;
  top: -1.25rem;
}

.title-num__line {
  display: inline-block;
  width: 1px;
  height: 2.625rem;
  margin-right: 0.6875rem;
  background-color: #000;
}

.title-num__line.long {
  height: 4.8125rem;
}

.swiper-wrap {
  position: relative;
}

.footer-nav {
  background: #2D303E;
  box-shadow: 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
}

@media (max-width: 991.98px) {
  .footer-nav {
    padding: 0.9375rem 0;
  }
}

@media (max-width: 767.98px) {
  .footer-nav {
    padding-bottom: 1.5625rem;
  }
}

.footer-nav__cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 991.98px) {
  .footer-nav__cover {
    flex-wrap: wrap;
  }
}

.footer-nav__cover .break {
  order: 3;
}

@media (min-width: 768px) {
  .footer-nav__cover .break {
    display: none;
  }
}

.footer-nav nav {
  width: 60.4%;
  flex-grow: 0;
}

@media (max-width: 1500px) {
  .footer-nav nav {
    width: 76%;
  }
}

@media (max-width: 991.98px) {
  .footer-nav nav {
    display: none;
    width: 100%;
    order: 5;
    padding-top: 0.9375rem;
  }
}

.footer-nav nav.show {
  display: block;
}

@media (max-width: 991.98px) {
  .footer-nav nav ul {
    display: flex;
    flex-wrap: wrap;
  }
}

.footer-nav nav ul li {
  height: 6.25rem;
  padding-top: 1.9375rem;
  padding-bottom: 1.625rem;
}

@media (max-width: 991.98px) {
  .footer-nav nav ul li {
    height: 3.125rem;
    width: 33.333%;
  }
}

@media (max-width: 767.98px) {
  .footer-nav nav ul li {
    width: 50%;
  }
}

@media (max-width: 575.98px) {
  .footer-nav nav ul li {
    width: 100%;
  }
}

.footer-nav .logo {
  max-width: 14.1875rem;
  width: 100%;
  display: block;
}

@media (max-width: 767.98px) {
  .footer-nav .logo {
    order: 4;
  }
}

.footer-nav .logo img {
  position: relative;
  left: -0.3125rem;
}

@media (max-width: 1500px) {
  .footer-nav .socials {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .footer-nav .socials {
    display: flex;
    margin: 0;
  }
}

@media (max-width: 767.98px) {
  .footer-nav .socials {
    order: 1;
    margin-bottom: 0.9375rem;
  }
}

@media (max-width: 767.98px) {
  .footer-nav .socials li {
    margin: 0;
    margin-right: 1.25rem;
  }
}

.footer-nav__tel {
  display: block;
  flex-shrink: 0;
}

@media (min-width: 992px) {
  .footer-nav__tel {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .footer-nav__tel {
    order: 2;
    margin-bottom: 0.9375rem;
  }
}

.footer-nav__tel img {
  width: 9.625rem;
}

.footer-nav__btn {
  display: block;
  order: 5;
  margin-top: 0.625rem;
}

@media (min-width: 992px) {
  .footer-nav__btn {
    display: none;
  }
}

.socials {
  display: flex;
  margin-right: auto;
  margin-left: 3.125rem;
  padding-top: 0.625rem;
}

@media (max-width: 991.98px) {
  .socials {
    padding-top: 0;
  }
}

.socials li {
  margin: 0.9375rem;
}

.socials li a {
  display: block;
}

.socials li a:hover {
  opacity: 0.85;
}

.footer-main {
  background: #1C1F2D;
  box-shadow: 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
}

.footer-main__cover {
  display: flex;
  justify-content: space-between;
  padding-top: 2.625rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 767.98px) {
  .footer-main__cover {
    padding-top: 1.875rem;
    padding-bottom: 0.625rem;
    flex-wrap: wrap;
    flex-flow: column;
  }
}

.footer-main__nav-col {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

@media (max-width: 1500px) {
  .footer-main__nav-col {
    width: 37%;
  }
}

@media (max-width: 991.98px) {
  .footer-main__nav-col {
    width: 33.333%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    justify-content: flex-start;
  }
}

@media (max-width: 767.98px) {
  .footer-main__nav-col {
    display: none;
    margin-bottom: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.footer-main__nav-col:first-child {
  justify-content: flex-start;
}

@media (max-width: 1500px) {
  .footer-main__nav-col:first-child {
    width: 26%;
  }
}

@media (max-width: 991.98px) {
  .footer-main__nav-col:first-child {
    width: 33.333%;
    padding-left: 0;
  }
}

@media (max-width: 767.98px) {
  .footer-main__nav-col:first-child {
    display: block;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .footer-main__nav-col:first-child ul li {
    display: none;
  }
}

.footer-main__nav-col:first-child ul li:first-child {
  position: relative;
}

@media (max-width: 767.98px) {
  .footer-main__nav-col:first-child ul li:first-child {
    display: block;
  }
}

.footer-main__nav-col:first-child ul li:first-child a:after {
  content: "";
  display: none;
  width: 1.0625rem;
  height: 0.5625rem;
  position: absolute;
  top: 0.3125rem;
  right: 0;
  background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-white.svg") no-repeat 50%/contain;
}

@media (max-width: 767.98px) {
  .footer-main__nav-col:first-child ul li:first-child a:after {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .footer-main__nav-col:first-child ul li a {
    width: 100%;
    display: block;
  }
}

@media (max-width: 991.98px) {
  .footer-main__nav-col:nth-child(3) {
    padding-right: 0;
  }
}

@media (max-width: 1500px) {
  .footer-main__nav-col:nth-child(3):after {
    display: none;
  }
}

@media (max-width: 1500px) {
  .footer-main__nav-col:last-child {
    display: none;
  }
}

.footer-main__nav-col:after {
  content: "";
  display: block;
  width: 0.0625rem;
  height: 13.875rem;
  background-color: #2D303E;
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 767.98px) {
  .footer-main__nav-col:after {
    display: none;
  }
}

.footer-main__nav-col ul li {
  margin-bottom: 1.375rem;
}

.footer-main__nav-col ul li:last-child {
  margin-bottom: 0;
}

.footer-main__nav-col ul li a {
  font-size: 1.0625rem;
  line-height: 1.25rem;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #FFFFFF;
}

.footer-main__nav-col.show-mob-nav {
  display: block;
  margin-bottom: 2.5rem;
}

.footer-main__nav-col.show-mob-nav li {
  display: block !important;
}

.footer-main__nav-col.show-mob-nav li:first-child a:after {
  transform: rotate(-180deg);
}

.footer-main__map-wrap {
  position: relative;
}

@media (max-width: 767.98px) {
  .footer-main__map-wrap {
    margin: 0 -1.25rem;
  }
}

.footer-main__map-wrap .map-inside {
  position: absolute;
  left: 2.5rem;
  bottom: 2.5rem;
  z-index: 5;
}

@media (max-width: 767.98px) {
  .footer-main__map-wrap .map-inside {
    position: relative;
    left: auto;
    bottom: auto;
    max-width: 100%;
  }
}

.footer-main__map-wrap .socials {
  display: none;
  background: #2D303E;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
}

@media (max-width: 1500px) {
  .footer-main__map-wrap .socials {
    display: flex;
    margin-left: 0;
    padding: 0.5rem 0.9375rem;
  }
}

@media (max-width: 991.98px) {
  .footer-main__map-wrap .socials {
    display: none;
  }
}

.footer-main__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 0.625rem;
  padding: 2.1875rem 0;
}

@media (max-width: 767.98px) {
  .footer-main__bottom {
    flex-flow: column;
    padding: 1.25rem 0;
  }
}

.footer-main__bottom p {
  color: #9D9FA5;
}

@media (max-width: 1500px) {
  .footer-main__bottom p {
    font-size: 0.9375rem;
    line-height: 1.4375rem;
  }
}

@media (max-width: 767.98px) {
  .footer-main__bottom p {
    margin-bottom: 1.125rem;
  }
}

.footer-main__bottom p a {
  color: #9D9FA5;
  text-decoration: underline;
}

.map {
  height: 24.6875rem;
  width: 100%;
  position: relative;
  background: url("/design/themes/komtrans_it/media/assets/images/map.jpg") no-repeat 50%/cover;
}

.map-inside {
  max-width: 23.4375rem;
  width: 100%;
  background-color: #fff;
  padding: 1.875rem 2.5rem;
  box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .map-inside {
    padding: 1.875rem 1.25rem;
  }
}

.map-inside h4 {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #000000;
  display: inline-block;
  position: relative;
  padding-right: 2.25rem;
  margin-bottom: 0.625rem;
}

.map-inside h4:after {
  content: "";
  display: block;
  width: 1.0625rem;
  height: 0.5625rem;
  position: absolute;
  top: 0.8125rem;
  right: 0;
  background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-dark.svg") no-repeat 50%/contain;
}

.map-inside ul li {
  font-weight: 500;
  font-size: 1rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  padding-left: 2.25rem;
  position: relative;
}

.map-inside ul li i {
  position: absolute;
  top: 0.5rem;
  left: 0;
}

.map-inside ul li a {
  color: #000;
  text-decoration: none;
}

.map-inside ul li a:hover {
  text-decoration: underline;
}

.map-inside hr {
  margin: 1.125rem 0;
  border: none;
  border-top: 0.0625rem solid #E8E8E8;
}

.map-inside__drop-down {
  position: relative;
}

.map-inside__drop-down h4 {
  cursor: pointer;
}

.map-inside__drop-down ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-width: 12.5rem;
  max-height: 11.5625rem;
  overflow: auto;
  background-color: #fff;
  z-index: 2;
}

.map-inside__drop-down ul li {
  padding-left: 0;
}

.map-inside.open .map-inside__drop-down ul {
  display: block;
}

.map-inside.open .map-inside__drop-down h4:after {
  transform: rotate(-180deg);
}

.tel-link {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #000000;
  padding-left: 2.375rem;
  position: relative;
  text-decoration: none;
}

.tel-link:before {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/tel-red.svg") no-repeat 50%/contain;
}

.tel-link:hover {
  text-decoration: underline;
}

.hero-block {
  height: 70rem;
}

@media (max-width: 1500px) {
  .hero-block {
    height: 40rem;
  }
}

@media (max-width: 767.98px) {
  .hero-block {
    height: 25.625rem;
  }
}

.hero-block > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.hero-block__blackout {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.hero-block__content-cover {
  padding-left: 3.125rem;
}

@media (max-width: 1500px) {
  .hero-block__content-cover {
    padding-left: 0;
  }
}

.hero-block__content {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding-bottom: 13.4375rem;
  padding-top: 10.3125rem;
}

@media (max-width: 1500px) {
  .hero-block__content {
    padding-bottom: 0;
    padding-top: 8.125rem;
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  .hero-block__content {
    padding-top: 0;
    padding-bottom: 3.75rem;
    justify-content: flex-end;
  }
}

.hero-block__content h1 {
  position: relative;
}

.hero-block__content h1:before {
  content: "";
  width: 0.3125rem;
  height: 2.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-1.25rem);
  left: -3.125rem;
  background: #D53A42;
  box-shadow: 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
}

.hero-block__content p {
  color: #fff;
  max-width: 21.875rem;
}

.hero-block__content .btn {
  margin-top: 2.8125rem;
}

@media (max-width: 767.98px) {
  .hero-block__content .btn {
    margin-top: 1.875rem;
  }
}

.hero-block .container {
  z-index: 2;
  color: #fff;
}

.hero-block .section-title-outside {
  left: auto;
  right: 0rem;
  z-index: 1;
  bottom: 9.375rem;
  top: auto;
  left: auto;
}

@media (max-width: 1500px) {
  .hero-block .section-title-outside {
    display: none;
  }
}

.hero-block .section-title-outside__cover {
  width: auto;
}

.hero-block .title-num {
  margin-right: 0;
}

.hero-block .title-num__line {
  background-color: #fff;
}

.hero-block .title-num__number {
  color: #fff;
}

.hero-block .title-num__number-small {
  color: #fff;
}

.advantages-block-wrap .advantages-block {
  margin-top: -4.375rem;
  width: calc(100vw - (100vw - 103.125rem) / 2);
}

@media (max-width: 1500px) {
  .advantages-block-wrap .advantages-block {
    margin-top: 0;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .advantages-block-wrap .advantages-block {
    margin: 0;
  }
}

.advantages-block-wrap .advantages-block__text-content {
  padding: 0 3.125rem;
}

@media (max-width: 991.98px) {
  .advantages-block-wrap .advantages-block__text-content {
    padding-top: 3.125rem;
  }
}

@media (max-width: 991.98px) {
  .advantages-block-wrap .advantages-block__text-content {
    padding: 1.875rem 1.25rem;
  }
}

.advantages-block-wrap .advantages-block .swiper-wrap {
  width: 100%;
}

@media (max-width: 1500px) {
  .advantages-block-wrap > .container {
    padding: 0;
  }
}

.swiper-hero-block .swiper-notification {
  position: relative;
  left: auto;
  top: auto;
}

.swiper-hero-block .swiper-pagination {
  bottom: 6.25rem;
}

@media (max-width: 1500px) {
  .swiper-hero-block .swiper-pagination {
    bottom: 2.5rem;
  }
}

@media (max-width: 767.98px) {
  .swiper-hero-block .swiper-pagination {
    bottom: 1.25rem;
  }
}

.advantages-block {
  padding-top: 3.125rem;
  position: relative;
  z-index: 2;
}

@media (max-width: 1500px) {
  .advantages-block {
    margin-top: 0;
  }
}

@media (max-width: 991.98px) {
  .advantages-block {
    padding-top: 0;
    margin: 0 -1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .advantages-block {
    margin: 0 -1.25rem;
  }
}

@media (max-width: 767.98px) {
  .advantages-block--product .section-title__back {
    display: none;
  }
}

@media (max-width: 1500px) {
  .advantages-block--product .swiper-wrap {
    margin-left: -3.125rem;
  }
}

.advantages-block:before {
  content: "";
  width: 100%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("/design/themes/komtrans_it/media/assets/images/advantages-block-bg.png") no-repeat 50%/cover, #000;
}

@media (max-width: 991.98px) {
  .advantages-block:before {
    display: none;
  }
}

.advantages-block__text-content {
  display: flex;
  max-width: 103.125rem;
  width: 100%;
}

@media (max-width: 991.98px) {
  .advantages-block__text-content {
    background: url("/design/themes/komtrans_it/media/assets/images/advantages-block-bg.png") no-repeat 50%/cover, #000;
    padding: 0 1.25rem;
  }
}

.advantages-block__text-content .row {
  width: 100%;
}

.advantages-block h3 {
  color: #fff;
  font-size: 2.375rem;
  line-height: 3.125rem;
  margin-bottom: 1.25rem;
}

@media (max-width: 767.98px) {
  .advantages-block h3 {
    font-size: 1.6875rem;
    line-height: 2.5rem;
  }
}

@media (max-width: 991.98px) {
  .advantages-block .section-title {
    margin-top: 1.25rem;
  }
}

@media (max-width: 991.98px) {
  .advantages-block .section-title h3 {
    margin-bottom: 0;
  }
}

.advantages-block .section-title__back {
  color: #FFFFFF;
  opacity: 0.06;
}

.advantages-block p {
  color: #fff;
  margin-bottom: 2.5rem;
}

@media (max-width: 767.98px) {
  .advantages-block p {
    margin-bottom: 0rem;
  }
}

.advantages-block__back-title {
  opacity: 0.05;
  color: #fff;
  font-size: 6.25rem;
  font-weight: bold;
  position: absolute;
  top: 10.9375rem;
  left: 0;
}

@media (max-width: 991.98px) {
  .advantages-block .swiper-wrap {
    margin: 0;
  }
}

@media (min-width: 992px) {
  .advantages-block .swiper-wrap .swiper-button-prev,
  .advantages-block .swiper-wrap .swiper-button-next {
    background: #1E1D1C;
  }
  .advantages-block .swiper-wrap .swiper-button-prev:hover,
  .advantages-block .swiper-wrap .swiper-button-next:hover {
    background: #D43A42;
  }
  .advantages-block .swiper-wrap .swiper-button-prev:after,
  .advantages-block .swiper-wrap .swiper-button-next:after {
    color: #FFFFFF;
  }
  .advantages-block .swiper-wrap .swiper-button-next:after {
    background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-next-white.svg");
  }
  .advantages-block .swiper-wrap .swiper-button-prev:after {
    background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-prev-white.svg");
  }
}

.advantage-card {
  width: 27.1875rem;
  padding: 3.125rem;
  background: #FFFFFF;
  position: relative;
  height: 100%;
}

@media (max-width: 767.98px) {
  .advantage-card {
    padding: 2.1875rem 1.25rem;
  }
}

@media (max-width: 575.98px) {
  .advantage-card {
    width: 100%;
  }
}

.advantage-card:after {
  content: "";
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  right: 0;
  top: 0;
  background: #EEEEEE;
}

@media (max-width: 575.98px) {
  .advantage-card:after {
    display: none;
  }
}

.advantage-card > i {
  display: flex;
  margin-bottom: 1.875rem;
  width: 4.125rem;
  height: 4.125rem;
  align-items: center;
  justify-content: center;
  background-color: #D43A42;
  border-radius: 50%;
}

.advantage-card h4 {
  font-size: 1.875rem;
  line-height: 2.6875rem;
  margin-bottom: 0.625rem;
}

.advantage-card p {
  color: #000000;
  margin-bottom: 0.3125rem;
  line-height: 1.8125rem;
}

.our-branches__cover {
  position: relative;
}

@media (max-width: 991.98px) {
  .our-branches__cover {
    display: flex;
    flex-wrap: wrap;
  }
}

.our-branches__cover .map-inside h4:after {
  content: none;
}

.our-branches .map-inside {
  margin-bottom: 1.25rem;
}

@media (max-width: 991.98px) {
  .our-branches .map-inside {
    margin-right: 1.25rem;
  }
}

.our-branches__map {
  position: absolute;
  top: -5rem;
  left: 28.75rem;
}

@media (max-width: 1500px) {
  .our-branches__map {
    right: auto;
    top: 0rem;
    left: 25rem;
  }
}

@media (max-width: 991.98px) {
  .our-branches__map {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 auto;
  }
}

.our-branches .ymaps-2-1-76-zoom {
  display: none;
}

.our-branches .ymaps-2-1-76-map-copyrights-promo {
  display: none;
}

.our-branches .ymaps-2-1-76-copyright {
  display: none;
}

.branches-map {
  width: 77.9375rem;
}

@media (max-width: 1500px) {
  .branches-map {
    width: 51.875rem;
  }
}

@media (max-width: 991.98px) {
  .branches-map {
    width: 100%;
  }
}

.branches-map img {
  width: 100%;
  height: auto;
}

.branches-map svg {
  width: 100%;
  height: auto;
}

.branches-map svg path.region {
  cursor: pointer;
}

.branches-map svg path.region:hover {
  fill: #D43A42;
}

.branches-map svg path.region:hover + path.marker {
  fill: #fff;
}

.branches-map svg path.marker {
  pointer-events: none;
}

.card {
  width: 24.375rem;
  background-color: #fff;
  padding-bottom: 1.25rem;
  position: relative;
}

@media (max-width: 575.98px) {
  .card {
    width: 100%;
  }
}

.card a {
  text-decoration: none;
}

.card__img-wrap {
  display: block;
  position: relative;
  height: 12.1875rem;
  margin-bottom: 1.25rem;
}

.card__img-wrap img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.card h5 {
  margin-bottom: 0.9375rem;
}

@media (max-width: 767.98px) {
  .card h5 {
    margin-bottom: 0.625rem;
  }
}

.card h5 a {
  display: inline-block;
}

.card table {
  width: 100%;
}

.card table td {
  font-size: 0.875rem;
  line-height: 1.8125rem;
  color: #000000;
}

@media (max-width: 575.98px) {
  .card table tr:nth-child(3) td {
    display: none;
  }
}

.card__body {
  padding: 0 1.875rem;
  margin-bottom: 1.25rem;
}

@media (max-width: 767.98px) {
  .card__body {
    padding: 0 1.25rem;
    margin-bottom: 1.5625rem;
  }
}

.card__link {
  text-decoration: none;
}

.card__link:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.card__footer {
  padding: 0rem 1.875rem;
  margin-bottom: 1.25rem;
}

@media (max-width: 767.98px) {
  .card__footer {
    padding: 0 1.25rem;
  }
}

@media (max-width: 767.98px) {
  .card__footer {
    margin-bottom: 0.625rem;
  }
}

@media (max-width: 767.98px) {
  .card__footer .row > div {
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
  }
}

.card__price a {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.625rem;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
}

@media (max-width: 767.98px) {
  .card__price a {
    font-size: 0.9375rem;
  }
}

.card__price-value {
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.625rem;
  color: #000000;
  margin-bottom: 0.3125rem;
}

@media (max-width: 767.98px) {
  .card__price-value {
    margin-top: 1.375rem;
  }
}

.card .btn {
  display: flex;
  width: 100%;
  padding: 0;
  height: 3.375rem;
  position: relative;
  z-index: 5;
}

.card__time {
  color: #9E9E9E;
}

.catalog-equipment {
  background: #2D303E;
  position: relative;
  overflow: hidden;
  padding: 1.5625rem 0;
}

@media (max-width: 767.98px) {
  .catalog-equipment {
    padding: 0;
  }
}

.catalog-equipment .container {
  position: relative;
  z-index: 3;
}

.catalog-equipment .section-title-outside h2 {
  opacity: 0.1;
}

.catalog-equipment .section-title-outside .title-num__number {
  color: #fff;
}

.catalog-equipment .section-title-outside .title-num__number-small {
  color: #fff;
}

.catalog-equipment .section-title-outside .title-num__line {
  background-color: #fff;
}

@media (max-width: 767.98px) {
  .catalog-equipment .section-title {
    margin-bottom: 0.625rem;
  }
}

.catalog-equipment .section-title h3 {
  color: #FFFFFF;
}

.catalog-equipment .section-title__back {
  color: #FFFFFF;
  opacity: 0.06;
}

@media (max-width: 575.98px) {
  .catalog-equipment .row {
    margin: 0 -2.1875rem;
  }
}

.catalog-equipment .row > div {
  margin-bottom: 2.5rem;
}

@media (max-width: 575.98px) {
  .catalog-equipment .row > div {
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 1500px) and (min-width: 992px) {
  .catalog-equipment .row > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 575.98px) {
  .catalog-equipment .row > div:last-child {
    margin-bottom: 0;
  }
}

.catalog-equipment .circle-main {
  border-width: 7.5rem;
  border-color: #3B3F4E;
}

.catalog-equipment .circle-main.circle-1 {
  width: 82.0625rem;
  height: 78.0625rem;
  bottom: -36.25rem;
  right: -21.125rem;
}

@media (max-width: 1500px) {
  .catalog-equipment .circle-main.circle-1 {
    top: 50%;
    bottom: auto;
    transform: translate(50%, -50%);
    right: 0;
  }
}

.catalog-equipment .circle-main.circle-2 {
  width: 57.5625rem;
  height: 54.75rem;
  top: 4.375rem;
  left: 4.375rem;
}

.catalog-card {
  background: #FFFFFF;
  box-shadow: 0rem 0rem 2.125rem rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  position: relative;
}

.catalog-card:before {
  content: "";
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #EEEEEE;
}

.catalog-card__body {
  overflow: hidden;
  position: relative;
  padding: 2.5rem 2.5rem;
  padding-top: 2.3125rem;
}

@media (max-width: 575.98px) {
  .catalog-card__body {
    height: 13.4375rem;
    padding: 3.125rem 1.25rem;
  }
}

.catalog-card h5 {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5625rem;
  position: relative;
  z-index: 2;
  text-shadow: 0.125rem 0.125rem 0.125rem #fff, -0.125rem -0.125rem 0.125rem #fff;
}

@media (max-width: 575.98px) {
  .catalog-card h5 {
    width: 60%;
  }
}

.catalog-card img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.catalog-card__quantity {
  position: absolute;
  right: 1.25rem;
  bottom: -1.6875rem;
  background: #D43A42;
  box-shadow: 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.25rem;
  height: 3.375rem;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.625rem;
  text-transform: uppercase;
  color: #FFFFFF;
  z-index: 3;
}

@media (max-width: 575.98px) {
  .catalog-card__quantity {
    bottom: 0;
    right: 1.25rem;
    height: 3.125rem;
  }
}

.catalog-card .btn {
  height: 3.375rem;
  padding: 0 1.6875rem;
  position: relative;
  z-index: 2;
}

@media (max-width: 575.98px) {
  .catalog-card .btn {
    position: absolute;
    left: 1.25rem;
    bottom: 0;
    height: 3.125rem;
  }
}

.catalog-card .circle {
  border-width: 3.125rem;
  width: 19.875rem;
  height: 19.875rem;
  top: 50%;
  transform: translateY(-50%);
  left: -3.125rem;
}

.catalog-card-1 img {
  width: 23.875rem;
  right: -21%;
}

@media (max-width: 575.98px) {
  .catalog-card-1 img {
    right: -7.25rem;
  }
}

.catalog-card-2 img {
  width: 19rem;
  right: -12%;
}

@media (max-width: 575.98px) {
  .catalog-card-2 img {
    right: -6.125rem;
  }
}

.catalog-card-3 img {
  width: 16.375rem;
  right: -6%;
}

@media (max-width: 575.98px) {
  .catalog-card-3 img {
    right: -2.3125rem;
  }
}

.catalog-card-4 img {
  width: 24.6875rem;
  right: -14%;
}

@media (max-width: 575.98px) {
  .catalog-card-4 img {
    right: -9.8125rem;
  }
}

.catalog-card-5 img {
  width: 16.25rem;
  right: -8%;
}

@media (max-width: 575.98px) {
  .catalog-card-5 img {
    right: -4.1875rem;
  }
}

.catalog-card-6 img {
  width: 23.4375rem;
  right: -26%;
}

@media (max-width: 575.98px) {
  .catalog-card-6 img {
    right: -11.6875rem;
  }
}

.catalog-card-7 img {
  width: 20.6875rem;
  right: -8%;
  top: 46%;
}

@media (max-width: 575.98px) {
  .catalog-card-7 img {
    right: -7.5625rem;
  }
}

.catalog-card-8 img {
  width: 17.6875rem;
  right: -6%;
}

@media (max-width: 575.98px) {
  .catalog-card-8 img {
    right: -4.3125rem;
  }
}

.catalog-card-9 img {
  width: 17.75rem;
  right: -7%;
}

@media (max-width: 575.98px) {
  .catalog-card-9 img {
    right: -7.1875rem;
  }
}

.catalog-card-10 img {
  width: 22.875rem;
  right: -7%;
}

@media (max-width: 575.98px) {
  .catalog-card-10 img {
    right: -4.6875rem;
  }
}

.catalog-card-11 img {
  width: 19.1875rem;
  right: -7%;
}

@media (max-width: 575.98px) {
  .catalog-card-11 img {
    right: -7.75rem;
  }
}

.catalog-card-12 img {
  width: 18.5rem;
  right: -7%;
}

@media (max-width: 575.98px) {
  .catalog-card-12 img {
    right: -6.8125rem;
  }
}

.catalog-card-13 img {
  width: 15.75rem;
  right: -7%;
}

@media (max-width: 575.98px) {
  .catalog-card-13 img {
    right: -4.375rem;
  }
}

.issue-leasing__next-step {
  width: 5.125rem;
  height: 0.8125rem;
  border-top: 1px solid #C0C0C0;
  border-bottom: 1px solid #C0C0C0;
  margin: 0 auto;
  margin-top: 3.125rem;
}

.issue-leasing .row {
  justify-content: space-between;
}

@media (max-width: 1500px) {
  .issue-leasing .row {
    padding-right: 3.125rem;
  }
}

@media (max-width: 991.98px) {
  .issue-leasing .row {
    flex-wrap: wrap;
    padding-right: 0;
    justify-content: flex-start;
  }
}

.issue-leasing .row > div {
  margin-bottom: 3.125rem;
  width: 7.5rem;
  flex-grow: 0;
  flex-basis: auto;
  display: flex;
}

@media (max-width: 991.98px) {
  .issue-leasing .row > div {
    width: 33.333%;
  }
}

@media (max-width: 767.98px) {
  .issue-leasing .row > div {
    width: 100%;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 991.98px) {
  .issue-leasing .row > div:nth-child(2n) {
    display: none;
  }
}

.leasing-card {
  width: 7.5rem;
}

@media (max-width: 991.98px) {
  .leasing-card {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .leasing-card {
    display: flex;
    align-items: center;
  }
}

.leasing-card i {
  display: block;
  width: 7.5rem;
  margin-bottom: 1.75rem;
  flex-shrink: 0;
}

@media (max-width: 767.98px) {
  .leasing-card i {
    width: 5.5625rem;
    margin-bottom: 0;
    margin-right: 1.5625rem;
  }
}

.leasing-card i img {
  width: 100%;
}

.leasing-card p {
  font-size: 1.25rem;
  line-height: 2rem;
  color: #000000;
  font-weight: bold;
  margin-right: -1.25rem;
}

@media (max-width: 991.98px) {
  .leasing-card p {
    margin-right: 0;
  }
}

@media (max-width: 767.98px) {
  .leasing-card p br {
    display: none;
  }
}

.leasing-card.checked i {
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
}

.circle {
  display: block;
  border-style: solid;
  border-color: rgba(225, 227, 236, 0.35);
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
}

.company-benefits {
  position: relative;
  display: flex;
  flex-flow: column;
}

.company-benefits__back {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 991.98px) {
  .company-benefits__back {
    display: none;
  }
}

.company-benefits__front {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .company-benefits__front {
    margin: 0 -1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .company-benefits__front {
    margin: 0 -1.25rem;
    overflow: visible;
  }
}

.company-benefits__front .company-benefits__row {
  height: auto;
}

.company-benefits__front .company-benefits__content {
  box-shadow: none;
  min-height: 22.8125rem;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  padding: 3.125rem;
  background: none;
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .company-benefits__front .company-benefits__content {
    background: #2D303E;
    box-shadow: 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
    padding: 2.5rem;
    align-items: flex-start;
    min-height: 0;
  }
}

@media (max-width: 767.98px) {
  .company-benefits__front .company-benefits__content {
    padding: 1.875rem 1.25rem;
  }
}

.company-benefits__front .company-benefits__content:before {
  display: none;
}

.company-benefits__front .company-benefits__media img {
  opacity: 0;
}

@media (max-width: 991.98px) {
  .company-benefits__front .company-benefits__media img {
    opacity: 1;
  }
}

.company-benefits__row {
  display: flex;
  height: 100%;
}

@media (max-width: 991.98px) {
  .company-benefits__row {
    flex-flow: column;
  }
}

.company-benefits__media {
  width: 51.1%;
  position: relative;
  overflow: hidden;
  height: 100%;
}

@media (max-width: 991.98px) {
  .company-benefits__media {
    width: 100%;
    height: 50%;
  }
}

@media (max-width: 767.98px) {
  .company-benefits__media {
    height: 12.5rem;
  }
}

.company-benefits__media img {
  max-width: none;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: 13% 0;
  font-family: 'object-fit: cover; object-position: 13% 0;';
}

@media (max-width: 991.98px) {
  .company-benefits__media img {
    position: relative;
  }
}

.company-benefits__content {
  height: 100%;
  width: 48.9%;
  background: #2D303E;
  box-shadow: 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
  position: relative;
  color: #FFFFFF;
}

@media (max-width: 991.98px) {
  .company-benefits__content {
    width: 100%;
    height: auto;
  }
}

.company-benefits__content:before {
  content: "";
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  left: 0;
  top: 0;
  background: #505466;
}

@media (max-width: 767.98px) {
  .company-benefits__content:before {
    display: none;
  }
}

.company-benefits__content h3 {
  font-weight: bold;
  font-size: 2.125rem;
  line-height: 3.9375rem;
  color: #FFFFFF;
  margin-bottom: 2.1875rem;
}

@media (max-width: 767.98px) {
  .company-benefits__content h3 {
    font-size: 1.625rem;
    line-height: 2.625rem;
    margin-bottom: 1.5625rem;
  }
}

.company-benefits__content p {
  color: #FFFFFF;
  margin-bottom: 0.625rem;
}

.company-benefits__content-cover {
  max-width: 34.375rem;
  width: 100%;
}

.company-benefits .circle {
  border-width: 7.5rem;
  border-color: #3B3F4E;
}

@media (max-width: 768px) {
  .company-benefits .circle {
    border-width: 5rem;
  }
}

.company-benefits .circle-1 {
  width: 84.875rem;
  height: 78.125rem;
  top: 18.75rem;
  left: 22.5rem;
}

@media (max-width: 1500px) {
  .company-benefits .circle-1 {
    left: 3.75rem;
  }
}

@media (max-width: 767.98px) {
  .company-benefits .circle-1 {
    width: 52.8125rem;
    height: 49.125rem;
    top: 1.875rem;
  }
}

.company-benefits .circle-2 {
  width: 59.5rem;
  height: 54.6875rem;
  top: 5rem;
  left: 5rem;
}

@media (max-width: 767.98px) {
  .company-benefits .circle-2 {
    width: 37.4375rem;
    height: 34.4375rem;
    top: 2.5rem;
    left: 2.5rem;
  }
}

.company-benefits.reverse .company-benefits__row {
  flex-flow: row-reverse;
}

@media (max-width: 991.98px) {
  .company-benefits.reverse .company-benefits__row {
    flex-flow: column;
  }
}

.company-info h3 {
  font-size: 2.375rem;
  line-height: 3.9375rem;
  color: #FFFFFF;
  margin-bottom: 3.4375rem;
  position: relative;
  z-index: 3;
}

@media (max-width: 1500px) {
  .company-info h3 br {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .company-info h3 {
    font-size: 1.6875rem;
    line-height: 2.5rem;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 991.98px) {
  .company-info .company-benefits__row {
    flex-flow: column-reverse;
  }
}

.company-info .company-benefits__content {
  padding: 4.375rem 5.3125rem;
  padding-bottom: 0;
}

@media (max-width: 1500px) {
  .company-info .company-benefits__content {
    padding: 4.375rem 0 0 3.125rem;
  }
}

@media (max-width: 991.98px) {
  .company-info .company-benefits__content {
    padding-right: 3.125rem;
    padding-left: 3.125rem;
    overflow: hidden;
  }
}

@media (max-width: 767.98px) {
  .company-info .company-benefits__content {
    padding-top: 1.875rem;
    padding-bottom: 21.875rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media (max-width: 767.98px) {
  .company-info .company-benefits__media {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .company-info__benefits {
    margin-top: -11.25rem;
  }
}

@media (max-width: 767.98px) {
  .company-info__benefits {
    margin-top: -20rem;
  }
}

.company-info__benefits .row > div {
  margin-bottom: 3.75rem;
}

@media (max-width: 1500px) and (min-width: 992px) {
  .company-info__benefits .row > div {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 991.98px) {
  .company-info__benefits .row > div {
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 767.98px) {
  .company-info__benefits .row > div {
    margin-bottom: 1.25rem;
  }
  .company-info__benefits .row > div:last-child {
    margin-bottom: 0;
  }
}

.benefit-card {
  background: #FFFFFF;
  box-shadow: 0rem 0rem 2.125rem rgba(0, 0, 0, 0.1);
  padding: 2.5rem 3.125rem;
  position: relative;
  height: 100%;
}

@media (max-width: 768px) {
  .benefit-card {
    padding: 2.5rem 1.25rem;
  }
}

.benefit-card:before {
  content: "";
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #D43A42;
}

.benefit-card h5 {
  font-weight: bold;
  font-size: 1.75rem;
  line-height: 2.1875rem;
  color: #000000;
  margin-bottom: 1.25rem;
}

@media (max-width: 768px) {
  .benefit-card h5 {
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
  }
}

.benefit-card p {
  margin-bottom: 0.625rem;
  color: #000;
}

.logo-card {
  padding: 0.625rem 1.875rem;
}

.logo-card img {
  margin: 0 auto;
  height: 5.625rem;
}

@media (max-width: 767.98px) {
  .helpful-information .mob-swiper-button-center .swiper-button-prev,
  .helpful-information .mob-swiper-button-center .swiper-button-next {
    top: 7.625rem;
  }
}

.helpful-information .section-title-outside {
  top: 20rem;
}

.ribbon {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 6.25rem;
  height: 6.25rem;
}

.ribbon:before {
  content: "";
  border-bottom: 1.875rem solid #2D303E;
  border-left: 1.875rem solid transparent;
  border-right: 1.875rem solid transparent;
  height: 0;
  width: 8.8125rem;
  position: absolute;
  top: 1.375rem;
  left: -1.875rem;
  transform: rotate(-45deg);
}

.ribbon span {
  position: absolute;
  z-index: 2;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.8125rem;
  color: #FFFFFF;
  transform: rotate(-45deg);
  display: block;
  top: 1.5rem;
  left: 0.1875rem;
}

.payment-delivery-block {
  padding-bottom: 2.1875rem;
}

@media (max-width: 767.98px) {
  .payment-delivery-block {
    padding-bottom: 0;
  }
}

.payment-delivery-block .row > div {
  margin-bottom: 2.1875rem;
}

@media (max-width: 575.98px) {
  .payment-delivery-block .row > div:last-child {
    margin-bottom: 0;
  }
}

.payment-delivery {
  padding: 3.4375rem 2.5rem 4.0625rem 4.0625rem;
  box-shadow: 0rem 0rem 2.125rem rgba(0, 0, 0, 0.1);
  position: relative;
  background-color: #fff;
  height: 100%;
}

@media (max-width: 1500px) {
  .payment-delivery {
    padding: 2.5rem 2.5rem 3.75rem 3.125rem;
  }
}

@media (max-width: 767.98px) {
  .payment-delivery {
    padding: 1.5625rem 1.25rem 2.5rem;
  }
}

@media (max-width: 575.98px) {
  .payment-delivery {
    margin: 0 -1.25rem;
  }
}

.payment-delivery:after {
  content: "";
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  right: 0;
  top: 0;
  background: #EEEEEE;
}

.payment-delivery h4 {
  margin-bottom: 2.5rem;
}

@media (max-width: 767.98px) {
  .payment-delivery h4 {
    margin-bottom: 1.875rem;
  }
}

.payment-delivery__cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.875rem;
}

@media (max-width: 767.98px) {
  .payment-delivery__cover {
    margin-bottom: 1.25rem;
  }
}

.payment-delivery__method {
  font-size: 1.125rem;
  line-height: 2.1875rem;
  color: #000000;
  position: relative;
  padding-left: 4.375rem;
  padding-right: 1.25rem;
  margin-bottom: 1.5625rem;
}

@media (max-width: 1500px) {
  .payment-delivery__method {
    padding-right: 2.5rem;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .payment-delivery__method {
    font-size: 1rem;
    line-height: 2.1875rem;
    padding-left: 3.4375rem;
    margin-bottom: 1rem;
  }
}

.payment-delivery__method:before {
  content: "";
  display: block;
  width: 2.875rem;
  height: 2.875rem;
  border-radius: 50%;
  background: url("/design/themes/komtrans_it/media/assets/icons/check-white.svg") no-repeat 50%/1.0625rem, #D43A42;
  position: absolute;
  top: -0.3125rem;
  left: 0;
}

@media (max-width: 767.98px) {
  .payment-delivery__method:before {
    width: 2.5rem;
    height: 2.5rem;
    top: 0rem;
  }
}

/*-------------- filial --------------*/
.filial-hero {
  margin-bottom: 3.75rem;
}

@media (max-width: 1500px) {
  .filial-hero {
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 991.98px) {
  .filial-hero {
    padding-top: 3.4375rem;
  }
}

@media (max-width: 767.98px) {
  .filial-hero {
    padding-top: 1.25rem;
  }
}

.filial-hero .section-title__back {
  bottom: 1%;
}

@media (max-width: 767.98px) {
  .filial-hero .section-title__back {
    line-height: 0.57971;
  }
}

.filial-hero__cover {
  position: relative;
}

.filial-hero__cover .map-inside {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  .filial-hero__cover .map-inside {
    position: static;
    width: auto;
    max-width: none;
    transform: none;
    margin: 0 -1.25rem;
  }
}

.filial-hero__cover .map-inside h4::after {
  content: none;
}

.filial-hero__image {
  width: calc(100% - 12.1875rem);
  height: 28.125rem;
  margin-left: auto;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1500px) {
  .filial-hero__image {
    width: calc(100% - 6.25rem);
  }
}

@media (max-width: 991.98px) {
  .filial-hero__image {
    width: auto;
    margin: 0 -1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .filial-hero__image {
    height: 11.875rem;
    margin: 0 -1.25rem;
  }
}

.filial-hero__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
}

.filial-stat {
  margin-bottom: 3.75rem;
}

@media (max-width: 1500px) {
  .filial-stat {
    margin-bottom: 3.125rem;
  }
}

@media (max-width: 991.98px) {
  .filial-stat {
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 767.98px) {
  .filial-stat {
    margin-bottom: 0;
  }
}

.filial-stat__list {
  margin-left: -1.875rem;
  display: flex;
  align-items: stretch;
}

@media (max-width: 991.98px) {
  .filial-stat__list {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.filial-stat__item {
  width: calc(100%/7);
  padding-left: 1.875rem;
}

@media (max-width: 1500px) {
  .filial-stat__item {
    display: none;
  }
}

@media (max-width: 1500px) {
  .filial-stat__item:nth-child(1), .filial-stat__item:nth-child(2), .filial-stat__item:nth-child(3), .filial-stat__item:nth-child(4), .filial-stat__item:nth-child(5) {
    width: calc(100%/5);
    display: block;
  }
}

@media (max-width: 991.98px) {
  .filial-stat__item:nth-child(1), .filial-stat__item:nth-child(2), .filial-stat__item:nth-child(3), .filial-stat__item:nth-child(4), .filial-stat__item:nth-child(5) {
    width: 50%;
    margin-bottom: 1.875rem;
  }
}

@media (max-width: 767.98px) {
  .filial-stat__item:nth-child(1), .filial-stat__item:nth-child(2), .filial-stat__item:nth-child(3), .filial-stat__item:nth-child(4), .filial-stat__item:nth-child(5) {
    width: 100%;
    max-width: 30rem;
  }
}

.filial-stat__item-inner {
  height: 9.0625rem;
  background: #FFFFFF;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 2.1875rem 1.25rem 0;
  transition: background 0.3s;
}

.filial-stat__item-inner:hover {
  background: #2D303E;
}

.filial-stat__item-inner:hover .filial-stat__item-title {
  color: #FFFFFF;
}

.filial-stat__item-inner:hover .filial-stat__item-title > div {
  color: #FFFFFF;
}

.filial-stat__item-title {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 0.875;
  color: #D43A42;
  transition: color 0.3s;
}

@media (max-width: 991.98px) {
  .filial-stat__item-title {
    font-size: 2.1875rem;
  }
}

.filial-stat__item-title > div {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #000000;
  transition: color 0.3s;
  margin-top: 1.5625rem;
}

@media (max-width: 991.98px) {
  .filial-stat__item-title > div {
    margin-top: 0.9375rem;
  }
}

.filial-galery {
  margin-bottom: 0.625rem;
}

@media (max-width: 1500px) {
  .filial-galery {
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 767.98px) {
  .filial-galery {
    display: none;
  }
}

.filial-galery__list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -1.875rem;
}

.filial-galery__item {
  width: 50%;
  padding-left: 1.875rem;
  margin-bottom: 1.875rem;
}

.filial-galery__item-inner {
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
  height: 23.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2D303E;
  position: relative;
}

@media (max-width: 1500px) {
  .filial-galery__item-inner {
    height: 22.1875rem;
  }
}

@media (max-width: 991.98px) {
  .filial-galery__item-inner {
    height: 18.75rem;
  }
}

.filial-galery__item-inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: #505466;
}

.filial-galery__item-image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.filial-galery__item-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
}

.filial-galery__item-text {
  font-weight: 300;
  font-size: 1rem;
  line-height: 2;
  color: #FFFFFF;
  width: 100%;
  max-width: 38.75rem;
  padding: 0 1.25rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.filial-slider {
  padding: 3.75rem 0;
  background: #2D303E;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
  margin-bottom: 2.5rem;
}

@media (max-width: 991.98px) {
  .filial-slider {
    padding: 0;
    background: none;
    box-shadow: none;
    margin-bottom: 1.875rem;
  }
}

@media (max-width: 767.98px) {
  .filial-slider {
    margin-bottom: 0;
  }
}

.filial-slider__inner {
  width: 100%;
  position: relative;
}

.filial-slider__inner .benefit-card {
  width: 100%;
  height: auto;
  max-width: 31.25rem;
  padding: 3.125rem 5.9375rem 3.125rem 3.75rem;
  position: absolute;
  bottom: 3.125rem;
  left: 3.125rem;
  z-index: 1;
}

@media (max-width: 991.98px) {
  .filial-slider__inner .benefit-card {
    padding: 1.875rem 0 0 0;
    position: static;
    max-width: 37.5rem;
    box-shadow: none;
  }
}

.filial-slider__inner .benefit-card::before {
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  background: #EEEEEE;
}

@media (max-width: 991.98px) {
  .filial-slider__inner .benefit-card::before {
    content: none;
  }
}

.filial-slider__inner .benefit-card h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 2.125rem;
  line-height: 1.58824;
  margin-bottom: 2.5rem;
}

@media (max-width: 991.98px) {
  .filial-slider__inner .benefit-card h5 {
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 767.98px) {
  .filial-slider__inner .benefit-card h5 {
    font-size: 1.5rem;
  }
}

.filial-slider__inner .benefit-card p {
  font-weight: normal;
  font-size: 1rem;
  line-height: 2;
}

@media (max-width: 767.98px) {
  .filial-slider__inner .benefit-card p {
    font-size: 0.9375rem;
  }
}

.photo-slider {
  width: 100%;
}

.photo-slider__outer {
  width: 100%;
  margin: 0;
}

@media (max-width: 991.98px) {
  .photo-slider__outer {
    background: #2D303E;
    padding: 1.25rem 1.5625rem;
    margin: 0 -1.5625rem;
    width: auto;
    box-shadow: none;
  }
}

@media (max-width: 767.98px) {
  .photo-slider__outer {
    padding: 1.25rem;
    margin: 0 -1.25rem;
  }
}

.photo-slider__outer .swiper-button-wrap {
  top: 1.875rem;
  right: 0;
  padding: 0 1.875rem;
}

@media (max-width: 767.98px) {
  .photo-slider__outer .swiper-button-wrap {
    right: auto;
    left: 0;
  }
}

.photo-slider .swiper-slide {
  margin-right: 0;
}

.photo-slider__item-inner {
  width: 100%;
  height: 36.875rem;
  position: relative;
}

@media (max-width: 991.98px) {
  .photo-slider__item-inner {
    height: 24.375rem;
  }
}

@media (max-width: 767.98px) {
  .photo-slider__item-inner {
    height: 15.3125rem;
  }
}

.photo-slider__item-inner > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
  display: block;
}

.employees {
  padding-bottom: 1.875rem;
  overflow: hidden;
}

@media (max-width: 991.98px) {
  .employees {
    padding-bottom: 2.5rem;
  }
}

.employees .section-title-outside__cover {
  left: -5rem;
}

.employees-list {
  overflow: visible;
}

@media (max-width: 991.98px) {
  .employees-list {
    overflow: visible;
  }
}

.employees-list ul {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1.875rem;
  width: auto;
}

@media (max-width: 991.98px) {
  .employees-list ul {
    width: 100%;
    flex-wrap: nowrap;
    margin-right: 0;
  }
}

.employees-list ul li {
  width: 25%;
  width: calc(25% - 1.875rem);
  margin-bottom: 1.875rem;
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .employees-list ul li {
    padding-left: 0;
    margin-bottom: 0;
    width: 24.375rem;
  }
}

@media (max-width: 575.98px) {
  .employees-list ul li {
    width: 100%;
  }
}

.employees-card {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.employees-card__image {
  position: relative;
  width: 100%;
  padding-top: 71%;
}

@media (max-width: 767.98px) {
  .employees-card__image {
    padding-top: 0;
    height: 12.5rem;
  }
}

.employees-card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
}

.employees-card__cont {
  flex-grow: 1;
  padding: 2.1875rem 2.8125rem 3.125rem 2.5rem;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);
}

@media (max-width: 1500px) {
  .employees-card__cont {
    padding: 1.5rem 1.875rem 2.125rem;
  }
}

.employees-card__cont h5 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.6;
  color: #000000;
  margin-bottom: 0.9375rem;
}

.employees-card__cont p {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625;
  color: #9E9E9E;
}

@media (max-width: 767.98px) {
  .employees-card__cont p {
    font-size: 0.9375rem;
  }
}

.product-hero {
  padding-bottom: 1.5625rem;
}

@media (max-width: 767.98px) {
  .product-hero {
    padding-bottom: 0;
  }
}

.product-hero__content-col {
  padding-left: 2.8125rem;
}

@media (max-width: 1500px) {
  .product-hero__content-col {
    padding-left: 2.1875rem;
  }
}

@media (max-width: 991.98px) {
  .product-hero__content-col {
    padding-left: 15px;
  }
}

.product-galery__item {
  background: #FFFFFF;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15.625rem;
  position: relative;
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .product-galery__item {
    width: 17.5rem;
  }
}

@media (max-width: 575.98px) {
  .product-galery__item {
    width: 100%;
  }
}

.product-galery__item:after {
  content: "";
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #EEEEEE;
}

@media (max-width: 767.98px) {
  .product-galery__item:after {
    display: none;
  }
}

.product-galery__item.main {
  height: 28.125rem;
}

.product-galery__item.main:after {
  display: none;
}

.product-galery__item img {
  width: 100%;
  position: relative;
  z-index: 2;
}

.product-galery .row > div {
  margin-bottom: 1.875rem;
}

.product-galery .circle {
  border-width: 3.75rem;
}

@media (max-width: 767.98px) {
  .product-galery .circle {
    border-width: 2.75rem;
  }
}

.product-galery .circle-1 {
  width: 32.75rem;
  height: 32.75rem;
  top: 0;
  right: -18.125rem;
}

@media (max-width: 767.98px) {
  .product-galery .circle-1 {
    width: 21.9375rem;
    height: 21.9375rem;
    top: 4.375rem;
    right: auto;
    left: 6.5625rem;
  }
}

.product-galery .circle-2 {
  width: 19.875rem;
  height: 19.875rem;
  top: 3.125rem;
  left: 3.125rem;
}

@media (max-width: 767.98px) {
  .product-galery .circle-2 {
    width: 13.3125rem;
    height: 13.3125rem;
    top: 1.25rem;
    left: 1.25rem;
  }
}

@media (max-width: 575.98px) {
  .product-galery .swiper-wrap {
    margin: 0;
  }
}

.product-actions {
  margin-bottom: 1.5625rem;
}

.product-actions .row {
  justify-content: space-between;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  flex-wrap: nowrap;
}

@media (max-width: 1500px) {
  .product-actions .row {
    flex-wrap: wrap;
  }
}

.product-actions .row > div {
  padding: 0 0.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  width: auto;
  display: flex;
  align-items: center;
}

@media (max-width: 1500px) {
  .product-actions .row > div:first-child {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 1.5625rem;
  }
}

@media (max-width: 575.98px) {
  .product-actions .row > div:first-child {
    width: 50%;
  }
}

@media (max-width: 575.98px) {
  .product-actions .row > div:nth-child(2) {
    order: 3;
    flex-grow: 1;
    max-width: 50%;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .product-actions .row > div:nth-child(3) {
    order: 4;
    flex-grow: 1;
    max-width: 50%;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .product-actions .row > div:last-child {
    order: 2;
    margin-bottom: 1.5625rem;
  }
}

.product-actions .btn {
  height: 3.375rem;
  padding: 0 2.1875rem;
}

@media (max-width: 575.98px) {
  .product-actions .btn {
    width: 100%;
  }
}

.product-actions img {
  max-width: 8.25rem;
  margin-right: -0.625rem;
}

@media (max-width: 575.98px) {
  .product-actions img {
    max-width: 5.9375rem;
  }
}

.product-actions__price {
  font-weight: bold;
  font-size: 2.1875rem;
  line-height: 1.625rem;
  color: #000000;
  padding-right: 1.5625rem;
}

@media (max-width: 767.98px) {
  .product-actions__price {
    font-size: 1.625rem;
  }
}

.product-vailability {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.8125rem;
  margin-bottom: 0.75rem;
  flex-wrap: wrap;
}

.product-vailability h6 {
  font-weight: 600;
  color: #000000;
  padding-right: 1.5625rem;
  margin-bottom: 1.125rem;
}

@media (max-width: 767.98px) {
  .product-vailability h6 {
    width: 100%;
  }
}

.product-vailability ul {
  display: flex;
  flex-wrap: wrap;
}

.product-vailability ul li {
  color: #008473;
  padding-left: 1.5625rem;
  margin-right: 1.5625rem;
  position: relative;
  margin-bottom: 1.125rem;
}

.product-vailability ul li:before {
  content: "•";
  color: #008473;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.4375rem;
}

.product-vailability ul li:last-child {
  margin-right: 0;
}

.product-vailability ul li.not-available {
  color: #D3D3D3;
  padding-left: 0;
}

.product-vailability ul li.not-available:before {
  display: none;
}

.product-desc {
  padding: 1.5625rem 0;
}

.product-desc table {
  width: 100%;
}

.product-desc table td {
  font-size: 1rem;
  line-height: 1.125rem;
  height: 2.5rem;
  color: #000000;
  font-weight: 300;
  vertical-align: middle;
}

.product-desc table td:nth-child(1) {
  width: 47%;
}

@media (max-width: 1500px) {
  .product-desc table td:nth-child(1) {
    width: 51%;
  }
}

@media (max-width: 767.98px) {
  .product-desc table td:nth-child(1) {
    padding-right: 0.9375rem;
    width: auto;
  }
}

.product-desc table td:nth-child(2) {
  width: 23%;
}

@media (max-width: 1500px) {
  .product-desc table td:nth-child(2) {
    width: 14%;
  }
}

@media (max-width: 767.98px) {
  .product-desc table td:nth-child(2) {
    display: none;
  }
}

.product-desc table td:nth-child(3) {
  width: 30%;
}

@media (max-width: 1500px) {
  .product-desc table td:nth-child(3) {
    width: 35%;
  }
}

@media (max-width: 767.98px) {
  .product-desc table td:nth-child(3) {
    width: auto;
  }
}

.product-desc .line {
  display: block;
  width: 6.25rem;
  height: 0.0625rem;
  background-color: #D9DEF1;
}

@media (max-width: 1500px) {
  .product-desc .line {
    width: 2rem;
  }
}

.product-desc.basic {
  background: #F8F9FD;
  position: relative;
}

.product-desc.basic:before {
  content: "";
  display: block;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: -4.0625rem;
  background: #F8F9FD;
  z-index: -1;
}

@media (max-width: 991.98px) {
  .product-desc.basic:before {
    left: -1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .product-desc.basic:before {
    left: -1.25rem;
  }
}

.product-desc.basic table {
  position: relative;
}

.product-desc.basic table td {
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .product-desc.hide {
    display: none;
  }
}

.product-desc .all-specifications {
  margin-top: 1.875rem;
}

@media (max-width: 767.98px) {
  .product-desc.product-desc-hide {
    display: none;
  }
}

.product-desc.product-desc-hide.show {
  display: block;
}

.all-specifications {
  border: 1px solid #C4C7D7;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.625rem;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  height: 3.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: relative;
}

.all-specifications:after {
  content: "";
  display: block;
  width: 1.0625rem;
  height: 0.5625rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.25rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-dark.svg") no-repeat 50%/contain;
}

.all-specifications.open:after {
  transform: rotate(-180deg) translateY(50%);
}

.description-product .section-title {
  margin-top: 9.375rem;
}

@media (max-width: 1500px) {
  .description-product .section-title {
    margin-top: 4.375rem;
  }
}

@media (max-width: 767.98px) {
  .description-product .section-title {
    margin-top: 2.5rem;
  }
}

.description-product__row {
  display: flex;
}

@media (max-width: 991.98px) {
  .description-product__row {
    flex-flow: column;
  }
}

.description-product__col:first-child {
  width: 44%;
}

@media (max-width: 1500px) {
  .description-product__col:first-child {
    width: 31%;
  }
}

@media (max-width: 991.98px) {
  .description-product__col:first-child {
    width: 100%;
  }
}

.description-product__col:last-child {
  width: 56%;
}

@media (max-width: 1500px) {
  .description-product__col:last-child {
    width: 69%;
  }
}

@media (max-width: 991.98px) {
  .description-product__col:last-child {
    display: none;
    width: 100%;
  }
}

.description-product p {
  margin-bottom: 1.5625rem;
}

@media (max-width: 767.98px) {
  .description-product p {
    font-size: 1rem;
    line-height: 2rem;
  }
}

.description-product__cover-content {
  position: relative;
  z-index: 2;
  padding-bottom: 1.5625rem;
}

@media (max-width: 1500px) {
  .description-product__cover-content p:last-child {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .description-product__cover-content p:last-child {
    display: block;
  }
}

.description-product .product-interactive {
  width: 75rem;
  height: 56.25rem;
  margin-right: -15.625rem;
  margin-top: -9.375rem;
  margin-bottom: -1.875rem;
}

@media (max-width: 1500px) {
  .description-product .product-interactive {
    width: 64.625rem;
    height: 50rem;
    margin-right: 0;
    margin-bottom: -3.125rem;
    margin-top: -2.5rem;
    margin-left: -7.5rem;
  }
}

.description-product + div {
  position: relative;
  z-index: 2;
}

.product-interactive {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.interactive-1 .clause-1 {
  top: 50.78%;
  left: 12.83%;
}

@media (max-width: 1500px) {
  .interactive-1 .clause-1 {
    display: none;
  }
}

.interactive-1 .clause-2 {
  top: 38%;
  left: 37.5%;
}

.interactive-1 .clause-3 {
  top: 46.2%;
  left: 60.58%;
}

.interactive-1 .clause-4 {
  top: 23.2%;
  left: 69%;
}

.interactive-1 .clause-5 {
  top: 56.4%;
  left: 82.3%;
}

.clause {
  position: absolute;
  z-index: 5;
}

.clause__ico {
  display: block;
  width: 3.9375rem;
  height: 3.9375rem;
  border-radius: 50%;
  background-color: #2D303E;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s;
}

.clause__ico:hover {
  background-color: #D43A42;
}

.clause__ico:after {
  content: "";
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/clause-plus.svg") no-repeat 50%/contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clause__desc {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  width: 16.625rem;
  padding-bottom: 3.125rem;
  transform: translate(-100%, -100%);
  display: none;
}

.clause__desc:after {
  content: "";
  display: block;
  width: 16.625rem;
  height: 2.75rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/clausу-line.svg") no-repeat 50%/contain;
  position: absolute;
  bottom: 0;
  left: 0;
}

.clause__desc p {
  background-color: #fff;
}

.clause:hover .clause__desc {
  display: block;
}

.articles-preview .row > div {
  margin-bottom: 1.875rem;
}

@media (max-width: 1500px) {
  .articles-preview .row > div:last-child {
    display: none;
  }
}

@media (max-width: 1500px) and (min-width: 992px) {
  .articles-preview .row > div {
    flex: 0 0 33.3333%;
    max-width: 33.333%;
  }
}

@media (max-width: 767.98px) {
  .articles-preview .section-title {
    margin-top: 0.625rem;
  }
}

.article-card {
  display: flex;
  padding-right: 1.5625rem;
}

@media (max-width: 575.98px) {
  .article-card {
    padding-right: 0;
    flex-flow: column-reverse;
  }
}

.article-card__date {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal;
  display: block;
  text-align: center;
  color: #000000;
  margin-top: 0.3125rem;
}

@media (max-width: 575.98px) {
  .article-card__date {
    line-height: 1.8125rem;
    display: inline;
    text-align: left;
  }
}

.article-card__date span {
  text-align: center;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 2.1875rem;
  color: #000000;
}

@media (max-width: 575.98px) {
  .article-card__date span {
    font-size: 0.875rem;
    line-height: 1.8125rem;
    color: #000000;
    text-align: left;
    font-weight: normal;
  }
}

.article-card__main {
  padding-left: 1.5625rem;
}

@media (max-width: 575.98px) {
  .article-card__main {
    padding-left: 0;
  }
}

.article-card h5 {
  font-weight: 500;
  line-height: 2rem;
  color: #000000;
  margin-bottom: 1.25rem;
}

@media (max-width: 767.98px) {
  .article-card h5 {
    font-size: 1.125rem;
    line-height: 2rem;
  }
}

.article-card h5 a {
  display: inline-block;
  text-decoration: none;
}

.article-card p {
  font-size: 0.875rem;
  line-height: 1.8125rem;
  color: #616161;
}

/*-------------- about --------------*/
.about-hero {
  margin-bottom: 5rem;
}

@media (max-width: 991.98px) {
  .about-hero {
    padding-top: 3.4375rem;
    margin-bottom: 0;
  }
}

.about-hero .section-title-outside {
  top: 12.5rem;
}

.about-hero__image {
  position: relative;
  width: 100%;
  height: 39.6875rem;
}

@media (max-width: 1500px) {
  .about-hero__image {
    height: 29.375rem;
  }
}

@media (max-width: 991.98px) {
  .about-hero__image {
    height: 23.125rem;
  }
}

@media (max-width: 767.98px) {
  .about-hero__image {
    padding-top: 59.4%;
    height: auto;
  }
}

.about-hero__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;', serif;
}

@media (max-width: 991.98px) {
  .about-hero__cover {
    margin: 0 -1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .about-hero__cover {
    margin: 0 -1.25rem;
  }
}

.about-hero__info {
  width: calc(100% - 9.6875rem);
  background: #2d303e;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
  padding: 3rem 0 2.5rem 4rem;
  margin-top: -9.375rem;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1500px) {
  .about-hero__info {
    width: 100%;
    margin-top: -3.125rem;
  }
}

@media (max-width: 991.98px) {
  .about-hero__info {
    margin-top: -0.625rem;
    padding: 1.875rem 1.5625rem 2.5rem;
  }
}

@media (max-width: 767.98px) {
  .about-hero__info {
    padding: 1.875rem 1.25rem 2.5rem;
  }
}

.about-hero__info::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: #505466;
}

@media (max-width: 991.98px) {
  .about-hero__info::after {
    content: none;
  }
}

.about-hero__info::before {
  content: '';
  width: 75rem;
  height: 75rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/circle-bg.svg") no-repeat 50%/contain;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-60%, -13%);
}

@media (max-width: 767.98px) {
  .about-hero__info::before {
    width: 62.5rem;
    height: 62.5rem;
    left: auto;
    right: 0;
    transform: translate(-5%, -10%);
  }
}

.about-hero__info-inner {
  width: 100%;
  display: flex;
  align-items: stretch;
  position: relative;
}

@media (max-width: 991.98px) {
  .about-hero__info-inner {
    flex-direction: column;
  }
}

.about-hero__info-left {
  width: calc(50% + 9.6875rem);
  padding-right: 5%;
}

@media (max-width: 991.98px) {
  .about-hero__info-left {
    width: 100%;
    padding-right: 0;
    margin-bottom: 1.875rem;
  }
}

.about-hero__info-left h3 {
  font-weight: bold;
  font-size: 2.125rem;
  line-height: 1.23529;
  margin-bottom: 2.5rem;
  color: #ffffff;
}

@media (max-width: 767.98px) {
  .about-hero__info-left h3 {
    font-size: 1.5rem;
  }
}

.about-hero__info-left p {
  color: #ffffff;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.75;
}

@media (max-width: 767.98px) {
  .about-hero__info-left p {
    font-size: 0.9375rem;
  }
}

.about-hero__info-right {
  width: calc(50% - 9.6875rem);
  display: flex;
  align-items: center;
}

@media (max-width: 991.98px) {
  .about-hero__info-right {
    width: 100%;
  }
}

.check-list {
  width: 100%;
}

.check-list-icon {
  width: 2.8125rem;
  height: 2.8125rem;
  border-radius: 50%;
  background: #d43a42;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -1.40625rem;
}

@media (max-width: 767.98px) {
  .check-list-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.check-list-icon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 0.6875rem;
  margin-top: -0.34375rem;
  margin-left: -0.5rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/check-white.svg") no-repeat 50%/contain;
}

.check-list ul {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.check-list ul li {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.6875;
  text-transform: uppercase;
  color: #ffffff;
  padding-left: 4.375rem;
  margin-bottom: 1.25rem;
  min-height: 2.8125rem;
}

@media (max-width: 767.98px) {
  .check-list ul li {
    padding-left: 3.4375rem;
    font-size: 0.875rem;
  }
}

.advantages-about-block {
  margin-bottom: 2.5rem;
}

@media (max-width: 991.98px) {
  .advantages-about-block {
    margin: 0 -1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .advantages-about-block {
    margin: 0 -1.25rem;
  }
}

.advantages-about-block:before {
  height: 55%;
}

@media (max-width: 991.98px) {
  .advantages-about-block .advantages-block__text-content {
    padding: 0 1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .advantages-about-block .advantages-block__text-content {
    padding: 0 1.25rem;
  }
}

@media (max-width: 991.98px) {
  .advantages-about-block .advantages-block__text-content .section-title {
    padding: 1.5625rem 0 1.875rem;
    margin: 0;
  }
}

@media (max-width: 991.98px) {
  .advantages-about-block .advantages-block__text-content .section-title__back {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .advantages-about-block h3 {
    margin-bottom: 0;
  }
}

.advantages-about-block .swiper-button-wrap {
  display: none;
}

@media (max-width: 1500px) {
  .advantages-about-block .swiper-button-wrap {
    display: flex;
  }
}

.advantages-about-block .swiper-wrap {
  margin: 0;
  margin-right: -1.875rem;
  width: auto;
}

@media (max-width: 1500px) {
  .advantages-about-block .swiper-wrap {
    margin-right: 0;
  }
}

@media (max-width: 1500px) and (min-width: 992px) {
  .advantages-about-block .swiper-container {
    margin-right: -10%;
  }
}

@media (max-width: 767.98px) {
  .advantages-about-block .swiper-container {
    margin-right: 0;
  }
}

.advantages-about-block .swiper-slide {
  width: calc(33.333% - 1.875rem);
}

@media (max-width: 1500px) {
  .advantages-about-block .swiper-slide {
    width: auto;
  }
}

@media (max-width: 575.98px) {
  .advantages-about-block .swiper-slide {
    width: 100%;
  }
}

@media (max-width: 1500px) {
  .advantages-about-block .advantage-card {
    padding: 3.125rem 2.5rem;
  }
}

@media (min-width: 1500px) {
  .advantages-about-block .advantage-card {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .advantages-about-block .advantage-card {
    padding: 2.5rem 1.25rem;
  }
}

@media (max-width: 991.98px) {
  .advantages-about-block .advantage-card::after {
    content: none;
  }
}

@media (max-width: 1500px) {
  .advantages-about-block .advantage-card h4 {
    font-size: 1.6875rem;
    line-height: 1.37037;
  }
}

@media (max-width: 991.98px) {
  .advantages-about-block .advantage-card h4 {
    font-size: 1.5rem;
    line-height: 1.41667;
    margin-bottom: 1.25rem;
  }
}

@media (max-width: 991.98px) {
  .advantages-about-block .advantage-card p {
    font-size: 1rem;
  }
}

.advantages-about__actions {
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .advantages-about__actions {
    margin-top: 1.875rem;
    padding: 0 1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .advantages-about__actions {
    padding: 0 1.25rem;
  }
  .advantages-about__actions .btn {
    width: 100%;
    max-width: 17.5rem;
    padding: 0;
  }
}

.filials-list {
  margin-bottom: 1.875rem;
}

.filials-list__cover {
  box-shadow: none;
  margin: 0;
  width: 100%;
}

@media (max-width: 991.98px) {
  .filials-list__cover {
    width: auto;
    margin: 0 -1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .filials-list__cover {
    margin: 0 -1.25rem;
  }
}

.filials-list__cover .swiper-button-wrap {
  display: none;
}

@media (max-width: 991.98px) {
  .filials-list__cover .swiper-button-wrap {
    display: flex;
  }
}

@media (max-width: 991.98px) {
  .filials-list .section-title__back {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .filials-slider {
    overflow: visible;
  }
}

.filials-slider .swiper-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1.875rem;
  width: auto;
}

@media (max-width: 991.98px) {
  .filials-slider .swiper-wrapper {
    width: 100%;
    flex-wrap: nowrap;
    margin-right: 0;
  }
}

.filials-slider .swiper-slide {
  width: calc(25% - 1.875rem);
  margin-bottom: 1.875rem;
  box-shadow: none;
}

@media (max-width: 1500px) {
  .filials-slider .swiper-slide {
    width: calc(33.333% - 1.875rem);
  }
}

@media (max-width: 991.98px) {
  .filials-slider .swiper-slide {
    width: 24.375rem;
    padding-left: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 575.98px) {
  .filials-slider .swiper-slide {
    width: 100%;
  }
}

.filial-card {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
}

.filial-card__top {
  width: 100%;
}

.filial-card__image {
  width: 100%;
  position: relative;
  padding-top: 62.8%;
}

.filial-card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
}

.filial-card__bottom {
  padding: 1.25rem 1.875rem 2.5rem;
}

@media (max-width: 991.98px) {
  .filial-card__bottom {
    padding: 1.25rem 1.5625rem 2.1875rem;
  }
}

@media (max-width: 767.98px) {
  .filial-card__bottom {
    padding: 1.25rem 1.25rem 2.1875rem;
  }
}

.filial-card__bottom h5 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #000000;
  margin-bottom: 1.25rem;
}

.filial-card__bottom .btn {
  padding: 0;
  width: 14.375rem;
}

@media (max-width: 991.98px) {
  .filial-card__bottom .btn {
    width: 100%;
    max-width: 14.375rem;
    font-size: 0.9375rem;
  }
}

.about-services {
  margin-bottom: 1.875rem;
}

@media (max-width: 991.98px) {
  .about-services__cover {
    margin: 0 -1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .about-services__cover {
    margin: 0 -1.25rem;
  }
}

.about-services__cover .swiper-wrap {
  width: 100%;
  margin: 0;
  box-shadow: none;
}

@media (max-width: 991.98px) {
  .about-services__cover .swiper-wrap {
    padding-top: 2.5rem;
  }
}

.about-services__cover .swiper-wrap .swiper-button-wrap {
  display: none;
}

@media (max-width: 991.98px) {
  .about-services__cover .swiper-wrap .swiper-button-wrap {
    display: flex;
  }
}

.about-services__items {
  display: flex;
  align-items: stretch;
  margin-left: -1.875rem;
  margin-bottom: 3.4375rem;
}

@media (max-width: 991.98px) {
  .about-services__items {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.about-services__item {
  width: 50%;
  padding-left: 1.875rem;
}

@media (max-width: 991.98px) {
  .about-services__item {
    width: 100%;
    margin-bottom: 1.25rem;
  }
  .about-services__item:last-child {
    margin-bottom: 0;
  }
}

.about-services-card {
  width: 100%;
  height: 22.1875rem;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15%;
}

@media (max-width: 991.98px) {
  .about-services-card {
    padding: 0 15% 0 1.5625rem;
    height: 20rem;
  }
}

@media (max-width: 767.98px) {
  .about-services-card {
    padding: 0 15% 0 1.25rem;
  }
}

.about-services-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(45, 48, 62, 0.8), rgba(45, 48, 62, 0.8));
}

.about-services-card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
}

.about-services-card__text {
  position: relative;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.8125;
  color: #FFFFFF;
  z-index: 1;
}

@media (max-width: 767.98px) {
  .about-services-card__text {
    font-size: 0.9375rem;
  }
}

.about-services-card__text p {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.8125;
  color: #FFFFFF;
}

@media (max-width: 767.98px) {
  .about-services-card__text p {
    font-size: 0.9375rem;
  }
}

.about-advantages__container {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: auto;
  margin-left: -2.5rem;
}

@media (max-width: 991.98px) {
  .about-advantages__container {
    flex-wrap: nowrap;
    margin-left: 0;
    width: 100%;
  }
}

.about-advantages__item {
  width: 33.333333%;
  padding-left: 2.5rem;
  margin-bottom: 1.875rem;
  box-shadow: none;
  position: relative;
}

@media (min-width: 992px) {
  .about-advantages__item {
    margin-right: 0;
  }
}

@media (max-width: 991.98px) {
  .about-advantages__item {
    width: auto;
    padding-left: 0;
    margin-bottom: 0;
  }
}

.about-advantages__item::before {
  content: "";
  width: 1px;
  height: 100%;
  background: #E2E2E2;
  position: absolute;
  top: 0;
  right: -1.25rem;
}

@media (max-width: 575.98px) {
  .about-advantages__item::before {
    display: none;
  }
}

@media (max-width: 1500px) {
  .about-advantages__item {
    width: 50%;
  }
}

@media (max-width: 991.98px) {
  .about-advantages__item {
    width: 24.375rem;
  }
}

@media (max-width: 575.98px) {
  .about-advantages__item {
    width: 100%;
  }
}

.about-advantages__actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.875rem;
}

@media (max-width: 991.98px) {
  .about-advantages__actions {
    display: none;
  }
}

.about-advantages-card {
  position: relative;
  padding-left: 6.25rem;
}

@media (max-width: 991.98px) {
  .about-advantages-card {
    padding: 5.625rem 1.5625rem 0;
  }
}

.about-advantages-card__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 4.0625rem;
  height: 4.0625rem;
  border-radius: 50%;
  background: #D43A42;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .about-advantages-card__image {
    left: 1.25rem;
  }
}

.about-advantages-card__cont {
  padding-top: 0.9375rem;
}

@media (max-width: 991.98px) {
  .about-advantages-card__cont {
    padding-top: 0;
  }
}

.about-advantages-card__cont h5 {
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.59091;
  color: #000000;
  margin-bottom: 0.625rem;
}

@media (max-width: 991.98px) {
  .about-advantages-card__cont h5 {
    margin-bottom: 0.9375rem;
  }
}

.about-advantages-card__cont p {
  font-weight: normal;
  font-size: 1rem;
  line-height: 2;
  color: #000000;
}

.about-clients {
  margin-bottom: 2.5rem;
}

@media (max-width: 991.98px) {
  .about-clients {
    margin-bottom: 0;
  }
}

.about-clients__cover {
  box-shadow: none;
  margin: 0;
  width: 100%;
}

@media (max-width: 991.98px) {
  .about-clients__cover {
    width: auto;
    margin: 0 -1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .about-clients__cover {
    margin: 0 -1.25rem;
  }
}

.about-clients__cover .swiper-button-wrap {
  display: none;
}

@media (max-width: 991.98px) {
  .about-clients__cover .swiper-button-wrap {
    display: flex;
  }
}

@media (max-width: 991.98px) {
  .about-clients .section-title__back {
    display: none;
  }
}

.about-clients .swiper-wrapper {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: auto;
  margin-right: -1.875rem;
}

@media (max-width: 991.98px) {
  .about-clients .swiper-wrapper {
    flex-wrap: nowrap;
    margin-right: 0;
    width: 100%;
  }
}

.about-clients .swiper-slide {
  width: calc(25% - 1.875rem);
  margin-bottom: 1.875rem;
  box-shadow: none;
  position: relative;
}

@media (max-width: 1500px) {
  .about-clients .swiper-slide {
    width: calc(33.333% - 1.875rem);
  }
}

@media (max-width: 991.98px) {
  .about-clients .swiper-slide {
    margin-bottom: 0;
    padding-left: 0;
    width: 24.375rem;
  }
}

@media (max-width: 575.98px) {
  .about-clients .swiper-slide {
    width: 100%;
  }
}

.clients-card__image {
  position: relative;
  width: 100%;
  padding-top: 62.8%;
}

@media (max-width: 767.98px) {
  .clients-card__image {
    padding-top: 76.56%;
  }
}

.clients-card__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
}

.our-branches--about {
  margin-bottom: 3.4375rem;
}

@media (max-width: 991.98px) {
  .our-branches--about {
    display: none;
  }
}

.our-branches--about .map-inside h4::after {
  content: none;
}

.catalog-section-hero {
  position: relative;
  display: flex;
  flex-flow: column;
  padding-bottom: 10rem;
  background-color: #F7F7F7;
  padding-top: 10.3125rem;
  overflow: hidden;
  min-height: 43.75rem;
}

@media (max-width: 1500px) {
  .catalog-section-hero {
    padding-top: 0;
  }
}

@media (max-width: 767.98px) {
  .catalog-section-hero {
    min-height: 28.125rem;
  }
}

.catalog-section-hero .breadcrumb {
  position: relative;
  z-index: 3;
}

.catalog-section-hero .row > div {
  display: flex;
  flex-flow: column;
}

.catalog-section-hero .circle {
  border-width: 7.5rem;
}

@media (max-width: 767.98px) {
  .catalog-section-hero .circle {
    border-width: 3.25rem;
  }
}

.catalog-section-hero .circle-1 {
  width: 80.5625rem;
  height: 78.3125rem;
  top: -6.25rem;
  left: 9.75rem;
  transform: translate(-50%, -50%);
  z-index: 2;
}

@media (max-width: 1500px) {
  .catalog-section-hero .circle-1 {
    opacity: 0.5;
  }
}

@media (max-width: 767.98px) {
  .catalog-section-hero .circle-1 {
    width: 30.625rem;
    height: 30.625rem;
    top: -10rem;
    left: 0rem;
    transform: translate(-50%, 0%);
    z-index: initial;
  }
}

.catalog-section-hero .circle-2 {
  width: 56.4375rem;
  height: 54.9375rem;
  top: auto;
  bottom: 5rem;
  right: 5rem;
}

@media (max-width: 767.98px) {
  .catalog-section-hero .circle-2 {
    width: 18.5625rem;
    height: 18.5625rem;
    bottom: 2.8125rem;
    right: 2.8125rem;
  }
}

.catalog-section-hero__content {
  min-height: 30rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 3.125rem;
}

@media (max-width: 1500px) {
  .catalog-section-hero__content {
    min-height: 0;
    padding-top: 5rem;
    padding-left: 0;
  }
}

@media (max-width: 767.98px) {
  .catalog-section-hero__content {
    padding-top: 3.125rem;
  }
}

.catalog-section-hero img {
  position: absolute;
  top: -9.6875rem;
  left: -12.5rem;
  width: 73.875rem;
  max-width: none;
  z-index: 2;
}

@media (max-width: 1500px) {
  .catalog-section-hero img {
    width: 63.4375rem;
    top: -5.625rem;
    left: -16.25rem;
  }
}

@media (max-width: 767.98px) {
  .catalog-section-hero img {
    width: 35.4375rem;
    top: -8.125rem;
    right: 0rem;
    left: auto;
  }
}

@media (max-width: 575.98px) {
  .catalog-section-hero img {
    right: -12.8125rem;
  }
}

.catalog-section-hero h1 {
  font-weight: 800;
  font-size: 3.5rem;
  line-height: 4.6875rem;
  text-transform: uppercase;
  color: #000000;
  position: relative;
  z-index: 2;
}

@media (max-width: 1500px) {
  .catalog-section-hero h1 {
    padding-left: 0;
  }
}

@media (max-width: 991.98px) {
  .catalog-section-hero h1 {
    font-size: 2.125rem;
    line-height: 3.125rem;
  }
}

.catalog-section-hero h1:before {
  content: "";
  width: 0.3125rem;
  height: 2.5rem;
  position: absolute;
  top: 2.5rem;
  transform: translateY(-1.25rem);
  left: -3.125rem;
  background: #D53A42;
  box-shadow: 0rem 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
}

@media (max-width: 991.98px) {
  .catalog-section-hero h1:before {
    left: -1.5625rem;
    top: 50%;
    height: 4.6875rem;
    transform: translateY(-2.375rem);
  }
}

@media (max-width: 767.98px) {
  .catalog-section-hero h1:before {
    left: -1.25rem;
  }
}

.catalog-section-hero__title-back {
  font-weight: 800;
  font-size: 21.25rem;
  line-height: 4.8125rem;
  mix-blend-mode: multiply;
  opacity: 0.5;
  position: absolute;
  color: #E1E3EC;
  left: 50%;
  bottom: 8%;
  transform: translateX(-50%);
  z-index: 1;
}

@media (max-width: 1500px) {
  .catalog-section-hero__title-back {
    font-size: 12.5rem;
    bottom: 3%;
  }
}

@media (max-width: 767.98px) {
  .catalog-section-hero__title-back {
    display: none;
  }
}

.catalog-sort {
  padding: 1.875rem 0;
  position: relative;
}

.catalog-sort__cover {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 575.98px) {
  .catalog-sort__cover {
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.catalog-sort h5 {
  color: #000000;
  margin-right: 2.5rem;
}

@media (max-width: 575.98px) {
  .catalog-sort h5 {
    margin-right: 0;
    margin-bottom: 1.875rem;
  }
}

.catalog-sort__lines {
  width: 3.1875rem;
  height: 1.25rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/sort-lines-3.svg") no-repeat 50%/contain;
  margin-right: 2.8125rem;
}

@media (max-width: 1500px) {
  .catalog-sort__lines {
    height: 0.6875rem;
    background: url("/design/themes/komtrans_it/media/assets/icons/sort-lines.svg") no-repeat 50%/contain;
  }
}

@media (max-width: 575.98px) {
  .catalog-sort__lines {
    display: none;
  }
}

.catalog-sort .select-wrap {
  width: 14.1875rem;
}

@media (max-width: 575.98px) {
  .catalog-sort .select-wrap {
    margin-bottom: 0.625rem;
    width: 100%;
  }
}

.catalog-sort .select2-container .select2-selection--single {
  height: 3.4375rem;
}

.catalog-sort .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 3.4375rem;
}

.catalog-sort .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 3.4375rem;
}

.catalog-sort .select2-container--default .select2-selection--single {
  border: none;
  background: #FFFFFF;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
}

.catalog-sort .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000000;
  font-weight: 500;
}

.catalog-sort__title-back {
  font-weight: 800;
  font-size: 8.625rem;
  line-height: 4.8125rem;
  color: #000000;
  opacity: 0.05;
  position: absolute;
  right: 39.5625rem;
  bottom: -2.1875rem;
}

@media (max-width: 1500px) {
  .catalog-sort__title-back {
    right: 18.75rem;
  }
}

@media (max-width: 767.98px) {
  .catalog-sort__title-back {
    display: none;
  }
}

.catalog-main {
  padding-bottom: 1.875rem;
}

@media (max-width: 1500px) and (min-width: 992px) {
  .catalog-main .container > .row > div:first-child {
    flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (max-width: 1500px) and (min-width: 992px) {
  .catalog-main .container > .row > div:last-child {
    flex: 0 0 70%;
    max-width: 70%;
  }
}

@media (max-width: 575.98px) {
  .catalog-main__cards {
    margin: 0 -1.25rem;
  }
}

.catalog-main__cards > .row > div {
  margin-bottom: 1.875rem;
}

@media (max-width: 1500px) and (min-width: 768px) {
  .catalog-main__cards > .row > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.catalog-main__cards .card {
  width: 100%;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
}

.catalog-filter {
  background: #FFFFFF;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
  padding: 2.3125rem 2rem;
}

@media (max-width: 575.98px) {
  .catalog-filter {
    margin: 0 -1.25rem;
    padding: 2.1875rem 1.25rem;
  }
}

.catalog-filter h5 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}

.catalog-filter .calc-block {
  margin-top: 1.875rem;
}

.catalog-filter__actions-btn {
  display: flex;
  margin: 0 -0.625rem;
}

.catalog-filter__actions-btn .btn {
  margin: 0 0.625rem;
  width: 50%;
  padding: 0;
  height: 3.375rem;
}

.calc-block h6 {
  font-size: 0.9375rem;
  line-height: 1.8125rem;
  color: #000000;
  margin-bottom: 1.5625rem;
}

.calc-block__values-cover {
  display: flex;
  flex-wrap: wrap;
}

.calc-block__values-cover input {
  font-family: 'Gilroy', sans-serif;
  background: #FFFFFF;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
  font-weight: 300;
  font-size: 1rem;
  line-height: 2rem;
  color: #000000;
  margin-right: 1.25rem;
  border: none;
  width: 5.9375rem;
  padding: 0 1.125rem;
  height: 3.125rem;
  margin-bottom: 1.5625rem;
}

.calc-block__values-cover input:last-child {
  margin-right: 0;
}

.page-nav {
  margin: 0 auto;
  margin: 1.875rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575.98px) {
  .page-nav {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0.625rem 0;
  }
}

.page-nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.375rem;
  height: 3.375rem;
  background: #FFFFFF;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;
  line-height: 1.8125rem;
  color: #000000;
  margin: 0 0.625rem;
  text-decoration: none;
}

@media (max-width: 575.98px) {
  .page-nav a {
    margin: 0;
    margin-right: 1.25rem;
  }
}

@media (max-width: 575.98px) {
  .page-nav a:nth-child(4) {
    display: none;
  }
}

.page-nav a:last-child {
  background: none;
  box-shadow: none;
  margin-right: 0;
}

.page-nav a:hover {
  background: #F2F2F2;
  box-shadow: none;
}

.paragraph-block {
  padding: 3.75rem 0;
}

@media (max-width: 767.98px) {
  .paragraph-block {
    display: none;
  }
}

.paragraph-block__item {
  padding-left: 3.75rem;
}

@media (max-width: 991.98px) {
  .paragraph-block__item {
    padding-left: 0;
  }
}

@media (max-width: 991.98px) {
  .article {
    padding-top: 3.125rem;
  }
}

.article * {
  box-sizing: border-box;
}

.article h1 {
  font-weight: bold;
  font-size: 2.5625rem;
  line-height: 1.21951;
  color: #000000;
  margin-bottom: 1.25rem;
  text-transform: initial;
}

@media (max-width: 767.98px) {
  .article h1 {
    font-size: 1.875rem;
    margin-bottom: 0;
  }
}

.article h2, .article h3, .article h4, .article h5, .article h6 {
  line-height: normal;
}

.article .section-title-outside h2 {
  font-weight: bold;
  font-size: 3.5rem;
  line-height: 4.6875rem;
  color: #ECECEC;
  white-space: nowrap;
  margin-bottom: 0;
}

.article .section-title__back {
  line-height: 3.75rem;
}

.article ul {
  margin-bottom: 2.1875rem;
}

@media (max-width: 767.98px) {
  .article ul {
    margin-bottom: 1.875rem;
  }
}

.article ul li {
  font-weight: 300;
  font-size: 1rem;
  line-height: 2;
  color: #000000;
  margin-bottom: 1.25rem;
  position: relative;
  padding-left: 2.375rem;
  width: 100%;
  max-width: 42.5rem;
}

.article ul li:last-child {
  margin-bottom: 0;
}

.article ul li::before {
  content: "";
  position: absolute;
  top: 0.75rem;
  left: 0;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: #000;
}

@media (max-width: 767.98px) {
  .article ul li {
    max-width: none;
    font-size: 0.9375rem;
  }
}

.article ul.check-ul li {
  padding-left: 3.125rem;
}

.article ul.check-ul li::before {
  width: 1.5rem;
  height: 1.5rem;
  top: 0.3125rem;
  background: #D43A42;
}

.article ul.check-ul li::after {
  content: "";
  position: absolute;
  top: 0.75rem;
  left: 0.375rem;
  width: 0.75rem;
  height: 0.5625rem;
  background: url("/design/themes/komtrans_it/media/assets/icons/check-white.svg") no-repeat 50%/contain;
}

.article h2 {
  font-weight: bold;
  font-size: 2.375rem;
  line-height: 1.39474;
  color: #000000;
  margin-bottom: 1.25rem;
}

@media (max-width: 767.98px) {
  .article h2 {
    font-size: 1.625rem;
    margin-bottom: 0.625rem;
  }
}

.article h3 {
  font-weight: bold;
  font-size: 2.125rem;
  line-height: 1.26471;
  color: #000000;
  margin-bottom: 1.25rem;
}

@media (max-width: 767.98px) {
  .article h3 {
    font-size: 1.5rem;
    margin-bottom: 0.625rem;
  }
}

.article h4 {
  font-weight: bold;
  font-size: 1.5625rem;
  line-height: 1.28;
  color: #000000;
  margin-bottom: 1.25rem;
}

@media (max-width: 767.98px) {
  .article h4 {
    font-size: 1.3125rem;
    margin-bottom: 0.625rem;
  }
}

.article hr {
  width: 100%;
  height: 1px;
  background: #B8B8B8;
  margin: 2.8125rem 0;
}

@media (max-width: 767.98px) {
  .article hr {
    margin: 2.5rem 0;
  }
}

.article blockquote {
  padding: 3.125rem 2.1875rem 2.1875rem 3.4375rem;
  background: #F5F7FF;
  font-weight: 300;
  font-size: 1rem;
  line-height: 2;
  color: #000000;
  margin-bottom: 3.75rem;
}

@media (max-width: 767.98px) {
  .article blockquote {
    margin: 0 -1.25rem;
    padding: 1.25rem 1.875rem 2.5rem;
    font-size: 0.9375rem;
  }
}

.article blockquote strong {
  font-weight: bold;
}

.article p {
  font-weight: 300;
  font-size: 1rem;
  line-height: 2;
  color: #000000;
  margin: 0.9375rem 0;
}

.article p:first-child {
  margin-top: 0;
}

.article__row {
  display: flex;
  align-items: stretch;
  margin: 0 -1.5625rem 3.75rem;
}

.article__row--reverse {
  flex-direction: row-reverse;
}

@media (max-width: 991.98px) {
  .article__row {
    flex-direction: column;
  }
}

@media (max-width: 767.98px) {
  .article__row {
    margin: 0 -1.25rem 2.5rem;
  }
}

.article__col {
  padding: 0 1.5625rem;
}

@media (max-width: 767.98px) {
  .article__col {
    padding: 0 1.25rem;
  }
}

.article__col:first-child {
  width: 40%;
}

@media (max-width: 991.98px) {
  .article__col:first-child {
    width: 100%;
    margin-bottom: 1.875rem;
  }
}

.article__col:last-child {
  width: 60%;
}

@media (max-width: 991.98px) {
  .article__col:last-child {
    width: 100%;
  }
}

.article__ul {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-left: -1.875rem;
  margin-top: 2.8125rem;
}

@media (max-width: 767.98px) {
  .article__ul {
    margin-top: 1.875rem;
  }
}

.article__ul ul {
  width: 50%;
  padding-left: 1.875rem;
}

@media (max-width: 767.98px) {
  .article__ul ul {
    width: auto;
  }
}

.article__image {
  width: 100%;
  position: relative;
  height: 20.625rem;
}

@media (max-width: 767.98px) {
  .article__image {
    margin: 0 -1.25rem;
    width: auto;
  }
}

.article__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover;", serif;
}

.article img {
  width: 100%;
  display: block;
}

.our-branches--article {
  margin-top: 2.1875rem;
}

@media (max-width: 767.98px) {
  .our-branches--article {
    display: none;
  }
}

.our-branches--article .map-inside h4:after {
  content: none;
}

.leasing-block .payment-delivery:after {
  right: auto;
  left: 0;
}

@media (max-width: 991.98px) {
  .leasing-block .payment-delivery:after {
    display: none;
  }
}

.leasing-block .payment-delivery .payment-delivery__method {
  width: 50%;
}

@media (max-width: 1500px) {
  .leasing-block .payment-delivery .payment-delivery__method {
    width: 100%;
  }
}

.payment-delivery.gray {
  background: #2D303E;
  color: #fff;
}

.payment-delivery.gray h4 {
  color: #FFFFFF;
}

.payment-delivery.gray:after {
  background: #505466;
}

.payment-delivery.gray .payment-delivery__method {
  color: #fff;
}

.payment-delivery.gray p {
  color: #fff;
}

.content-row {
  margin-bottom: 3.125rem;
}

@media (max-width: 991.98px) {
  .content-row {
    margin-bottom: 2.1875rem;
  }
}

.content-row .img-wrap {
  height: 100%;
  position: relative;
}

@media (max-width: 991.98px) {
  .content-row .img-wrap {
    height: 18.75rem;
  }
}

@media (max-width: 767.98px) {
  .content-row .img-wrap {
    height: 12.5rem;
  }
}

@media (max-width: 575.98px) {
  .content-row .img-wrap {
    margin: 0 -1.25rem;
  }
}

.content-row img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.content-row p {
  margin-bottom: 1.875rem;
}

.content-row p:last-child {
  margin-bottom: 0;
}

.leasing-calc {
  padding-bottom: 3.75rem;
}

@media (max-width: 991.98px) {
  .leasing-calc {
    padding-bottom: 0;
  }
}

.calculator {
  background: #FFFFFF;
  box-shadow: 0 0 2.125rem rgba(0, 0, 0, 0.1);
  display: flex;
}

@media (max-width: 991.98px) {
  .calculator {
    flex-flow: column;
    margin: 0 -1.5625rem;
  }
}

@media (max-width: 767.98px) {
  .calculator {
    margin: 0 -1.25rem;
  }
}

.calculator__estimation {
  display: flex;
  justify-content: space-between;
  padding: 3.4375rem 0;
  flex-grow: 1;
  position: relative;
}

@media (max-width: 991.98px) {
  .calculator__estimation {
    padding: 1.25rem 1.5625rem;
    flex-flow: column;
  }
}

@media (max-width: 767.98px) {
  .calculator__estimation {
    padding: 1.25rem;
  }
}

.calculator__estimation:after {
  content: "";
  display: block;
  width: 3.125rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: -3.0625rem;
  background: url("/design/themes/komtrans_it/media/assets/images/bg-row.png") no-repeat 100% 50%/cover;
  z-index: 2;
}

@media (max-width: 991.98px) {
  .calculator__estimation:after {
    display: none;
  }
}

@media (max-width: 576.98px) {
  .calculator__estimation:after {
    display: block;
    width: 100%;
    height: 3.125rem;
    top: auto;
    bottom: -3.0625rem;
    left: 0;
    right: auto;
    background: url("/design/themes/komtrans_it/media/assets/images/bg-row-mob.png") no-repeat 50% 100%/cover;
  }
}

.calculator__resut {
  padding: 3.4375rem 4.0625rem;
  padding-left: 5.9375rem;
  max-width: 23.75rem;
  width: 100%;
  color: #fff;
  background: #2D303E;
  position: relative;
}

@media (max-width: 1500px) {
  .calculator__resut {
    padding-left: 5rem;
    padding-right: 1.5625rem;
    max-width: 20rem;
  }
}

@media (max-width: 991.98px) {
  .calculator__resut {
    max-width: 100%;
    padding: 4.375rem 1.5625rem;
  }
}

.calculator__resut h6 {
  font-size: 0.9375rem;
  line-height: 1.8125rem;
  margin-bottom: 0.9375rem;
}

@media (max-width: 991.98px) {
  .calculator__resut h6 {
    text-align: center;
  }
}

.calculator__resut .btn {
  padding: 0;
  width: 100%;
  display: flex;
  max-width: 18.75rem;
}

@media (max-width: 991.98px) {
  .calculator__resut .btn {
    margin: 0 auto;
  }
}

.calculator__resut-price {
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.625rem;
  margin-bottom: 1.875rem;
}

@media (max-width: 991.98px) {
  .calculator__resut-price {
    text-align: center;
  }
}

.calculator .calc-block {
  width: 100%;
  padding-left: 4.0625rem;
  padding-right: 0.9375rem;
}

@media (max-width: 1500px) {
  .calculator .calc-block {
    padding-left: 1.875rem;
    padding-right: 0rem;
  }
}

@media (max-width: 991.98px) {
  .calculator .calc-block {
    padding-left: 0rem;
    padding-right: 0rem;
    margin-bottom: 1.875rem;
  }
}

.calculator .calc-block:last-child {
  padding-right: 4.0625rem;
}

@media (max-width: 1500px) {
  .calculator .calc-block:last-child {
    padding-right: 1.875rem;
  }
}

.calculator .calc-block:last-child .calc-block__values-cover input:last-child {
  width: 5.9375rem;
}

.calculator .calc-block h6 {
  margin-right: -1.25rem;
}

.calculator .calc-block__values-cover input:last-child {
  width: 8.125rem;
}

@media (max-width: 1500px) {
  .calculator .calc-block__values-cover input:last-child {
    width: 7.5rem;
  }
}

.icon {
	display: block;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	flex-shrink: 0;
}

.ig-icon {
	width: rem(21);
	height: rem(21);
	background-image: url("/design/themes/komtrans_it/media/assets/icons/ig.svg");
}

.vk-icon {
	width: rem(21);
	height: rem(21);
	background-image: url("/design/themes/komtrans_it/media/assets/icons/vk.svg");
}

.fb-icon {
	width: rem(21);
	height: rem(21);
	background-image: url("/design/themes/komtrans_it/media/assets/icons/fb.svg");
}

.time-light-icon {
	width: rem(19);
	height: rem(19);
	background-image: url("/design/themes/komtrans_it/media/assets/icons/time-light.svg");
}

.mail-light-icon {
	width: rem(19);
	height: rem(19);
	background-image: url("/design/themes/komtrans_it/media/assets/icons/mail-light.svg");
}

.location-light-icon {
	width: rem(20);
	height: rem(20);
	background-image: url("/design/themes/komtrans_it/media/assets/icons/location-light.svg");
}

.equipment-icon {
	width: rem(40);
	height: rem(39);
	background-image: url("/design/themes/komtrans_it/media/assets/icons/equipment-white.svg");
}

.locations-icon {
	width: rem(38);
	height: rem(38);
	background-image: url("/design/themes/komtrans_it/media/assets/icons/locations-white.svg");
}
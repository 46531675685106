.main {
	display: block;
}

.breadcrumb {
	margin: rem(35) 0;

	@media (max-width: 1500px) {
		margin-bottom: rem(25);
	}

	@media (max-width: 991.98px) {
  	display: none;  
	}

	li {
		display: inline;

		a {
			font-size: rem(15);
			line-height: rem(29);
			text-decoration-line: underline;
			color: #A4A4A4;
		}

		&:after {
			content: "-";
			padding: 0 rem(5);
			font-size: rem(15);
			line-height: rem(29);
			text-decoration-line: underline;
			color: #A4A4A4;
			text-decoration: none;
			padding-right: rem(2);
		}

		&:last-child {
			a {
				color: #000000;
			}

			&:after {
				content: "";

			}
		}
	}
}

.section-title {
	padding-bottom: rem(30);
	position: relative;
	// z-index: -1;
	@media (max-width: 1500px) {
		padding-bottom: rem(25)
	}

	@media (max-width: 767.98px) {
		margin-top: rem(25);
		padding-bottom: rem(20);
	}

	h2 {
		position: relative;
		z-index: 2;
	}

	&__back {
		font-weight: 800;
		font-size: rem(162);
		line-height: rem(77);
		color: #000000;
		opacity: 0.05;
		position: absolute;
		bottom: 18%;
		left: 0;
		white-space: nowrap;
		pointer-events: none;

		@media (max-width: 1500px) {
    	font-size: rem(138);
    	bottom: 13%;
  	}

  	@media (max-width: 991.98px) {
  		font-size: rem(100);
  	}

  	@media (max-width: 767.98px) {
  		font-size: rem(63);
  		bottom: -12%;
  	}

  	.hide-1 {
  		@media (max-width: 1500px) {
	    	display: none;
	  	}
  	}

  	.hide-2 {
  		@media (max-width: 991.98px) {
	  		display: none;
	  	}
  	}
	}
}

.section-title-outside {
	position: absolute;
	z-index: 10;
	transform: rotate(-90deg);
	left: rem(-350);
	top: rem(360);


  &__cover {
  	display: flex;
  	justify-content: flex-end;
		align-items: flex-end;
		width: rem(600);
  }

	h2 {
		font-weight: bold;
		font-size: rem(56);
		line-height: rem(75);
		color: #ECECEC;
		white-space: nowrap;
	}
	
	.title-num {
		margin-right: rem(32);
	}
}

.title-num {
	display: flex;
	align-items: center;

	&__number {
		font-size: rem(50);
	}

	&__number-small {
		font-size: rem(16);
		padding-left: rem(8);
		padding-bottom: rem(25);
		opacity: 0.4;
	}

	&__lines {
		display: flex;
		align-items: flex-end;
		position: relative;
		top: rem(-20);
	}

	&__line {
		display: inline-block;
		width: 1px;
		height: rem(42);
		margin-right: rem(11);
		background-color: #000;

		&.long {
			height: rem(77);
		}
	}
}

.swiper-wrap {
	position: relative;
}
.payment-delivery-block {
	padding-bottom: rem(35);

	@media (max-width: 767.98px) {
		padding-bottom: 0;
	}

	.row {
		
		& > div {
			margin-bottom: rem(35);

			&:last-child {
				@media (max-width: 575.98px) {
					margin-bottom: 0;
				}
			}
		}
	}
}

.payment-delivery {
	padding: rem(55) rem(40) rem(65) rem(65);
	box-shadow: rem(0) rem(0) rem(34) rgba(0, 0, 0, 0.1);
	position: relative;
	background-color: #fff;
	height: 100%;

	@media (max-width: 1500px) {
		padding: rem(40) rem(40) rem(60) rem(50);
	}

	@media (max-width: 767.98px) {
		padding: rem(25) rem(20) rem(40);
	}

	@media (max-width: 575.98px) {
		margin: 0 rem(-20);
	}

	&:after {
		content: "";
		display: block;
		width: rem(40);
		height: rem(40);
		position: absolute;
		right: 0;
		top: 0;
		background: #EEEEEE;
	}

	h4 {
		margin-bottom: rem(40);

		@media (max-width: 767.98px) {
			margin-bottom: rem(30);
		}
	}

	&__cover {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: rem(30);

		@media (max-width: 767.98px) {
			margin-bottom: rem(20);
		}
	}

	&__method {
		font-size: rem(18);
		line-height: rem(35);
		color: #000000;
		position: relative;
		padding-left: rem(70);
		padding-right: rem(20);
		margin-bottom: rem(25);

		@media (max-width: 1500px) {
			padding-right: rem(40);
			width: 100%;
		}

		@media (max-width: 767.98px) {
			font-size: rem(16);
			line-height: rem(35);
			padding-left: rem(55);
			margin-bottom: rem(16);
		}

		&:before {
			content: "";
			display: block;
			width: rem(46);
			height: rem(46);
			border-radius: 50%;
			background: url("/design/themes/komtrans_it/media/assets/icons/check-white.svg") no-repeat 50% / rem(17), #D43A42;
			position: absolute;
			top: rem(-5);
			left: 0;

			@media (max-width: 767.98px) {
				width: rem(40);
				height: rem(40);
				top: rem(0);
			}
		}
	}
}
$browser-context: 16; // Default

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function lh($lh, $fs) {
  @return #{$lh/$fs};
}


@import "_fonts";
@import "_reset";
@import "_common.scss";
@import "_icons.scss";
@import "_header.scss";
@import "_main.scss";
@import "_footer.scss";
@import "_home-page.scss";
@import "_payment-delivery.scss";
@import "_filial.scss";
@import "_card-product.scss";
@import "_about.scss";
@import "_catalog-section.scss";
@import "_article.scss";
@import "_leasing.scss";

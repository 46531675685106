.leasing-block {

	.payment-delivery {
		&:after {
			right: auto;
			left: 0;

			@media (max-width: 991.98px) {
				display: none;
			}
		}
		.payment-delivery__method {
			width: 50%;

			@media (max-width: 1500px) {
    		width: 100%;
			}
		}
	}
}

.payment-delivery.gray {
	background: #2D303E;
	color: #fff;

	h4 {
		color: #FFFFFF;
	}

	&:after {
		background: #505466;
	}

	.payment-delivery__method {
		color: #fff;
	}

	p {
		color: #fff;
	}
}

.content-row {
	margin-bottom: rem(50);

	@media (max-width: 991.98px) {
  	margin-bottom: rem(35);  
	}

	.img-wrap {
		height: 100%;
		position: relative;

		@media (max-width: 991.98px) {
			height: rem(300);
		}

		@media (max-width: 767.98px) {
			height: rem(200);
		}		


		@media (max-width: 575.98px) {
			margin: 0 rem(-20);
		}
	}

	img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover;';
	}

	p {
		margin-bottom: rem(30);

		&:last-child {
			margin-bottom: 0;
		}
	}
}


.leasing-calc {
	padding-bottom: rem(60);

	@media (max-width: 991.98px) {
		padding-bottom: 0;
	}
}

.calculator {
	background: #FFFFFF;
	box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
	display: flex;

	@media (max-width: 991.98px) {
    flex-flow: column;
    margin: 0 rem(-25);
	}

	@media (max-width: 767.98px) {
		margin: 0 rem(-20);
	}

	&__estimation {
		display: flex;
		justify-content: space-between;
		padding: rem(55) 0;
		flex-grow: 1;
		position: relative;

		@media (max-width: 991.98px) {
    	padding: rem(20) rem(25);
    	flex-flow: column;
		}

		@media (max-width: 767.98px) {
			padding: rem(20); 
		}

		&:after {
			content: "";
			display: block;
			width: rem(50);
			height: 100%;
			position: absolute;
			top: 0;
			right: rem(-49);
			background: url("/design/themes/komtrans_it/media/assets/images/bg-row.png") no-repeat 100% 50% / cover;
			z-index: 2;

			@media (max-width: 991.98px) {
    		display: none;
			}

			@media (max-width: 576.98px) {
				display: block;
				width: 100%;
				height: rem(50);
				top: auto;
				bottom: rem(-49);
				left: 0;
				right: auto;
				background: url("/design/themes/komtrans_it/media/assets/images/bg-row-mob.png") no-repeat 50% 100% / cover;
			}
		}
	}

	&__resut {
		padding: rem(55) rem(65);
		padding-left: rem(95);
		max-width: rem(380);
		width: 100%;
		color: #fff;
		background: #2D303E;
		position: relative;

		@media (max-width: 1500px) {
			padding-left: rem(80);
			padding-right: rem(25);
			max-width: rem(320);

		}

		@media (max-width: 991.98px) {
			max-width: 100%;
			padding: rem(70) rem(25);
		}

		h6 {
			font-size: rem(15);
			line-height: rem(29);
			margin-bottom: rem(15);

			@media (max-width: 991.98px) {
				text-align: center;
			}
		}

		.btn {
			padding: 0;
			width: 100%;
			display: flex;
			max-width: rem(300);

			@media (max-width: 991.98px) {
				margin: 0 auto;
			}
		}
	}

	&__resut-price {
		font-weight: bold;
		font-size: rem(26);
		line-height: rem(26);
		margin-bottom: rem(30);

		@media (max-width: 991.98px) {
			text-align: center;
		}
	}

	.calc-block {
		
		width: 100%;
		padding-left: rem(65);
		padding-right: rem(15);

		@media (max-width: 1500px) {
    	padding-left: rem(30);
    	padding-right: rem(0);
		}

		@media (max-width: 991.98px) {
			padding-left: rem(0);
    	padding-right: rem(0);
    	margin-bottom: rem(30);
		}

		&:last-child {
			padding-right: rem(65);

			@media (max-width: 1500px) {
				padding-right: rem(30);
			}

			.calc-block__values-cover {
				input {
					&:last-child {
						width: rem(95);
					}
				}
			}
		}

		h6 {
			margin-right: rem(-20);
		}

		&__values-cover {
			input {
				&:last-child {
					width: rem(130);

					@media (max-width: 1500px) {
    				width: rem(120);
					}
				}
			}
		}
	}
}

.hero-block {
	height: rem(1120);

	@media (max-width: 1500px) {
    height: rem(640);
	}

	@media (max-width: 767.98px) {
		height: rem(410);
	}

	> img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover;';
	}

	&__blackout {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
	}

	&__content-cover {
		padding-left: rem(50);

		@media (max-width: 1500px) {
			padding-left: 0;
		}
	}

	&__content {
		height: 100%;
		display: flex;
		flex-flow: column;
		justify-content: flex-end;
		padding-bottom: rem(215);
		padding-top: rem(165);

		@media (max-width: 1500px) {
			padding-bottom: 0;
			padding-top: rem(130);
			justify-content: center;
		}

		@media (max-width: 767.98px) {
			padding-top: 0;
			padding-bottom: rem(60);
			justify-content: flex-end;
		}
		
		h1 {
			position: relative;

			&:before {
				content: "";
				width: rem(5);
				height: rem(40);
				position: absolute;
				top: 50%;
				transform: translateY(rem(-20));
				left: rem(-50);
				background: #D53A42;
				box-shadow: rem(0) rem(4) rem(20) rgba(0, 0, 0, 0.1);

			}

		}


		p {
			color: #fff;
			max-width: rem(350);
		}

		.btn {
			margin-top: rem(45);

			@media (max-width: 767.98px) {
				margin-top: rem(30);
			}
		}
	}

	.container {
		z-index: 2;
		color: #fff;
	}

	.section-title-outside {
		left: auto;
		right: rem(0);
		z-index: 1;
		bottom: rem(150);
		top: auto;
		left: auto;

		@media (max-width: 1500px) {
			display: none;
		}
	}

	.section-title-outside__cover {
		width: auto;
	}

	.title-num {
		margin-right: 0;
	}

	.title-num__line {
		background-color: #fff;
	}

	.title-num__number {
		color: #fff;
	}

	.title-num__number-small {
		color: #fff;
	}

}

.advantages-block-wrap {
	.advantages-block {
		margin-top: rem(-70);
		width: calc(100vw - (100vw - #{rem(1650)}) / 2);
		
		@media (max-width: 1500px) {
			margin-top: 0;
			width: 100%;
		}

		@media (max-width: 991.98px) {
			margin: 0;

		}

		&__text-content {
			padding: 0 rem(50);

			@media (max-width: 991.98px) {
				padding-top: rem(50);
			}

			@media (max-width: 991.98px) {
				padding: rem(30) rem(20);
			}
		}

		.swiper-wrap {
			width: 100%;
		}
	}

	> .container {
		@media (max-width: 1500px) {
			padding: 0;
		}
	}
}

.swiper-hero-block {

	.swiper-notification {
    position: relative;
    left: auto;
    top: auto;
	}

	.swiper-pagination {
		bottom: rem(100);

		@media (max-width: 1500px) {
			bottom: rem(40);
		}

		@media (max-width: 767.98px) {
			bottom: rem(20);
		}
	}
}


.advantages-block {
	padding-top: rem(50);
	position: relative;
	z-index: 2;

	@media (max-width: 1500px) {
		margin-top: 0;
	}

	@media (max-width: 991.98px) {
		padding-top: 0;
		margin: 0 rem(-25);
	}

	@media (max-width: 767.98px) {
		margin: 0 rem(-20);
	}

	&--product {
		.section-title__back {
			@media (max-width: 767.98px) {
				display: none;
			}
		}

		.swiper-wrap {
			@media (max-width: 1500px) {
				margin-left: rem(-50);
			}
		}
	}

	&:before {
		content: "";
		width: 100%;
		height: 80%;
		position: absolute;
		top: 0;
		left: 0;
		background: url("/design/themes/komtrans_it/media/assets/images/advantages-block-bg.png") no-repeat 50% / cover, #000;
		
		@media (max-width: 991.98px) {
			display: none;
		}
	}

	&__text-content {
		display: flex;
		max-width: rem(1650);
		width: 100%;

		@media (max-width: 991.98px) {
	  	background: url("/design/themes/komtrans_it/media/assets/images/advantages-block-bg.png") no-repeat 50% / cover, #000;
			padding: 0 rem(20);
		}


		.row {
			width: 100%;
		}
	}

	h3 {
		color: #fff;
		font-size: rem(38);
		line-height: rem(50);
		margin-bottom: rem(20);

		@media (max-width: 767.98px) {
			font-size: rem(27);
			line-height: rem(40);
		}
	}

	.section-title {
		@media (max-width: 991.98px) {
			margin-top: rem(20);
		}
	}

	.section-title h3 {
		@media (max-width: 991.98px) {
			margin-bottom: 0;
		}
	}

	.section-title__back {
		color: #FFFFFF;
		opacity: 0.06;
	}

	p {
		color: #fff;
		margin-bottom: rem(40);

		@media (max-width: 767.98px) {
			margin-bottom: rem(0);
		}
	}

	&__back-title {
		opacity: 0.05;
		color: #fff;
		font-size: rem(100);
		font-weight: bold;
		position: absolute;
		top: rem(175);
		left: 0;
	}

	.swiper-wrap {

		@media (max-width: 991.98px) {
    	margin: 0;
		}

		@media (min-width: 992px) {
	    .swiper-button-prev,
			.swiper-button-next {
				background: #1E1D1C;

				&:hover {
					background: #D43A42;
				}

				&:after {
					color: #FFFFFF;
				}
			}
			.swiper-button-next {
				&:after {
					background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-next-white.svg");
				}
			} 

			.swiper-button-prev {
				&:after {
					background-image: url("/design/themes/komtrans_it/media/assets/icons/chevron-swiper-prev-white.svg");
				}
			}
		}
	}
}


.advantage-card {
	width: rem(435);
	padding: rem(50);
	background: #FFFFFF;
	position: relative;
	height: 100%;

	@media (max-width: 767.98px) {
		padding: rem(35) rem(20);
	}

	@media (max-width: 575.98px) {
		width: 100%;	
	}

	&:after {
		content: "";
		display: block;
		width: rem(40);
		height: rem(40);
		position: absolute;
		right: 0;
		top: 0;
		background: #EEEEEE;

		@media (max-width: 575.98px) {
			display: none;
		}
	}

	& > i {
		display: flex;
		margin-bottom: rem(30);
		width: rem(66);
		height: rem(66);
		align-items: center;
		justify-content: center;
		background-color: #D43A42;
		border-radius: 50%;
	}

	h4 {
		font-size: rem(30);
		line-height: rem(43);
		margin-bottom: rem(10);
	}

	p {
		color: #000000;
		margin-bottom: rem(5);
		line-height: rem(29);
	}
}

.our-branches {

	&__cover {
		position: relative;

		@media (max-width: 991.98px) {
			display: flex;
			flex-wrap: wrap;
		}

		.map-inside h4:after {
			content: none;
		}
	}

	.map-inside {
		margin-bottom: rem(20);

		@media (max-width: 991.98px) {
			margin-right: rem(20);
		}
	}

	&__map {
		position: absolute;
		top: rem(-80);
		left: rem(460);

		@media (max-width: 1500px) {
			right: auto;
			top: rem(0);
			left: rem(400);
		}

		@media (max-width: 991.98px) {
    	position: relative;
    	top: auto;
    	left: auto;
    	margin: 0 auto;
		}
	}

	.ymaps-2-1-76-zoom {
		display: none
	}

	.ymaps-2-1-76-map-copyrights-promo {
		display: none
	}

	.ymaps-2-1-76-copyright {
		display: none;
	}
}

.branches-map {
	width: rem(1247);

	@media (max-width: 1500px) {
		width: rem(830);
	}

	@media (max-width: 991.98px)  {
		width: 100%;
	}

	img {
		width: 100%;
		height: auto;
	}

	svg {
		width: 100%;
		height: auto;

		path.region {
			cursor: pointer;

			&:hover {
				fill: #D43A42;

				+ path.marker {
					fill: #fff;
				}
			}
			
		}
		path.marker {
			pointer-events: none;
		}
	}
}

.card {
	width: rem(390);
	background-color: #fff;
	padding-bottom: rem(20);
	position: relative;

	@media (max-width: 575.98px) {
		width: 100%;	
	}

	a {
		text-decoration: none;
	}

	&__img-wrap {
		display: block;
		position: relative;
		height: rem(195);
		margin-bottom: rem(20);

		img {
			width: 100%;
  		height: 100%;
			position: absolute;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}
	}

	h5 {
		margin-bottom: rem(15);

		@media (max-width: 767.98px) {
			margin-bottom: rem(10);
		}

		a {
			display: inline-block;
		}
	}

	table {
		width: 100%;

		td {
			font-size: rem(14);
			line-height: rem(29);
			color: #000000;
		}

		tr {
			&:nth-child(3) {
				td {
					@media (max-width: 575.98px) {
						display: none;
					}
				}
			}
		}
	}

	&__body {
		padding: 0 rem(30);
		margin-bottom: rem(20);

		@media (max-width: 767.98px) {
			padding: 0 rem(20);
			margin-bottom: rem(25);
		}
	}

	&__link {
		text-decoration: none;
		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}
	}

	&__footer {
		padding: rem(0) rem(30);
		margin-bottom: rem(20);

		@media (max-width: 767.98px) {
			padding: 0 rem(20);
		}
		@media (max-width: 767.98px) {
			margin-bottom: rem(10);
		}

		.row {
			& > div {
				@media (max-width: 767.98px) {
					display: flex;
					justify-content: center;
					flex-flow: column;
					align-items: center;
				}
				
			}
		}
	}

	&__price {
		a {
			font-weight: 500;
			font-size: rem(13);
			line-height: rem(26);
			text-transform: uppercase;
			color: #000000;
			text-decoration: none;

			@media (max-width: 767.98px) {
				font-size: rem(15);
			}
		}
	}

	&__price-value {
		font-weight: bold;
		font-size: rem(26);
		line-height: rem(26);
		color: #000000;
		margin-bottom: rem(5);

		@media (max-width: 767.98px) {
			margin-top: rem(22);
		}
	}

	.btn {
		display: flex;
		width: 100%;
		padding: 0;
		height: rem(54);
		position: relative;
		z-index: 5;
	}

	&__time {
		color: #9E9E9E;
	}
}


.catalog-equipment {
	background: #2D303E;
	position: relative;
	overflow: hidden;
	padding: rem(25) 0;

	@media (max-width: 767.98px) {
		padding: 0;
	}

	.container {
		position: relative;
		z-index: 3;
	}

	.section-title-outside {
		h2 {
			opacity: 0.1;
		}

		.title-num__number {
			color: #fff;
		}
		.title-num__number-small {
			color: #fff;
		}

		.title-num__line {
			background-color: #fff;
		}
	}

	.section-title {
		@media (max-width: 767.98px) {
			margin-bottom: rem(10);
		}

		h3 {
			color: #FFFFFF;
		}

		&__back {
			color: #FFFFFF;
			opacity: 0.06;
		}
	}

	.row {
		@media (max-width: 575.98px) {
			margin: 0 rem(-35);
		}

		& > div {
			margin-bottom: rem(40);

			@media (max-width: 575.98px) {
				margin-bottom: rem(20);
			}

			@media (max-width: 1500px) and (min-width: 992px) {
    		flex: 0 0 50%;
   			max-width: 50%;
			}

			&:last-child {
				@media (max-width: 575.98px) {
					margin-bottom: 0;
				}
			}
		}
	}

	.circle-main {
		border-width: rem(120);
		border-color: #3B3F4E;
		

		&.circle-1 {
			width: rem(1313);
			height: rem(1249);
			bottom: rem(-580);
			right: rem(-338);
			
			@media (max-width: 1500px) {
				top: 50%;
				bottom: auto;
				transform: translate(50%, -50%);
				right: 0;

			}
		}

		&.circle-2 {
			width: rem(921);
			height: rem(876);
			top: rem(70);
			left: rem(70);
			
			@media (max-width: 1500px) {


			}
		}
	} 

}


.catalog-card {
	background: #FFFFFF;
	box-shadow: rem(0) rem(0) rem(34) rgba(0, 0, 0, 0.1);
	width: 100%;
	height: 100%;
	position: relative;

	&:before {
		content: "";
		display: block;
		width: rem(40);
		height: rem(40);
		position: absolute;
		left: 0;
		bottom: 0;
		background: #EEEEEE;
	}
	
	&__body {
		overflow: hidden;
		position: relative;
		padding: rem(40) rem(40);
		padding-top: rem(37);

		@media (max-width: 575.98px) {
			height: rem(215);
			padding: rem(50) rem(20);
		}
	}

	h5 {
		font-size: rem(24);
		line-height: rem(32);
		margin-bottom: rem(25);
		position: relative;
		z-index: 2;
		text-shadow: rem(2) rem(2) rem(2) #fff, rem(-2) rem(-2) rem(2) #fff;
		
		@media (max-width: 575.98px) {
			width: 60%;
		}
	}

	img {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}

	&__quantity {
		position: absolute;
		right: rem(20);
		bottom: rem(-27);
		background: #D43A42;
		box-shadow: rem(0) rem(4) rem(20) rgba(0, 0, 0, 0.1);
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(68);
		height: rem(54);
		font-weight: 500;
		font-size: rem(15);
		line-height: rem(26);
		text-transform: uppercase;
		color: #FFFFFF;
		z-index: 3;

		@media (max-width: 575.98px) {
			bottom: 0;
			right: rem(20);
			height: rem(50);
		}

	}



	.btn {
		height: rem(54);
		padding: 0 rem(27);
		position: relative;
		z-index: 2;

		@media (max-width: 575.98px) {
			position: absolute;
			left: rem(20);
			bottom: 0;
			height: rem(50);
		}
	}

	.circle {
		border-width: rem(50);
		width: rem(318);
		height: rem(318);
		top: 50%;
		transform: translateY(-50%);
		left: rem(-50);
	}

}


.catalog-card-1 {
	img {
		width: rem(382);
		right: -21%;

		@media (max-width: 575.98px) {
			right: rem(-116);
		}
	}
}

.catalog-card-2 {
	img {
		width: rem(304);
		right: -12%;

		@media (max-width: 575.98px) {
			right: rem(-98);
		}
	}
}

.catalog-card-3 {
	img {
		width: rem(262);
		right: -6%;

		@media (max-width: 575.98px) {
			right: rem(-37);
		}
	}
}

.catalog-card-4 {
	img {
		width: rem(395);
		right: -14%;

		@media (max-width: 575.98px) {
			right: rem(-157);
		}
	}
}

.catalog-card-5 {
	img {
		width: rem(260);
		right: -8%;

		@media (max-width: 575.98px) {
			right: rem(-67);
		}
	}
}

.catalog-card-6 {
	img {
		width: rem(375);
		right: -26%;

		@media (max-width: 575.98px) {
			right: rem(-187);
		}
	}
}

.catalog-card-7 {
	img {
		width: rem(331);
		right: -8%;
		top: 46%;

		@media (max-width: 575.98px) {
			right: rem(-121);
		}
	}
}

.catalog-card-8 {
	img {
		width: rem(283);
		right: -6%;

		@media (max-width: 575.98px) {
			right: rem(-69);
		}
	}
}

.catalog-card-9 {
	img {
		width: rem(284);
		right: -7%;

		@media (max-width: 575.98px) {
			right: rem(-115);
		}
	}
}

.catalog-card-10 {
	img {
		width: rem(366);
		right: -7%;

		@media (max-width: 575.98px) {
			right: rem(-75);
		}
	}
}

.catalog-card-11 {
	img {
		width: rem(307);
		right: -7%;

		@media (max-width: 575.98px) {
			right: rem(-124);
		}
	}
}

.catalog-card-12 {
	img {
		width: rem(296);
		right: -7%;

		@media (max-width: 575.98px) {
			right: rem(-109);
		}
	}
}

.catalog-card-13 {
	img {
		width: rem(252);
		right: -7%;

		@media (max-width: 575.98px) {
			right: rem(-70);
		}
	}
}

.issue-leasing {
	&__next-step {
		width: rem(82);
		height: rem(13);
		border-top: 1px solid #C0C0C0;
		border-bottom: 1px solid #C0C0C0;
		margin: 0 auto;
		margin-top: rem(50);
	}

	.row {
		justify-content: space-between;

		@media (max-width: 1500px) {
    	padding-right: rem(50);
		}

		@media (max-width: 991.98px) {
			flex-wrap: wrap;
			padding-right: 0;
			justify-content: flex-start;
		}

		& > div {
			margin-bottom: rem(50);
			width: rem(120);
			flex-grow: 0;
			flex-basis: auto;
			display: flex;


			@media (max-width: 991.98px) {
				width: 33.333%;
			}

			@media (max-width: 767.98px) {
				width: 100%;
				margin-bottom: rem(20);
			}

			&:nth-child(2n) {

				@media (max-width: 991.98px) {
    			display: none;
				}
			}
			
		}
	}

}

.leasing-card {
	width: rem(120);

	@media (max-width: 991.98px) {
		width: 100%
	}

	@media (max-width: 767.98px) {
		display: flex;
		align-items: center;
	}

	i {
		display: block;
		width: rem(120);
		margin-bottom: rem(28);
		flex-shrink: 0;

		@media (max-width: 767.98px) {
			width: rem(89);
			margin-bottom: 0;
			margin-right: rem(25);
		}

		img {
			width: 100%;
		}
	}

	p {
		font-size: rem(20);
		line-height: rem(32);
		color: #000000;
		font-weight: bold;
		margin-right: rem(-20);

		@media (max-width: 991.98px) {
			margin-right: 0;
		}

		@media (max-width: 767.98px) {
			br {
				display: none;
			}
		}
	}

	&.checked {
		i {
			box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
		}
	}

}

.circle {
	display: block;
	border-style: solid;
	border-color: rgba(#E1E3EC, 0.35);
	position: absolute;
	border-radius: 50%;
	pointer-events: none;
}

.company-benefits {
	position: relative;
	display: flex;
	flex-flow: column;

	&__back {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;

		@media (max-width: 991.98px)  {
			display: none;
		}

	}

	&__front {
		position: relative;
		z-index: 2;
		overflow: hidden;

		@media (max-width: 991.98px) {
			margin: 0 rem(-25);
		}

		@media (max-width: 767.98px) {
			margin: 0 rem(-20);
			overflow: visible;
		}

		.company-benefits__row {
			height: auto;
		}

		.company-benefits__content {
			box-shadow: none;
			min-height: rem(365);
			display: flex;
			justify-content: center;
			flex-flow: column;
			align-items: center;
			padding: rem(50);
			background: none;
			box-shadow: none;

			@media (max-width: 991.98px) {
				background: #2D303E;
				box-shadow: rem(0) rem(4) rem(20) rgba(0, 0, 0, 0.1);
				padding: rem(40);
				align-items: flex-start;
				min-height: 0;
			}

			@media (max-width: 767.98px) {
				padding: rem(30) rem(20);
			}

			&:before {
				display: none;
			}
		}

		.company-benefits__media {

			img {
				opacity: 0;

				@media (max-width: 991.98px) {
					opacity: 1;
				}
			}
		}
	}

	&__row {
		display: flex;
		height: 100%;

		@media (max-width: 991.98px) {
    	flex-flow: column;
		}
	}

	&__media {
		width: 51.1%;
		position: relative;
		overflow: hidden;
		height: 100%;

		@media (max-width: 991.98px) {
			width: 100%;
			height: 50%;
		}

		@media (max-width: 767.98px) {
			height: rem(200);
		}

		img {
			max-width: none;
			width: 100%;
  		height: 100%;
			position: absolute;
			object-fit: cover;
			object-position: 13% 0;
			font-family: 'object-fit: cover; object-position: 13% 0;';
			
			@media (max-width: 991.98px) {
				position: relative;
			}
		}
	}

	&__content {
		height: 100%;
		width: 48.9%;
		background: #2D303E;
		box-shadow: rem(0) rem(4) rem(20) rgba(0, 0, 0, 0.1);
		position: relative;
		color: #FFFFFF;

		@media (max-width: 991.98px) {
			width: 100%;
			height: auto;
		}

		&:before {
			content: "";
			display: block;
			width: rem(40);
			height: rem(40);
			position: absolute;
			left: 0;
			top: 0;
			background: #505466;

			@media (max-width: 767.98px) {
				display: none;
			}
		}

		h3 {
			font-weight: bold;
			font-size: rem(34);
			line-height: rem(63);
			color: #FFFFFF;
			margin-bottom: rem(35);

			@media (max-width: 767.98px) {
				font-size: rem(26);
				line-height: rem(42);
				margin-bottom: rem(25);
			}
		}

		p {
			color: #FFFFFF;
			margin-bottom: rem(10);
		}
	}

	&__content-cover {
		max-width: rem(550);
		width: 100%;
	}

	.circle {
		border-width: rem(120);
		border-color: #3B3F4E;

		@media (max-width: 768px) {
			border-width: rem(80);
		}
	}

	.circle-1 {
		width: rem(1358);
		height: rem(1250);

		top: rem(300);
		left: rem(360);

		@media (max-width: 1500px) {
			left: rem(60);
		}

		@media (max-width: 767.98px) {
			width: rem(845);
			height: rem(786);
			top: rem(30);
		}

	}

	.circle-2 {
		width: rem(952);
		height: rem(875);
		top: rem(80);
		left: rem(80);

		@media (max-width: 767.98px) {
			width: rem(599);
			height: rem(551);
			top: rem(40);
			left: rem(40);
		}

	}

	&.reverse {
		.company-benefits__row {
			flex-flow: row-reverse;

			@media (max-width: 991.98px)  {
				flex-flow: column;
			}
		}
	}
}

.company-info {

	h3 {
		font-size: rem(38);
		line-height: rem(63);
		color: #FFFFFF;
		margin-bottom: rem(55);
		position: relative;
		z-index: 3;

		@media (max-width: 1500px) {
			br {
				display: none;
			}
		}

		@media (max-width: 767.98px) {
			font-size: rem(27);
			line-height: rem(40);
			margin-bottom: rem(20);
		}
	}
	.company-benefits__row {
		@media (max-width: 991.98px) {
    	flex-flow: column-reverse;
		}
	}

	.company-benefits__content {
		padding: rem(70) rem(85);
		padding-bottom: 0;

		@media (max-width: 1500px) {
    	padding: rem(70) 0 0 rem(50);
		}

		@media (max-width: 991.98px) {
			padding-right: rem(50);
			padding-left: rem(50);
			overflow: hidden;
		}

		@media (max-width: 767.98px) {
			padding-top: rem(30);
			padding-bottom: rem(350);
			padding-right: rem(20);
			padding-left: rem(20);
		}
	}

	.company-benefits__media {
		@media (max-width: 767.98px) {
			display: none;
		}
	}

	&__benefits {
		@media (max-width: 991.98px) {
			margin-top: rem(-180);
		}

		@media (max-width: 767.98px) {
			margin-top: rem(-320);
		}

		.row {
			& > div {
				margin-bottom: rem(60);

				@media (max-width: 1500px) and (min-width: 992px){
  			  flex: 0 0 50%;
    			max-width: 50%;
    			margin-bottom: rem(40);
				}

				@media (max-width: 991.98px) {
					margin-bottom: rem(40);
				}

				@media (max-width: 767.98px) {
					margin-bottom: rem(20);

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.benefit-card {
	background: #FFFFFF;
	box-shadow: rem(0) rem(0) rem(34) rgba(0, 0, 0, 0.1);
	padding: rem(40) rem(50);
	position: relative;
	height: 100%;

	@media (max-width: 768px) {
		padding: rem(40) rem(20);
	}

	&:before {
		content: "";
		display: block;
		width: rem(40);
		height: rem(40);
		position: absolute;
		right: 0;
		bottom: 0;
		background: #D43A42;
	}

	h5 {
		font-weight: bold;
		font-size: rem(28);
		line-height: rem(35);
		color: #000000;
		margin-bottom: rem(20);

		@media (max-width: 768px) {
			font-size: rem(24);
			margin-bottom: rem(10);
		}
	}

	p {
		margin-bottom: rem(10);
		color: #000;
	}
}


.logo-card {
	padding: rem(10) rem(30);

	img {
		margin: 0 auto;
		height: rem(90);
	}
}

.helpful-information {
	.mob-swiper-button-center {
		.swiper-button-prev,
		.swiper-button-next {
			@media (max-width: 767.98px) {
				top:  rem(122);
			}
		}
	}
	.section-title-outside {
		top: rem(320);
	}
}

.ribbon {
	position: absolute;
	z-index: 3;
	left: 0;
	top: 0;
	overflow: hidden;
	width: rem(100);
	height: rem(100);

	&:before {
		content: "";
    border-bottom: rem(30) solid #2D303E;
    border-left: rem(30) solid transparent;
    border-right: rem(30) solid transparent;
    height: 0;
    width: rem(141);
    position: absolute;
    top: rem(22);
    left: rem(-30);
    transform: rotate(-45deg);
	}

	span {
		position: absolute;
		z-index: 2;
		font-weight: 500;
		font-size: rem(14);
		line-height: rem(29);
		color: #FFFFFF;
		transform: rotate(-45deg);
		display: block;
		top: rem(24);
    left: rem(3);

	}
}
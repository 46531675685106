.product-hero {
	padding-bottom: rem(25);

	@media (max-width: 767.98px) {
		padding-bottom: 0;
	}

	&__content-col {
		padding-left: rem(45);

		@media (max-width: 1500px) {
			padding-left: rem(35)
		}

		@media (max-width: 991.98px) {
    	padding-left: 15px;
		}			
	}
}

.product-galery {
	&__item {
		background: #FFFFFF;
		box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
		display: flex;
		align-items: center;
		justify-content: center;
		height: rem(250);
		position: relative;
		overflow: hidden;

		@media (max-width: 767.98px) {
			width: rem(280);
		}

		@media (max-width: 575.98px) {
			width: 100%;
		}


		&:after {
			content: "";
			display: block;
			width: rem(40);
			height: rem(40);
			position: absolute;
			bottom: 0;
			left: 0;
			background: #EEEEEE;

			@media (max-width: 767.98px) {
				display: none;
			}
		}

		&.main {
			height: rem(450);

			&:after {
				display: none;
			}
		}

		img {
			width: 100%;
			position: relative;
			z-index: 2;
		}
	}

	.row {

		> div {
			margin-bottom: rem(30);

		}
	}

	.circle {
		border-width: rem(60);

		@media (max-width: 767.98px) {
			border-width: rem(44);
		}
	}

	.circle-1 {
		width: rem(524);
		height: rem(524);
		top: 0;
		right: rem(-290);

		@media (max-width: 767.98px) {
			width: rem(351);
			height: rem(351);
			top: rem(70);
			right: auto;
			left: rem(105);
		}
	}

	.circle-2 {
		width: rem(318);
		height: rem(318);
		top: rem(50);
		left: rem(50);

		@media (max-width: 767.98px) {
			width: rem(213);
			height: rem(213);
			top: rem(20);
			left: rem(20);
		}
	}

	.swiper-wrap {
		@media (max-width: 575.98px) {
			margin: 0;
		}
		
	}
}


.product-actions {
	margin-bottom: rem(25);

	.row {
		justify-content: space-between;
		margin-right: rem(-8);
    margin-left: rem(-8);
    flex-wrap: nowrap;

    @media (max-width: 1500px) {
    	flex-wrap: wrap;
		}

		> div {
			padding: 0 rem(8);
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: auto;
			width: auto;
			display: flex;
			align-items: center;

			&:first-child {

				@media (max-width: 1500px) {
		    	flex-grow: 1;
		    	width: 100%;
		    	margin-bottom: rem(25);
				}

				@media (max-width: 575.98px) {
					width: 50%;
				}
			}

			&:nth-child(2) {
				@media (max-width: 575.98px) {
					order: 3;
					flex-grow: 1;
					max-width: 50%;
					width: 100%;
				}
			}

			&:nth-child(3) {
				@media (max-width: 575.98px) {
					order: 4;
					flex-grow: 1;
					max-width: 50%;
					width: 100%;
				}
			}

			&:last-child {
				@media (max-width: 575.98px) {
					order: 2;
					margin-bottom: rem(25);
				}
			}
		}
	}

	.btn {
		height: rem(54);
		padding: 0 rem(35);

		@media (max-width: 575.98px) {
			width: 100%;
		}
	}

	img {
		max-width: rem(132);
		margin-right: rem(-10);

		@media (max-width: 575.98px) {
			max-width: rem(95);
		}
	}

	&__price {
		font-weight: bold;
		font-size: rem(35);
		line-height: rem(26);
		color: #000000;
		padding-right: rem(25);

		@media (max-width: 767.98px) {
			font-size: rem(26);
		}
	}
}

.product-vailability {
	display: flex;
	align-items: center;
	font-size: rem(16);
	line-height: rem(29);
	margin-bottom: rem(12);
	flex-wrap: wrap;

	h6 {
		font-weight: 600;
		color: #000000;
		padding-right: rem(25);
		margin-bottom: rem(18);

		@media (max-width: 767.98px) {
			width: 100%;
			
		}
	}

	ul {
		display: flex;
		flex-wrap: wrap;


		li {
			color: #008473;
			padding-left: rem(25);
			margin-right: rem(25);
			position: relative;
			margin-bottom: rem(18);

			&:before {
				content: "•";
				color: #008473;
				position: absolute;
				top: 0;
				left: 0;
				font-size: rem(23);
			}

			&:last-child {
				margin-right: 0;
			}

			&.not-available {
				color: #D3D3D3;
				padding-left: 0;

				&:before {
					display: none;
				}
			}
		}
	}
}

.product-desc {
	padding: rem(25) 0;

	table {
		width: 100%;

		td {
			font-size: rem(16);
			line-height: rem(18);
			height: rem(40);
			color: #000000;
			font-weight: 300;
			vertical-align: middle;

			&:nth-child(1) {
				width: 47%;

				@media (max-width: 1500px) {
					width: 51%;
				}

				@media (max-width: 767.98px) {
					padding-right: rem(15);
					width: auto;
				}
			}

			&:nth-child(2) {
				width: 23%;

				@media (max-width: 1500px) {
					width: 14%;
				}

				@media (max-width: 767.98px) {
					display: none;
				}
			}

			&:nth-child(3) {
				width: 30%;

				@media (max-width: 1500px) {
					width: 35%;
				}
				@media (max-width: 767.98px) {
					width: auto;
				}
			}
		}
	}

	.line {
		display: block;
		width: rem(100);
		height: rem(1);
		background-color: #D9DEF1;

		@media (max-width: 1500px) {
    	width: rem(32);
		}	

	}

	&.basic {
		background: #F8F9FD;
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 100vw;
			height: 100%;
			position: absolute;
			top: 0;
			left: rem(-65);
			background: #F8F9FD;
			z-index: -1;

			@media (max-width: 991.98px) {
				left: rem(-25);
			}

			@media (max-width: 767.98px) {
				left: rem(-20);
			}
		}

		table {
			position: relative;

			td {
				font-weight: 500;
			}
		}
	}

	&.hide {
		@media (max-width: 767.98px) {
			display: none;
		}
	}

	.all-specifications {
		margin-top: rem(30);
	}

	&.product-desc-hide {

		@media (max-width: 767.98px) {
			display: none;
		}

		&.show {
			display: block;
		}
	}
}

.all-specifications {
	border: 1px solid #C4C7D7;
	box-sizing: border-box;
	font-weight: 500;
	font-size: rem(15);
	line-height: rem(26);
	text-align: center;
	text-transform: uppercase;
	color: #000000;
	height: rem(54);
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	position: relative;

	&:after {
		content: "";
		display: block;
		width: rem(17);
		height: rem(9);
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: rem(20);
		background: url("/design/themes/komtrans_it/media/assets/icons/chevron-down-dark.svg") no-repeat 50% / contain;
	}

	&.open {
		&:after {
			transform: rotate(-180deg) translateY(50%);
		}
	}
}

.description-product {

	.section-title {
		margin-top: rem(150);

		@media (max-width: 1500px) {
			margin-top: rem(70);
		}

		@media (max-width: 767.98px) {
			margin-top: rem(40);
		}
	}

	&__row {
		display: flex;

		@media (max-width: 991.98px) {
			flex-flow: column;
		}
	}

	&__col {

		&:first-child {
			width: 44%;

			@media (max-width: 1500px) {
  			width: 31%;
			}
			@media (max-width: 991.98px) {
				width: 100%;
			}
		}

		&:last-child {
			width: 56%;

			@media (max-width: 1500px) {
  			width: 69%;
			}

			@media (max-width: 991.98px) {
				display: none;
				width: 100%;
			}

		}
	}

	p {
		margin-bottom: rem(25);

		@media (max-width: 767.98px) {
			font-size: rem(16);
			line-height: rem(32);
		}
	}

	&__cover-content {
		position: relative;
		z-index: 2;
		padding-bottom: rem(25);

		p {
			&:last-child {
				@media (max-width: 1500px) {
					display: none;
				}

				@media (max-width: 767.98px) {
					display: block
				}
			}
		}
	}

	.product-interactive {
		width: rem(1200);
		height: rem(900);
		margin-right: rem(-250);
		margin-top: rem(-150);
		margin-bottom: rem(-30);

		@media (max-width: 1500px) {
			width: rem(1034);
			height: rem(800);
			margin-right: 0;
			margin-bottom: rem(-50);
			margin-top: rem(-40);
			margin-left: rem(-120);

		}
	}

	+ div {
		position: relative;
		z-index: 2;
	}

}

.product-interactive {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.interactive-1 {
	.clause-1 {
		top: 50.78%;
		left: 12.83%;

		@media (max-width: 1500px) {
			display: none;
		}
	}

	.clause-2 {
		top: 38%;
		left: 37.5%;
	}

	.clause-3 {
		top: 46.2%;
		left: 60.58%;
	}

	.clause-4 {
		top: 23.2%;
		left: 69%;
	}

	.clause-5 {
		top: 56.4%;
		left: 82.3%;
	}
}

.clause {
	position: absolute;
	z-index: 5;


	&__ico {
		display: block;
		width: rem(63);
		height: rem(63);
		border-radius: 50%;
		background-color: #2D303E;
		position: relative;
		cursor: pointer;
		transition: background-color 0.2s;

		&:hover {
			background-color: #D43A42;
		}

		&:after {
			content: "";
			display: block;
			width: rem(20);
			height: rem(20);
			background: url("/design/themes/komtrans_it/media/assets/icons/clause-plus.svg") no-repeat 50% / contain;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__desc {
		position: absolute;
		top: rem(10);
		left: rem(10);
		width: rem(266);
		padding-bottom: rem(50);
		transform: translate(-100%, -100%);
		display: none;

		&:after {
			content: "";
			display: block;
			width: rem(266);
			height: rem(44);
			background: url('/design/themes/komtrans_it/media/assets/icons/clausу-line.svg') no-repeat 50% / contain;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		p {
			background-color: #fff;
		}
	}

	&:hover {
		.clause__desc {
			display: block;
		}
	}
}

.articles-preview {
	.row {
		> div {
			margin-bottom: rem(30);

			&:last-child {
				@media (max-width: 1500px) {
    			display: none;
				}
			}

			@media (max-width: 1500px) and (min-width: 992px) {
    		flex: 0 0 33.3333%;
    		max-width: 33.333%;
			}
			
		}
	}

	.section-title {
		@media (max-width: 767.98px) {
			margin-top: rem(10);
		}
	}
}

.article-card {
	display: flex;
	padding-right: rem(25);

	@media (max-width: 575.98px) {
		padding-right: 0;	
		flex-flow: column-reverse;
	}

	&__date {
		font-size: rem(14);
		line-height: rem(20);
		font-weight: normal;
		display: block;
		text-align: center;
		color: #000000;
		margin-top: rem(5);

		@media (max-width: 575.98px) {
			line-height: rem(29);
			display: inline;
			text-align: left;
		}
	
		span {
			text-align: center;
			font-weight: bold;
			font-size: rem(26);
			line-height: rem(35);
			color: #000000;
			
			@media (max-width: 575.98px) {
				font-size: rem(14);
				line-height: rem(29);
				color: #000000;
				text-align: left;
				font-weight: normal;
			}
		}
	}

	&__main {
		padding-left: rem(25);

		@media (max-width: 575.98px) {
			padding-left: 0;	
		}
	}

	h5 {
		font-weight: 500;
		line-height: rem(32);
		color: #000000;
		margin-bottom: rem(20);

		@media (max-width: 767.98px) {
			font-size: rem(18);
			line-height: rem(32);
		}

		a {
			display: inline-block;
			text-decoration: none;
		}
	}

	p {
		font-size: rem(14);
		line-height: rem(29);
		color: #616161;

	}
}
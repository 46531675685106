.catalog-section-hero {
	position: relative;
	display: flex;
	flex-flow: column;
	padding-bottom: rem(160);
	background-color: #F7F7F7;
	padding-top: rem(165);
	overflow: hidden;
	min-height: rem(700);

	@media (max-width: 1500px) {
  	padding-top: 0;  
	}

	@media (max-width: 767.98px) {
		min-height: rem(450);
	}

	.breadcrumb {
		position: relative;
		z-index: 3;
	}

	.row > div {
		display: flex;
		flex-flow: column;
	}

	.circle  {
		border-width: rem(120);

		@media (max-width: 767.98px) {
			border-width: rem(52);
		}
	}

	.circle-1 {
		width: rem(1289);
		height: rem(1253);
		top: rem(-100);
    left: rem(156);
		transform: translate(-50%, -50%);
		z-index: 2;

		@media (max-width: 1500px) {
			opacity: 0.5;
		}

		@media (max-width: 767.98px) {
			width: rem(490);
			height: rem(490);
			top: rem(-160);
			left: rem(0);
			transform: translate(-50%, 0%);
			z-index: initial;
		}
	}

  .circle-2 {
		width: rem(903);
		height: rem(879);
		top: auto;
		bottom: rem(80);
		right: rem(80);

		@media (max-width: 767.98px) {
			width: rem(297);
			height: rem(297);
			bottom: rem(45);	
			right: rem(45);
		}
  }

  &__content {
		min-height: rem(480);
		display: flex;
		flex-flow: column;
		justify-content: center;
		padding-left: rem(50);

		@media (max-width: 1500px) {
  		min-height: 0;
  		padding-top: rem(80);
  		padding-left: 0;
		}

		@media (max-width: 767.98px) {
			padding-top: rem(50);
		}


  }

  img {
  	position: absolute;
  	top: rem(-155);
  	left: rem(-200);
  	width: rem(1182);
  	max-width: none;
  	z-index: 2;

  	@media (max-width: 1500px) {
  		width: rem(1015);
  		top: rem(-90);
  		left: rem(-260);
  	}

  	@media (max-width: 767.98px) {
			width: rem(567);
			top: rem(-130);
			right: rem(0);
			left: auto;
		}

		@media (max-width: 575.98px) {
			right: rem(-205);
		}
  }

  h1 {
  	font-weight: 800;
		font-size: rem(56);
		line-height: rem(75);
		text-transform: uppercase;
		color: #000000;
		position: relative;
		z-index: 2;

		@media (max-width: 1500px) {
			padding-left: 0;
		}

		@media (max-width: 991.98px) {
    	font-size: rem(34);
			line-height: rem(50);
		}

		&:before {
			content: "";
			width: rem(5);
			height: rem(40);
			position: absolute;
			top: rem(40);
			transform: translateY(rem(-20));
			left: rem(-50);
			background: #D53A42;
			box-shadow: rem(0) rem(4) rem(20) rgba(0, 0, 0, 0.1);
			
			@media (max-width: 991.98px) {
				left: rem(-25);
				top: 50%;
				height: rem(75);
				transform: translateY(rem(-38));
			}

			@media (max-width: 767.98px) {
				left: rem(-20);
			}
		}
  }

  &__title-back {
  	font-weight: 800;
		font-size: rem(340);
		line-height: rem(77);
		mix-blend-mode: multiply;
		opacity: 0.5;
  	position: absolute;
  	color: #E1E3EC;
  	left: 50%;
  	bottom: 8%;
		transform: translateX(-50%);
		z-index: 1;

  	@media (max-width: 1500px) {
  		font-size: rem(200);
  		bottom: 3%;
  	}

  	@media (max-width: 767.98px) {
  		display: none;
  	}
  }

}


.catalog-sort {
	padding: rem(30) 0;
	position: relative;

	&__cover {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		@media (max-width: 575.98px) {
			flex-flow: column;
			justify-content: flex-start;
			align-items: flex-start;
		}	

	}

	h5 {
		color: #000000;
		margin-right: rem(40);

		@media (max-width: 575.98px) {
			margin-right: 0;
			margin-bottom: rem(30);
		}
	}

	&__lines {
		width: rem(51);
		height: rem(20);
		background: url("/design/themes/komtrans_it/media/assets/icons/sort-lines-3.svg") no-repeat 50% / contain;
		margin-right: rem(45);

		@media (max-width: 1500px) {
    	height: rem(11);
    	background: url("/design/themes/komtrans_it/media/assets/icons/sort-lines.svg") no-repeat 50% / contain;
		}

		@media (max-width: 575.98px) {
			display: none;
		}
	}

	.select-wrap {
		width: rem(227);

		@media (max-width: 575.98px) {
			margin-bottom: rem(10);
			width: 100%;
		}

	}

	.select2-container .select2-selection--single {
		height: rem(55);
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: rem(55);
	}

	.select2-container--default .select2-selection--single .select2-selection__arrow {
		height: rem(55);
	}

	.select2-container--default .select2-selection--single {
		border: none;
		background: #FFFFFF;
		box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
	}

	.select2-container--default .select2-selection--single .select2-selection__rendered {
		color: #000000;
		font-weight: 500;
	}

	&__title-back {
		font-weight: 800;
		font-size: rem(138);
		line-height: rem(77);
		color: #000000;
		opacity: 0.05;
		position: absolute;
		right: rem(633);
		bottom: rem(-35);

		@media (max-width: 1500px) {
			right: rem(300);
		}

		@media (max-width: 767.98px) {
			display: none
		}
	}
}

.catalog-main {
	padding-bottom: rem(30);

	.container {
		> .row {
			> div {
				&:first-child {
					@media (max-width: 1500px) and (min-width: 992px) {
						flex: 0 0 30%;
	    			max-width: 30%;
					}
				}
				&:last-child {
					@media (max-width: 1500px) and (min-width: 992px) {
						flex: 0 0 70%;
	    			max-width: 70%;
					}
				}
			}
		}
	}
	&__cards {

		@media (max-width: 575.98px) {
			margin: 0 rem(-20);
		}

		> .row {
			> div {
				margin-bottom: rem(30);
				

				@media (max-width: 1500px) and (min-width: 768px) {
					flex: 0 0 50%;
    			max-width: 50%;
				}
			}
		}
		.card {
			width: 100%;
			box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
		}
	}
}

.catalog-filter {
	background: #FFFFFF;
	box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
	padding: rem(37) rem(32);

	@media (max-width: 575.98px) {
		margin: 0 rem(-20);	
		padding: rem(35) rem(20);
	}

	h5 {
		font-weight: bold;
		font-size: 24px;
		line-height: 32px;
		color: #000000;
	}

	.calc-block {
		margin-top: rem(30);
	}

	&__actions-btn {
		display: flex;
		margin: 0 rem(-10);

		.btn {
			margin: 0 rem(10);
			width: 50%;
			padding: 0;
			height: rem(54);
		}
	}
}

.calc-block {

	h6 {
		font-size: rem(15);
		line-height: rem(29);
		color: #000000;
		margin-bottom: rem(25);
	}

	&__values-cover {
		display: flex;
		
		flex-wrap: wrap;

		input {
			font-family: 'Gilroy', sans-serif;
			background: #FFFFFF;
			box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
			font-weight: 300;
			font-size: rem(16);
			line-height: rem(32);
			color: #000000;
			margin-right: rem(20);
			border: none;
			width: rem(95);
			padding: 0 rem(18);
			height: rem(50);
			margin-bottom: rem(25);

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.page-nav {
	margin: 0 auto;
	margin: rem(30) 0;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 575.98px) {
		align-items: flex-start;
		justify-content: flex-start;
		margin: rem(10) 0;
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(54);
		height: rem(54);
		background: #FFFFFF;
		box-shadow: 0 0 rem(34) rgba(0, 0, 0, 0.1);
		font-size: rem(14);
		line-height: rem(29);
		color: #000000;
		margin: 0 rem(10);
		text-decoration: none;

		@media (max-width: 575.98px) {
			margin: 0;
			margin-right: rem(20);
		}

		&:nth-child(4) {
			@media (max-width: 575.98px) {
				display: none;
			}
		}

		

		&:last-child {
			background: none;
			box-shadow: none;
			margin-right: 0;
		}
		&:hover {
			background: #F2F2F2;
			box-shadow: none;
		}
	}
}

.paragraph-block {
	padding: rem(60) 0;

	@media (max-width: 767.98px) {
    display: none;
	}
	
	&__item {
		padding-left: rem(60);

		@media (max-width: 991.98px) {
    	padding-left: 0;
		}
	}

}